import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import HeroSection from "./components/HeroSection/HeroSection";
import WhatWeDoSection from "./components/WhatWeDoSection/WhatWeDoSection";
import HowWeWork from "./components/HowWeWork/HowWeWork";
import OurServices from "./components/OurServices/OurServices";
import LogoSlider from "./components/LogoSlider/LogoSlider";
import Products from "./components/Products/Products";
import Footer from "./components/Footer/Footer";
import SecondaryFooter from "./components/SecondaryFooter/SecondaryFooter";
import ContactUs from "./components/ContactUs/ContactUs";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if there's no hash
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <Header />
      <HeroSection />
      <WhatWeDoSection />
      <Products />
      <HowWeWork />
      <OurServices />
      <LogoSlider />
      <ContactUs />
      <SecondaryFooter />
      <Footer />
    </>
  );
};

export default HomePage;

import { styled } from "styled-components";

export const HeroSectionStyled = styled.section`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #f2f5fc;
  height: 620px;
  .heroSectionContainer {
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .heroTitle {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 56px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      max-width: 565px;
      width: 100%;
    }
    .heroParagraph {
      max-width: 446px;
      width: 100%;
      margin-top: 24px;
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
    }
  }
  @media (max-width: 959px) {
    padding: 20px;
    height: 100%;
    .heroSectionContainer {
      max-width: 100%;
      width: 100%;
      .imageContainer {
        display: none;
      }
      .heroTitle {
        font-size: 36px;
        line-height: 44px;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
      .heroParagraph {
        margin-top: 24px;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }
`;

import React from "react";
import { HeroSectionStyled } from "./HeroSectionStyled";

const HeroSection = ({ title = "" }: { title?: string }) => {
  return (
    <HeroSectionStyled>
      <div className="heroSectionContainer">
        <div>
          <h1 className="heroTitle">{title}</h1>
        </div>
      </div>
    </HeroSectionStyled>
  );
};

export default HeroSection;

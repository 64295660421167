import { useFormik } from "formik";
import { remainderValidations } from "../validations";
import { toast } from "react-toastify";
import { createRemainder } from "../../../services/private.services";
import { useAuth } from "../../../auth";

export const RemainderForm = (getList: any, handleClose: any) => {
  const { user } = useAuth();
  return useFormik({
    initialValues: {
      title: "",
      userId: user?.id,
      description: "",
      startDate: "",
      endDate: "",
      frequency: "",
      type: "event",
      period: "morning",
      isActive: false,
    },
    validationSchema: remainderValidations,
    onSubmit: async (values) => {
      await createRemainder(values);
      toast.success("New event created");
      getList();
      handleClose();
    },
  });
};

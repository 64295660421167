import { useFormik } from "formik";
import { customerValidations } from "../validations";
import { creatCustomer } from "../../../services/private.services";
import { toast } from "react-toastify";

const CreateCustomerForm = (handleClose: () => void) => {
  return useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      deliveryAddress: "",
    },
    validationSchema: customerValidations,
    onSubmit: async (values) => {
      const dataObj = {
        ...values,
      };
      await creatCustomer(dataObj);
      toast.success("Added new customer");
      handleClose();
    },
  });
};

export default CreateCustomerForm;

import React, { lazy, Suspense } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { ThreeDots } from "react-loader-spinner";
import {
  StyledTableRowHeader,
  StyledTableCell,
  StyledTableRow,
} from "../../../components/Common/Table";
import Pagination from "../../../components/Common/Table/Components/Pagination";
import { getFormattedDate, getName } from "../../../utils/utils";
import Drawer from "../../../components/Common/Drawer";
import useDraweHook from "../hooks/useDraweHook";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import JobCardStatuses from "../components/JobCardStatuses";
const JobCardDrawer = lazy(() => import("../components/JobCardDrawer"));

type IProps = {
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleOnChangeSearch: React.ChangeEventHandler<HTMLInputElement>;
  loading: boolean;
  list: any;
  totalCount: number;
  pagination: {
    page: number;
    perPage: number;
    search: string;
  };
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getList: (loader?: boolean) => void;
  handleFieldSort: (field: string) => void;
  onSetJobCardId: (id: string) => void;
};

const ListJobCards = ({
  loading,
  list,
  totalCount,
  pagination,
  handleChangeRowsPerPage,
  handleChangePage,
  handleOnChangeSearch,
  getList,
  handleFieldSort,
  onSetJobCardId,
}: IProps) => {
  const {
    openDrawer,
    setJobAndOpenDrawer,
    toggleDrawer,
    jobDetails,
    closeDrawer,
    getJobDetails,
  } = useDraweHook(getList);
  const renderLoader = () => <p>Loading</p>;
  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRowHeader>
            <StyledTableCell>
              <div className="title-wrapper">
                <SwapVertOutlinedIcon
                  className="title-icon"
                  onClick={() => handleFieldSort("jobNo")}
                />
                <span>Job No.</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className="title-wrapper">
                <SwapVertOutlinedIcon
                  className="title-icon"
                  onClick={() => handleFieldSort("jobTitle")}
                />
                <span>Job Title</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>Assignee</StyledTableCell>
            <StyledTableCell>
              <div className="title-wrapper">
                <SwapVertOutlinedIcon
                  className="title-icon"
                  onClick={() => handleFieldSort("reportedProblem")}
                />
                <span>Problem</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className="title-wrapper">
                <SwapVertOutlinedIcon
                  className="title-icon"
                  onClick={() => handleFieldSort("createdAt")}
                />
                <span>Date</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </StyledTableRowHeader>
        </TableHead>
        <TableBody>
          {!loading &&
            list &&
            list?.map((row: any, idx: any) => (
              <StyledTableRow
                key={row.id}
                onClick={() => setJobAndOpenDrawer(row?.id)}
                style={{
                  cursor: "pointer",
                }}
              >
                <StyledTableCell>
                  <div className="value-wrapper">{row.jobNo}</div>
                </StyledTableCell>
                <StyledTableCell>{row.jobTitle}</StyledTableCell>
                <StyledTableCell>
                  {getName(row?.asignedUser?.firstName)}
                </StyledTableCell>
                <StyledTableCell>{row?.reportedProblem}</StyledTableCell>
                <StyledTableCell>
                  {getFormattedDate(row?.createdAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <JobCardStatuses status={row?.status} />
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onSetJobCardId(row?.id);
                    }}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                  >
                    <DeleteForeverIcon />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#1B2850" height={50} width={50} />
        </div>
      )}
      {!loading && list?.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data Found
        </div>
      )}
      <Pagination
        count={totalCount}
        rowsPerpage={pagination?.perPage}
        onPageChange={handleChangePage}
        page={pagination?.page - 1}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Drawer open={openDrawer} toggleDrawer={toggleDrawer}>
        <Suspense fallback={renderLoader()}>
          <JobCardDrawer
            jobDetails={jobDetails}
            closeDrawer={closeDrawer}
            getJobDetails={getJobDetails}
            getList={getList}
          />
        </Suspense>
      </Drawer>
    </>
  );
};

export default ListJobCards;

import { FooterStyled } from "./FooterStyled";

const Footer = () => {
  return (
    <FooterStyled>
      <div className="footerContainer">
        <h1>Sahara National Computer Co.</h1> &nbsp;
        <h2>W.L.L</h2>
      </div>
    </FooterStyled>
  );
};

export default Footer;

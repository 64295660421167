import { createSlice } from "@reduxjs/toolkit";

export interface ProductState {
  selectedProduct: string;
  hasSelectedProduct: boolean;
}

const initialState: ProductState = {
  selectedProduct: "",
  hasSelectedProduct: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action: any) => {
      state.hasSelectedProduct = true;
      state.selectedProduct = action.payload;
    },
    removeProduct: (state) => {
      state.hasSelectedProduct = false;
      state.selectedProduct = "";
    },
  },
});

export const { setProduct, removeProduct } = productSlice.actions;
export default productSlice.reducer;

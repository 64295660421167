import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAuth } from "../../../auth";
import { createQuotation } from "../../../services/quotation.services";
import { useNavigate } from "react-router-dom";

export interface Product {
  productId: string;
  quotedPrice: string;
  quantityRequired: string;
  productName: string;
}

interface FormValues {
  products: Product[];
  name: string;
  customerId: string;
  userRefNo: string;
  description: string;
  userId: string;
  isEmailSent: boolean;
}

const CreateQuotationFrom = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  return useFormik<FormValues>({
    initialValues: {
      products: [
        {
          productId: "",
          quotedPrice: "",
          quantityRequired: "",
          productName: "",
        },
      ],
      customerId: "",
      name: "",
      userRefNo: "",
      description: "",
      userId: user?.id,
      isEmailSent: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      products: Yup.array().of(
        Yup.object().shape({
          productId: Yup.string().required("Required"),
          quotedPrice: Yup.number().required("Required"),
          quantityRequired: Yup.number().required("Required"),
        })
      ),
      customerId: Yup.string().max(255).trim().required("Select customer"),
      name: Yup.string().max(255).trim().required("Name is required"),
      userRefNo: Yup.string().max(255).trim().required("Reference Number is required"),
    }),
    onSubmit: async (values) => {
      await createQuotation(values).then(() => {
        toast.success("Quote created successfully");
        navigate("/crm-auth/quote");
      });
    },
  });
};

export default CreateQuotationFrom;

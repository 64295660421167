import * as Yup from "yup";
import {
  email,
  phoneNumber,
  name,
  address,
  deliveryAddress,
} from "../../utils/validations";

export const customerValidations = Yup.object({
  email,
  phoneNumber,
  name,
  address,
  deliveryAddress,
});

import { styled } from "styled-components";

export const HowWeWorkStyled = styled.section`
  height: 550px;
  display: flex;
  justify-content: center;
  .howWeWorkContainer {
    max-width: 1280px;
    margin: 100px 0px;
    margin-bottom: 150px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    .howWeWorkWrapper {
      position: relative;
      .howWeWorkBox {
        width: 508px;
        height: 283px;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        position: absolute;
        left: -330px;
        top: 57px;
        padding: 28px 0px 25px 51px;
        h1 {
          color: #2b3b7c;
          font-family: Poppins;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 72px;
        }
        p {
          color: #9f9f9f;
          width: 240px;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }
        a {
          color: #fff;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          width: 203px;
          height: 60px;
          background: #d93a35;
          outline: none;
          border: none;
          text-decoration: none;
          padding: 15px 20px;
        }
        @media (max-width: 989px) {
          position: static;
          max-width: 508px;
          width: 100%;
          height: 283px;
          padding-right: 20px;
        }
      }
      @media (max-width: 989px) {
        justify-content: center;
        .howWeWorkImage {
          display: none;
        }
      }
    }
    @media (max-width: 989px) {
      justify-content: center;
      margin: 20px 0px;
      height: 100%;
    }
  }
  @media (max-width: 989px) {
    height: 100%;
  }
`;

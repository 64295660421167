import React from "react";
import Header from "../components/Header/Header";
import HeroSection from "../Services/HeroSection/HeroSection";
import { PolicyContainer } from "./styles";

const Policy = () => {
  return (
    <>
      <Header />
      <HeroSection title={"Sahara Computers"} />
      <PolicyContainer>
        <div className="aboutUs">
          <h3>About Us</h3>
          <p>
            We are a leading supplier in the Kuwait IT market, specializing in
            IT hardware, software, and consumables for over 20 years. As an
            authorized HP Power & HP Power service Partner (MPS), we assure the
            authenticity of all HP products we supply. Our product range
            includes major brands like HP, DELL, Epson, Canon, APC, Honeywell,
            Kodak, Fujitsu, Zebra, Cisco, D-Link, TP-Link, Kuwes, 3M, Microsoft,
            Adobe, and more. If you require products from these renowned brands,
            feel free to contact us via email or any preferred communication
            channel. We offer annual purchase contracts for consumables and
            rental contracts for HP Printers. Allow us the opportunity to
            demonstrate the cost-effectiveness of HP original cartridges over
            Compatible/Duplicate options. For any IT-related requirements, reach
            out to us, and we guarantee swift assistance at your doorstep. We
            look forward to potentially collaborating with you soon. Thank you
            once again for considering us.
          </p>
          <p>
            Established in 2000, Sahara is a service-oriented organization that
            proudly represents leading global brands in IT hardware, software,
            networks, and office equipment. With a focus on transforming new
            technologies into reliable enterprise solutions, we have earned a
            diverse clientele, ranging from home users and small to medium-sized
            businesses to large corporations, banks, hospitals, universities,
            schools, and government institutions. Our founders, with over two
            decades of experience in various roles within the industry, remain
            actively involved in the day-to-day operations. This wealth of
            experience enables us to offer historical insights into the
            industry's evolution, current technological impacts, and foresight
            into emerging trends and solutions. We supply a comprehensive range
            of servers, workstations, printers, mass storage, peripheral
            devices, accessories, and consumables from leading manufacturers
            worldwide.
          </p>
        </div>
        <div className="aboutValues">
          <h3>Our Vision</h3>
          <p>
            To provide excellent computer hardware sales, support, and
            networking solutions to government and private companies in Kuwait
            at an affordable price within the shortest time.
          </p>
        </div>
        <div className="aboutValues">
          <h3>Our Mission</h3>
          <p>
            We strive for 100% customer satisfaction in both sales and services.
            Our goal is to ensure a trouble-free work environment for systems
            under our care.
          </p>
        </div>
        <div className="aboutValues">
          <h3>Core Values</h3>
          <p>
            Integrity: Upholding professional integrity and fair play. Pursuit
            of Excellence: Exceeding stakeholder expectations. Partnership:
            Valuing strategic partnerships with customers and business
            associates. Employees: Our certified engineers and technicians,
            trained and tested in the field, ensure the highest quality of
            service. Certifications and Partnerships
          </p>
        </div>
        <div className="aboutValues">
          <h3>Our Policy</h3>
          <p>
            <b>No solution, no charge.</b>
            If we are unable to resolve your computer problems, there will be no
            charge for our services. However, certain cases may require upgrades
            or replacements, and in such instances, charges may apply. Our
            onsite services are warranted for 30 days.
          </p>
        </div>
      </PolicyContainer>
    </>
  );
};

export default Policy;

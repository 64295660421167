import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "styled-components";

const CommonDatePicker = ({
  handleDateChange,
}: {
  handleDateChange: (value: any) => void;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker disablePast onChange={handleDateChange} />
    </LocalizationProvider>
  );
};

export default CommonDatePicker;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  .MuiInputBase-input,
  .MuiInputBase-root {
    height: 40px;
    font-family: "Nunito", sans-serif !important;
  }
`;

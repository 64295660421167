import * as React from "react";
import clsx from "clsx";
import { Box } from "@mui/system";
import { Modal } from "@mui/material";
import  styled  from "styled-components";

type IProps = {
  children?: React.ReactNode;
  handleOpen?: () => void;
  handleClose?: () => void;
  open: boolean;
  width?: number;
};

export default function IModal({
  children,
  handleOpen,
  open,
  handleClose,
  width = 75,
}: IProps) {
  return (
    <StyledModal
      width={width}
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
    >
      <Box>{children}</Box>
    </StyledModal>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const StyledModal = styled(Modal)<IProps>`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .heading-h1 {
    font-size: 1.375rem;
    font-weight: 600;
    text-align: center;
    color: #595959;
  }
  .sub-paragraph {
    font-size: 1.125em;
    font-weight: 400;
    text-align: center;
    color: #545454;
  }
  .MuiBox-root {
    border-radius: 12px;
    width: ${(props) => (props?.width ? props?.width : 75)}%;
    height: auto;
    padding: 16px 32px 24px 32px;
    background-color: white;
    box-shadow: 0px 2px 24px #383838;
  }
  @media only screen and (max-width: 600px) {
    .MuiBox-root {
      // overflow: scroll !important;
      // overflow-x: hidden !important;
      // height: 75% !important;
      width: 95% !important;
    }
  }
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
import { styled } from "styled-components";

export const StyledHeader = styled.div`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.02);
  .mobileHeader {
    display: none;
  }
  .headerContainer {
    display: flex;
    max-width: 1280px;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .imageLogo {
      width: 390px;
      height: 90px;
      cursor: pointer;
    }
    
    .headerItems {
      display: flex;
      align-items: center;
      ul {
        display: flex;
        align-items: center;
      }
      ul li {
        margin: 0 16px;
      }
      ul li a {
        text-decoration: none;
        color: #2b3b7c;
      }
      li {
        color: #2b3b7c;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        position: relative;
      }
      li:before {
        content: "";
        position: absolute;
        bottom: -10px;
        height: 2px;
        width: 0;
        background: #2b3b7c;
        border-radius: 50px;
        transition: width 0.3s ease;
      }
      li:hover:before {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .mobileHeader {
      display: block;
    }
    .headerContainer {
      .imageLogo {
        width: 290px;
        height: 70px;
        padding-left: 20px;
      }

      .headerItems {
        display: none;
      }
    }
  }
`;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "styled-components";

type Options = {
  id: number | string;
  name: string;
};

type Iprops = {
  options: Options[];
  className?: string;
  selectText?: string;
  inputwidth?: string;
  formik?: any;
  name?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
};

export default function Dropdown({
  options,
  className = "",
  selectText = "Select Option",
  inputwidth = "100",
  name = "",
  formik,
  value = "",
  disabled = false,
  onChange,
}: Iprops) {
  const handleChange = (event: SelectChangeEvent) => {
    if (formik) {
      formik?.setFieldValue(name, event.target.value);
      return;
    }
    if (onChange) {
      onChange(event);
      return;
    }
  };
  return (
    <div className={className}>
      <StyledDropdown
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        inputwidth={inputwidth}
        value={formik?.values[`${name}`] || value || ""}
        error={Boolean(formik?.touched[`${name}`] && formik?.errors[`${name}`])}
        name={name}
        disabled={disabled}
      >
        <MenuItem value="">
          <em>{selectText}</em>
        </MenuItem>
        {options?.map((opt) => (
          <MenuItem value={opt.id} key={opt.id}>
            {opt.name}
          </MenuItem>
        ))}
      </StyledDropdown>
    </div>
  );
}

const StyledDropdown: any = styled(Select)`
  height: 40px;
  width: ${(props: any) => props.inputwidth && parseInt(props.inputwidth)}%;
`;

import { useNavigate } from "react-router-dom";
import { OurServicesCard } from "./OurServicesStyled";
import { ServiceType } from "./types";

const OurServicesItems = ({ services }: { services: ServiceType }) => {
  const navigate = useNavigate();
  return (
    <OurServicesCard
      onClick={() => {
        navigate(`/services/${services.id}`);
      }}
    >
      <img src={services.image} alt={services.image} />
      <h3>{services.title}</h3>
      <p>{services.firstParagraph}</p>
    </OurServicesCard>
  );
};

export default OurServicesItems;

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  deleteRemainder,
  getRemainderList,
} from "../../services/private.services";
import moment from "moment";
import { toast } from "react-toastify";

function getMonthStartEndDates(dateString: Date) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based month index
  const startDate = new Date(year, month - 1, 1); // Setting day as 1 to get the first day of the month
  const endDate = new Date(year, month, 0); // Setting day as 0 to get the last day of the previous month

  const formattedStartDate = startDate.toISOString().slice(0, 10);
  const formattedEndDate = endDate.toISOString().slice(0, 10);

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
}

const useRemainderHook = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dates, setDates] = useState({
    start: "",
    end: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10000,
    search: "",
    ...getMonthStartEndDates(new Date()),
  });
  const [eventDetail, setEventDetails] = useState("");

  const setEvents = (data: any) => {
    setEventDetails(() => data);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    getList();
  };
  const handleDetailModalOpen = () => setOpenDetailModal(true);
  const handleDetailModalClose = () => setOpenDetailModal(false);

  const handleDateChanges = (dates: any) => {
    setDates(() => dates);
  };

  const changeEventOnMonthChange = (date: Date) => {
    setPagination((prevPage) => ({
      ...prevPage,
      ...getMonthStartEndDates(date),
    }));
  };

  const deleteRemainderWithId = async (id: string) => {
    await deleteRemainder(id);
    getList();
    handleDetailModalClose();
    toast.success("Event deleted successfully.", { autoClose: 1500 });
  };

  const getList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getRemainderList(pagination);

      setList(result?.data);
      setTotalCount(result?.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [pagination]);

  const event = useMemo(() => {
    let events: any = [];
    if (list.length) {
      events = list?.map((event: any) => {
        return {
          ...event,
          start: moment(event?.startDate).toDate(),
          end: moment(event?.endDate).toDate(),
        };
      });
    }
    return events;
  }, [list]);

  useEffect(() => {
    getList();
  }, [getList, pagination]);

  return {
    openModal,
    handleOpen,
    handleClose,
    handleDateChanges,
    dates,
    event,
    getList,
    openDetailModal,
    handleDetailModalOpen,
    handleDetailModalClose,
    setEvents,
    eventDetail,
    loading,
    totalCount,
    setPagination,
    changeEventOnMonthChange,
    deleteRemainderWithId,
  };
};

export default useRemainderHook;

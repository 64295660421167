import { Grid } from "@mui/material";
import HowWeWorkImg from "../../../../assets/images/HowWeWork.png";
import InputField from "../../../../components/Common/Input";
import { StyledError } from "../../../../components/styles";
import Button from "../../../../components/Common/Button";
import CreatUserForm from "./ContactUsForm";
import styled from "styled-components";
const ContactUs = () => {
  const formik = CreatUserForm();
  return (
    <StyledContact>
      <div className="content" id="contactUs">
        <div className="container">
          <div className="row align-items-stretch no-gutters contact-wrap">
            <div className="col-md-12">
              <div className="form h-100">
                <h3
                  style={{
                    fontFamily: "Poppins",
                    color: "#2b3b7c",
                    marginBottom: "20px",
                    fontSize: "36px",
                    lineHeight: "72px",
                  }}
                >
                  Contact Us
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <form
                    className="mb-5 form-cls"
                    method="post"
                    id="contactForm"
                    name="contactForm"
                    style={{
                      width: "48%",
                    }}
                  >
                    <Grid spacing={3} container>
                      <Grid xs={12} sm={6} lg={6} item>
                        <label
                          style={{
                            color: "#2b3b7c",
                          }}
                        >
                          Name
                        </label>
                        <InputField
                          formik={formik}
                          name="name"
                          placeholder="Name"
                        />
                        <StyledError>
                          {formik.touched?.name && formik.errors["name"]}
                        </StyledError>
                      </Grid>
                      <Grid xs={12} sm={6} lg={6} item>
                        <label
                          style={{
                            color: "#2b3b7c",
                          }}
                        >
                          Email
                        </label>
                        <InputField
                          formik={formik}
                          name="email"
                          placeholder="Email"
                        />
                        <StyledError>
                          {formik.touched?.email && formik.errors["email"]}
                        </StyledError>
                      </Grid>
                      <Grid xs={12} sm={6} lg={6} item>
                        <label
                          style={{
                            color: "#2b3b7c",
                          }}
                        >
                          Address
                        </label>
                        <InputField
                          formik={formik}
                          name="address"
                          placeholder="Address"
                        />
                        <StyledError>
                          {formik.touched?.address && formik.errors["address"]}
                        </StyledError>
                      </Grid>
                      <Grid xs={12} sm={6} lg={6} item>
                        <label
                          style={{
                            color: "#2b3b7c",
                          }}
                        >
                          Phone Number
                        </label>
                        <InputField
                          formik={formik}
                          name="phoneNumber"
                          placeholder="phoneNumber"
                        />
                        <StyledError>
                          {formik.touched?.phoneNumber &&
                            formik.errors["phoneNumber"]}
                        </StyledError>
                      </Grid>
                      <Grid xs={12} sm={12} lg={12} item>
                        <label
                          style={{
                            color: "#2b3b7c",
                          }}
                        >
                          Message
                        </label>
                        <InputField
                          formik={formik}
                          name="description"
                          placeholder="Description"
                          multiline
                        />
                        <StyledError>
                          {formik.touched?.description &&
                            formik.errors["description"]}
                        </StyledError>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        item
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Grid xs={12} sm={6} lg={3} item>
                          <Button
                            onClick={formik?.handleSubmit}
                            disabled={formik.isSubmitting}
                          >
                            Contact Us
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                  <div
                    style={{
                      width: "48%",
                    }}
                    className="imageContainer"
                  >
                    <img src={HowWeWorkImg} alt="Contact Us" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledContact>
  );
};

export default ContactUs;

const StyledContact = styled.div`
  @media (max-width: 989px) {
    #contactForm {
      width: 100%;
    }
    .form-cls {
      width: 100% !important;
    }
    .imageContainer {
      display: none;
    }
  }
`;

import React from "react";
import { StyledHeader } from "./HeaderStyled";
import Logo from "../../../../assets/images/saharaText.png";
import { headerItems } from "./constant";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const node = React.useRef() as any;
  return (
    <StyledHeader>
      <div className="headerContainer">
        <img
          src={Logo}
          alt="LOGO"
          className="imageLogo"
          onClick={() => navigate("/")}
        />
        <div className="headerItems">
          <ul>
            {headerItems.map((item) => {
              return (
                <li key={item.id}>
                  <Link to={item.path}>{item.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="mobileHeader">
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </div>
      </div>
    </StyledHeader>
  );
};
export default Header;

const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  z-index: 1000000;
  flex-direction: column;
  justify-content: center;
  background: white;
  transform: ${({ open }: any) =>
    open ? "translateX(0)" : "translateX(-100%)"};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

const Menu = ({ open }: any) => {
  return (
    <StyledMenu open={open}>
      <ul>
        {headerItems.map((item) => {
          return (
            <li key={item.id}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          );
        })}
      </ul>
    </StyledMenu>
  );
};

const StyledBurger = styled.button<{ open: boolean }>`
  position: absolute;
  top: 5%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  color: black;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#0D0C1D")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }: any) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const useOnClickOutside = (ref: any, handler: any) => {
  React.useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

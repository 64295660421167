import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { ThreeDots } from "react-loader-spinner";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowHeader,
} from "../../../components/Common/Table";
import Pagination from "../../../components/Common/Table/Components/Pagination";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import { getFormattedDate, getName } from "../../../utils/utils";
import { StatusWrapper } from "../../../components/styles";
import { useNavigate, useLocation } from "react-router-dom";

type IProps = {
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  loading: boolean;
  list: any;
  totalCount: number;
  pagination: {
    page: number;
    perPage: number;
    search: string;
  };
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSetQuoteId?: (id: string) => void;
};

const ListQuotes = ({
  loading,
  list,
  totalCount,
  pagination,
  handleChangeRowsPerPage,
  handleChangePage,
  onSetQuoteId,
}: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToDetails = (id: string) => {
    if (location.pathname === "/crm-auth/quote") {
      navigate(`/crm-auth/quote/${id}`);
    } else {
      navigate(`/crm-auth/purchase-order/${id}`);
    }
  };
  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRowHeader>
            <StyledTableCell>
              <div className="title-wrapper">
                <SwapVertOutlinedIcon className="title-icon" />
                <span>Quote Name</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>Created By</StyledTableCell>
            <StyledTableCell>Customer</StyledTableCell>
            <StyledTableCell>Reference No.</StyledTableCell>
            <StyledTableCell>
              <div className="title-wrapper">
                <SwapVertOutlinedIcon className="title-icon" />
                <span>Date</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </StyledTableRowHeader>
        </TableHead>
        <TableBody>
          {!loading &&
            list &&
            list?.map((row: any, idx: any) => (
              <StyledTableRow
                key={row.id}
                onClick={() => navigateToDetails(row?.id)}
                style={{
                  cursor: "pointer",
                }}
              >
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>
                  {getName(row?.quotationOwner?.firstName)}
                </StyledTableCell>
                <StyledTableCell>
                  {getName(row?.quotedCustomer?.name)}
                </StyledTableCell>
                <StyledTableCell>{row?.userRefNo}</StyledTableCell>
                <StyledTableCell>
                  {getFormattedDate(row?.createdAt)}
                </StyledTableCell>
                <StyledTableCell>
                  {row?.isApproved ? (
                    <StatusWrapper color="#32a34b80">Approved</StatusWrapper>
                  ) : (
                    <StatusWrapper color="#E8797C">Pending</StatusWrapper>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {" "}
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onSetQuoteId && onSetQuoteId(row?.id);
                    }}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                  >
                    <DeleteForeverIcon />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#1B2850" height={50} width={50} />
        </div>
      )}
      {!loading && list?.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data Found
        </div>
      )}
      <Pagination
        count={totalCount}
        rowsPerpage={pagination?.perPage}
        onPageChange={handleChangePage}
        page={pagination?.page - 1}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ListQuotes;

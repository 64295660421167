import React from "react";
import { Grid, Switch } from "@mui/material";
import InputField from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import CreateInventory from "./CreateInventory.form";
import { StyledError } from "../../../components/styles";
import { getInventoryTypeName } from "../utils";

type IProps = {
  handleClose: () => void;
  type: string;
};

const AddInventory = ({ handleClose, type }: IProps) => {
  const formik = CreateInventory(type, handleClose);
  return (
    <>
      <h4>{getInventoryTypeName(type, "Add")}</h4>
      <Grid spacing={3} container>
        <Grid xs={12} sm={12} lg={12} item>
          <label>Name</label>
          <InputField formik={formik} name="name" />
          <StyledError>
            {formik.touched?.name && formik.errors["name"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={12} item>
          <label>Description</label>
          <InputField formik={formik} name="description" />
          <StyledError>
            {formik.touched?.description && formik.errors["description"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={12} item>
          <label>Active</label>
          <Switch
            value={formik?.values?.isActive}
            name="isActive"
            onChange={formik?.handleChange}
          />
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <Button buttontypes="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <Button onClick={formik?.handleSubmit}>Submit</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddInventory;

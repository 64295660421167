import { Grid, Switch } from "@mui/material";
import InputField from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import { StyledError } from "../../../components/styles";
import { RemainderForm } from "./Remainder.form";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { frequencyType } from "../../../utils/remainder";
import Dropdown from "../../../components/Common/Dropdown";

interface Props {
  handleClose: any;
  dates: {
    start: any;
    end: any;
  };
  getList: any;
}

const CreateRemainder = ({ handleClose, dates, getList }: Props) => {
  const formik = RemainderForm(getList, handleClose);
  useEffect(() => {
    if (!isEmpty(dates)) {
      formik.setFieldValue("startDate", dates.start);
      formik.setFieldValue("endDate", dates.end);
    }
    // eslint-disable-next-line
  }, [dates]);
  return (
    <>
      <h4>Create Event</h4>
      <Grid spacing={3} container>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Title</label>
          <InputField name="title" formik={formik} />
          <StyledError>
            {formik.touched?.title && formik.errors["title"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Description</label>
          <InputField name="description" formik={formik} />
          <StyledError>
            {formik.touched?.description && formik.errors["description"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={12} item>
          <label>Event Period</label>
          <Dropdown
            options={frequencyType}
            formik={formik}
            name="frequency"
            value={formik?.values?.frequency}
          />
          <StyledError>
            {formik.touched?.frequency && formik.errors["frequency"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Active</label>
          <Switch
            value={formik?.values?.isActive}
            name="isActive"
            onChange={formik?.handleChange}
          />
        </Grid>
        <Grid xs={12} sm={12} lg={12} item container spacing={3}>
          <Grid xs={12} sm={12} lg={6} item>
            <Button buttontypes="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>{" "}
          <Grid xs={12} sm={12} lg={6} item>
            <Button onClick={formik?.handleSubmit}>Submit</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateRemainder;

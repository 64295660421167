import { styled } from "styled-components";

export const HeroSectionStyled = styled.section`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #f2f5fc;
  height: 230px;
  .heroSectionContainer {
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .heroTitle {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 56px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      width: 100%;
      text-align: center;
    }
  }
`;

export const BodySection = styled.section`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  .bodySectionContainer {
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .firstPara {
      margin-top: 550px;
      display: flex;
      p {
        width: 75%;
        font-size: 18px;
        padding-top: 25px;
        color: #5e5b5b;
      }
      img {
        width: 25%;
      }
    }
    p {
        font-size: 18px;
        padding-top: 25px;
        color: #5e5b5b;
      }
  }
`;

import styled from "styled-components";
import { Grid } from "@mui/material";
import Button from "../Button";

interface Props {
  handleClose: () => void;
  handleSubmit: () => void;
}

const DeleteModal = ({ handleClose, handleSubmit }: Props) => {
  return (
    <StyledDeleteModal>
      <h3>Are you sure you want to delete?</h3>
      <Grid xs={12} container item spacing={2} justifyContent="flex-end" mt={4}>
        <Grid xs={12} sm={12} lg={6} item>
          <Button onClick={() => handleSubmit()}>Delete</Button>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <Button buttontypes="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </StyledDeleteModal>
  );
};

export default DeleteModal;

export const StyledDeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

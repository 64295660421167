export type DeliveredProduct = {
  productId: string;
  quantityDelivered: number;
  productName?: string;
};

type User = {
  id: string;
  name: string;
  role: string;
};

export type DeliveredNotes = {
  id: string;
  status: string;
  date: string;
  createdBy: User;
  deliveredProducts: DeliveredProduct[];
};

export type DeliveryNotes = {
  quotationId: string;
  deliveryNotes: DeliveredNotes[];
  deliveredProducts: DeliveredProduct[];
  deliveryNotesCount: number;
};

export const generateDeliveryNotesDetails = (deliveryNotesDetails: any) => {
  let quotationId;
  const deliveryNotesCount = deliveryNotesDetails.count;
  const deliveryNotes = deliveryNotesDetails?.data?.map((data: any) => {
    quotationId = data.quotationId;
    return {
      id: data?.id,
      status: data?.status,
      date: data?.createdAt,
      createdBy: {
        name:
          data?.deliveryNoteCreatedBy?.firstName +
          (data?.deliveryNoteCreatedBy?.lastName || ""),
        role: data?.deliveryNoteCreatedBy?.role,
        id: data?.deliveryNoteCreatedBy?.id,
      },
      deliveredProducts: data?.deliveredProducts?.map((product: any) => ({
        productId: product?.productId,
        quantityDelivered: product?.quantityDelivered,
        productName: product?.productDetails?.name
      })),
    };
  });
  const deliveredProducts = deliveryNotes.reduce(
    (accumulator: any, currentOrder: any) => {
      currentOrder.deliveredProducts.forEach((product: any) => {
        const existingProduct = accumulator.find(
          (item: any) => item.productId === product.productId
        );
        if (existingProduct) {
          existingProduct.quantityDelivered += product.quantityDelivered;
        } else {
          accumulator.push({
            productId: product.productId,
            quantityDelivered: product.quantityDelivered,
            productName: product.name
          });
        }
      });
      return accumulator;
    },
    []
  );
  return {
    quotationId,
    deliveredProducts,
    deliveryNotes,
    deliveryNotesCount,
  };
};

import * as React from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableHOC from "./Components/TableHOC";
import { ThreeDots } from "react-loader-spinner";

export type Iprops = {
  data: any[];
  isLoading: boolean;
};

const CustomizedTables: any = ({ data, isLoading }: Iprops) => {
  return (
    <TContainer>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRowHeader>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Trips</StyledTableCell>
            <StyledTableCell>slogan</StyledTableCell>
            <StyledTableCell>Trips1</StyledTableCell>
            <StyledTableCell>Name2</StyledTableCell>
          </StyledTableRowHeader>
        </TableHead>
        <TableBody>
          {data?.map((row: any, idx: any) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell key={row.name + idx}>{row.name}</StyledTableCell>
              <StyledTableCell key={row.trips + idx}>
                {row.trips}
              </StyledTableCell>
              <StyledTableCell key={row?.airline[0]?.slogan + idx}>
                {row?.airline[0]?.slogan}
              </StyledTableCell>
              <StyledTableCell key={row?.airline[0]?.country + idx}>
                {row?.airline[0]?.country}
              </StyledTableCell>
              <StyledTableCell key={row?.airline[0]?.name + idx}>
                {row?.airline[0]?.name}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#1B2850" height={50} width={50} />
        </div>
      )}
    </TContainer>
  );
};

export default TableHOC(CustomizedTables, {
  url: "brands",
});

export const TContainer = styled(TableContainer)`
  height: 100%;
  overflow-x: auto;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: var(--hover) !important;
  }
  td {
    font-family: "Nunito", sans-serif !important;
    padding: 8px !important;
    color: #637381;
    font-weight: 500;
    font-size: 14px;
  }
  td:nth-child(1) {
    color: #000;
  }
  height: 58px !important;
`;

export const StyledTableRowHeader = styled(TableRow)`
  height: 40px !important;
  th {
    font-family: "Nunito", sans-serif !important;
    padding: 12px 8px 12px 8px; !important;
    font-weight: 700;
    background: #dbe3ed !important;
    font-size: 14px;
    color: var(--primary-header);
    border-bottom: none;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.w-25 {
    width: 100px;
  }
  .description-wrapper {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
  }
  .title-wrapper {
    display: flex;
    span {
      margin-left: 10px;
    }
    .title-icon {
      cursor: pointer;
    }
  }
  &.w-450 {
    width: 450px;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .value-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .table_btn {
    width: 90px;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

import { styled } from "styled-components";

interface IPropsStyledHeader {
  multiline?: boolean;
}

export const StyledHeader = styled.h4<IPropsStyledHeader>`
  font-weight: 600;
  margin: 0 8px 0 8px !important;
  color: rgb(36, 38, 55);
  display: flex;
  align-items: center;
  ${(props) =>
    props?.multiline &&
    "color: #758283; font-size: 16px; font-weight: 400;padding-top: 16px;"}
`;

export const StyledJobCard = styled.div`
  height: 100%;
  hr {
    margin: 8px 0px !important;
  }
  .hr {
    margin: 8px 8px !important;
  }
  .comment-input {
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    outline: none;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    background: none;
    align-items: center;
  }
  .mark-complete {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
  }
`;

export const DetailsWrapper = styled.div`
  padding: 8px;
`;

export const AssignedUserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  width: 300px;
  cursor: pointer;
  padding: 4px;
  margin-top: 8px;
  border-radius: 8px;
  &:hover {
    border: 1px solid #ccc;
  }
  .assignee-height {
    height: 300px;
    overflow-y: scroll;
  }
  .assginee-detail {
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 14px;
    font-weight: 700;
    font-family: "Nunito", sans-serif !important;
  }
  .name {
    font-size: 16px;
    color: var(--primary-header);
    border-bottom: none;
    text-transform: capitalize;
  }
  .date-color {
    color: var(--primary);
  }
`;

export const AssigneeModalWrapper = styled.div`
  position: relative;
  .input-wrapper {
    display: flex;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;
  }
  h3 {
    text-align: center;
  }
  .close-icon {
    color: #a0a0a0;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .search-input {
    flex: 1;
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    width: 80%;
  }

  .search-button {
    padding: 10px 15px;
    background-color: #ec2026 !important;
    color: white;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 20%;
  }

  .search-button:hover {
    background-color: #0056b3;
  }
  span {
    font-size: 14px;
    font-weight: 700;
    font-family: "Nunito", sans-serif !important;
  }
  .name {
    font-size: 16px;
    color: var(--primary-header);
    border-bottom: none;
  }
  .assginee-detail {
    display: flex;
    flex-direction: column;
  }
  .assignee-height {
    margin-top: 20px;
    height: 300px;
    overflow-y: scroll;
  }
  .assignee-list {
    cursor: pointer;
    &:hover {
      background: #eaeaea;
    }
  }
`;

export const CommentBoxWrapper = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  margin-bottom: 4px;
  margin-top: 10px;
  input {
    height: 60%;
    width: 100%;
    padding-left: 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
    color: rgba(19, 19, 19, 0.5);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    outline: none;
    border: none;
    background: none;
  }
`;

export const JobDetailsWrapper = styled.div`
  padding: 8px;
  h5 {
    text-align: center;
  }
  ol,
  ul {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  ul li {
    display: flex;
  }
  .titleValue {
    font-weight: 600;
    color: var(--primary-header);
  }
  .title {
    width: 200px;
  }
`;

export const CommentWrapper = styled.div`
  padding: 0px 8px;
  margin-bottom: 20px;
  overflow-y: scroll;
  .assginee-detail {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 14px;
      font-weight: 700;
      font-family: "Nunito", sans-serif !important;
      display: flex;
      .comment-time {
        font-size: 12px;
        font-weight: 500;
        text-transform: none;
        margin-left: 5px;
        color: rgba(19, 19, 19, 0.5);
      }
      .comment-name {
        color: #fff;
        display: flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #b6b6b6;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    p {
      margin: 0 !important;
      color: rgba(19, 19, 19, 0.8);
    }
  }
`;

export const AssignedUserWrapperUser = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  width: 75%;
  cursor: pointer;
  padding: 4px;
  margin-top: 8px;
  border-radius: 8px;
`;

export const StyledBtn = styled.button`
  outline: none;
  border: none;
  background: var(--primary);
  color: #fff;
  border-radius: 4px;
  width: 40px;
  font-size: 24px;
  font-weight: 900;
  margin-left: 10px;
`;

export const StyledJobCardHeading = styled.h5`
  margin: 0px 0px 0px 12px;
  color: #131313;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  opacity: 0.64;
`;

export const StyledStatusButton = styled.div<{
  background: string;
  color: string;
}>`
  button {
    background: ${(props) => props?.background ?? "white"};
    border: 1px solid ${(props) => props?.background ?? "white"};
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-family: Poppins;
    min-width: 120px;
    &:hover {
      background: ${(props) => props?.background ?? "white"};
      color: ${(props) => props?.color ?? "black"};
    }
  }
`;

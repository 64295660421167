import React from "react";
import Pagination from "./Pagination";
import { tableServices } from "../../../../services/private.services";
import SearchInput from "../../SearchInput";
// import debounce from "lodash/debounce";

type IPropsEntity = {
  url: string;
};

type State = {
  page: number;
  data: any[];
  isLoading: boolean;
  totalCount: number;
  rowsPerpage: number;
  search: string;
  inputRef: any;
};

export type Iprops = {
  data: any[];
  isLoading: boolean;
};
const TableHOC = (
  WrappedComponent: (props: Iprops) => React.JSX.Element,
  entity: IPropsEntity
) => {
  return class extends React.Component<any, State> {
    state = {
      page: 1,
      data: [],
      isLoading: false,
      totalCount: 0,
      rowsPerpage: 10,
      search: "",
      inputRef: React.createRef(),
    };

    componentDidMount(): void {
      this.getData();
    }

    componentDidUpdate(
      prevProps: Readonly<{}>,
      prevState: State,
      snapshot?: any
    ): void {
      if (
        prevState?.page !== this.state.page ||
        prevState?.rowsPerpage !== this.state.rowsPerpage
      ) {
        this.getData();
      }
      if (prevState?.search !== this.state.search) {
        // debounce(this.getData() as any, 2000);
      }
    }

    getData = async () => {
      this.setState({
        ...this.state,
        data: [],
        isLoading: true,
      });
      const res = await tableServices({
        url: entity?.url,
        page: this.state.page,
        rowsPerpage: this.state.rowsPerpage,
        search: this.state.search,
      });
      this.setState({
        ...this.state,
        data: res?.data,
        isLoading: false,
        totalCount: res.totalPassengers,
      });
    };

    handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      page: number
    ) => {
      this.setState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        ...this.state,
        page: 1,
        rowsPerpage: +event.target.value,
      });
    };

    handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState((prevState) => ({
        ...prevState,
        search: event.target.value,
      }));
    };

    render() {
      return (
        <>
          <SearchInput
            onChange={this.handleOnChangeSearch}
            value={this.state.search}
          />
          <WrappedComponent
            data={this.state.data}
            isLoading={this.state.isLoading}
          />
          <Pagination
            count={this.state.totalCount}
            rowsPerpage={this.state.rowsPerpage}
            onPageChange={this.handleChangePage}
            page={this.state.page - 1}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </>
      );
    }
  };
};

export default TableHOC;

import React from "react";
import { Grid } from "@mui/material";
import InputField from "../../../components/Common/Input";
import Dropdown from "../../../components/Common/Dropdown";
import { jobTypes, serviceTypes } from "../../../utils/jobCards";
import Button from "../../../components/Common/Button";
import CreateJobForm from "./CreateJob.form";
import { StyledError } from "../../../components/styles";
import UserSearchableDropdown from "../components/UserSearchableDropdown";
import {
  fetchCustomerList,
  fetchUserList,
} from "../../../services/private.services";
import ProductSearchableDropdown from "../../Inventory/CreateInventory/ProductSearchableDropdown";
import { fetchProductList } from "../../../services/inventory.services";
import { StyledBtn } from "../components/styles";
import CreateCustomer from "../../Customers/CreateCustomer";
import IModal from "../../../components/Common/Modal/IModal";
import CommonDatePicker from "../../../components/Common/DatePicker";

type IProps = {
  handleClose: () => void;
  getList: () => void;
};

const CreateJobCard = ({ handleClose, getList }: IProps) => {
  const formik = CreateJobForm(handleClose, getList);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleModalClose = () => {
    setOpen(false);
  };

  const handleDateChange = (value: any) => {
    formik?.setFieldValue("dueDate", value?.$d);
  };
  return (
    <>
      <Grid container justifyContent="flex-start">
        <Grid xs={12} sm={6} lg={6} item justifyContent="flex-start">
          <h4 className="mb-3">Create Job Card</h4>
        </Grid>
      </Grid>
      <Grid spacing={3} container>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Title</label>
          <InputField formik={formik} name="jobTitle" placeholder="Job Title" />
          <StyledError>
            {formik.touched?.jobTitle && formik.errors["jobTitle"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Due Date</label>
          <div>
            <CommonDatePicker handleDateChange={handleDateChange} />
          </div>
          <StyledError>
            {formik.touched?.dueDate && formik.errors["dueDate"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Job Type</label>
          <Dropdown
            options={jobTypes}
            formik={formik}
            name="type"
            value={formik?.values?.type}
          />
          <StyledError>
            {formik.touched?.type && formik.errors["type"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Assignee</label>
          <UserSearchableDropdown
            placeholder="Search User"
            fetchList={fetchUserList}
            key="User"
            onHandleChange={(data: any) => {
              formik?.setFieldValue("userId", data?.value);
            }}
          />
          <StyledError>
            {formik.touched?.userId && formik.errors["userId"]}
          </StyledError>
        </Grid>
        {formik?.values?.type !== "task" && (
          <>
            <Grid xs={12} sm={6} lg={4} item>
              <label>Customer</label>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {!open && (
                  <UserSearchableDropdown
                    placeholder="Search Customer"
                    fetchList={fetchCustomerList}
                    key="Customer"
                    onHandleChange={(data: any) => {
                      formik?.setFieldValue("customerId", data?.value);
                    }}
                  />
                )}
                <IModal
                  open={open}
                  handleOpen={handleOpen}
                  handleClose={handleModalClose}
                  width={50}
                >
                  <CreateCustomer handleClose={handleModalClose} />
                </IModal>
                <StyledBtn onClick={handleOpen}>+</StyledBtn>
              </div>
              <StyledError>
                {formik.touched?.customerId && formik.errors["customerId"]}
              </StyledError>
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <label>Product</label>
              <ProductSearchableDropdown
                placeholder="Search Product"
                fetchList={fetchProductList}
                key="Product"
                onHandleChange={(data: any) => {
                  formik?.setFieldValue("productId", data?.value);
                }}
              />
              <StyledError>
                {formik.touched?.customerId && formik.errors["customerId"]}
              </StyledError>
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <label>Service Type</label>
              <Dropdown
                options={serviceTypes}
                formik={formik}
                name="serviceType"
              />
              <StyledError>
                {formik.touched?.serviceType && formik.errors["serviceType"]}
              </StyledError>
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <label>Problem</label>
              <InputField
                formik={formik}
                name="reportedProblem"
                placeholder="Problem"
              />
              <StyledError>
                {formik.touched?.reportedProblem &&
                  formik.errors["reportedProblem"]}
              </StyledError>
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <label>Pre Condition</label>
              <InputField
                formik={formik}
                name="precondition"
                placeholder="Pre Condition"
              />
              <StyledError>
                {formik.touched?.precondition && formik.errors["precondition"]}
              </StyledError>
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <label>Accessories</label>
              <InputField
                formik={formik}
                name="accessories"
                placeholder="Accessories"
              />
              <StyledError>
                {formik.touched?.accessories && formik.errors["accessories"]}
              </StyledError>
            </Grid>
          </>
        )}
        <Grid xs={12} sm={6} lg={4} item>
          <label>Description</label>
          <InputField
            formik={formik}
            name="jobDescription"
            multiline
            placeholder="Description"
          />
        </Grid>
        <StyledError>
            {formik.touched?.jobDescription && formik.errors["jobDescription"]}
          </StyledError>
        <Grid xs={12} container item spacing={2} justifyContent="flex-end">
          <Grid xs={12} sm={6} lg={2} item>
            <Button onClick={formik?.handleSubmit}>Submit</Button>
          </Grid>
          <Grid xs={12} sm={6} lg={2} item>
            <Button buttontypes="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateJobCard;

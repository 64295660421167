import { useFormik } from "formik";
import { createJobCardValidations } from "../validations";
import { toast } from "react-toastify";
import { createJobCard } from "../../../services/private.services";

const CreateJobForm = (handleClose: () => void, getList: () => void) => {
  return useFormik({
    initialValues: {
      type: "",
      reportedProblem: "",
      precondition: "",
      accessories: "",
      serviceType: "",
      userId: "",
      jobDescription: "",
      jobTitle: "",
      customerId: "",
      productId: "",
      dueDate: "",
    },
    validationSchema: createJobCardValidations,
    onSubmit: async (values) => {
      if (values?.type === "task") {
        await createJobCard({
          type: values?.type,
          userId: values?.userId,
          jobDescription: values?.jobDescription,
          jobTitle: values?.jobTitle,
          dueDate: values?.dueDate,
        });
      } else {
        await createJobCard(values);
      }
      toast.success("New job card successfully");
      handleClose();
      getList();
    },
  });
};

export default CreateJobForm;

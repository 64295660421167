import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";

type Props = {
  type?: string;
  placeholder?: string;
  required?: boolean;
  inputwidth?: string | any;
  formik?: any;
  name?: string | any;
  multiline?: boolean;
  handleChange?: (event: any) => void;
  handleBlur?: (event: any) => void;
  onMouseOver?: (event: any) => void;
  value?: string | number;
  hasError?: boolean;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  login?: boolean;
};

const InputField = ({
  type = "text",
  placeholder,
  required,
  inputwidth = "100",
  formik,
  name,
  multiline = false,
  handleChange,
  handleBlur,
  onMouseOver,
  value,
  hasError = false,
  autoFocus = false,
  className,
  disabled = false,
  login = false,
  ...props
}: Props) => {
  return (
    <StyledInput
      {...props}
      multiline={multiline}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
      inputwidth={inputwidth}
      onBlur={formik?.handleBlur || handleBlur}
      value={formik?.values[`${name}`] || value || ""}
      onChange={formik?.handleChange || handleChange}
      autoFocus={autoFocus}
      className={className}
      onMouseEnter={onMouseOver}
      disabled={disabled}
      login={login}
      error={
        Boolean(formik?.touched[`${name}`] && formik?.errors[`${name}`]) ||
        hasError
      }
    />
  );
};

export default InputField;

const StyledInput: any = styled(TextField)`
  width: ${(props: any) => props?.inputwidth && parseInt(props?.inputwidth)}%;
  input {
    height: 40px !important;
    padding: 0 15px !important;
    font-family: "Nunito", sans-serif !important;
    ${(props: any) =>
      props?.login &&
      `background: rgba(50, 61, 134, 0.11) !important;
      outline: none !important;
      border-radius: 8px !important;
      border: 2px solid #303e8a !important;
      color: #2b3b7c !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      `}
  }
  .MuiInputBase-root {
    ${(props: any) => props?.login && `border-radius: 8px !important;`}
  }
  input:disabled {
    background-color: #eee !important;
  }

  input::-webkit-input-placeholder {
    font-family: "Nunito", sans-serif !important;
  }
  input::-moz-placeholder {
    font-family: "Nunito", sans-serif !important;
  }
  input:-ms-input-placeholder {
    font-family: "Nunito", sans-serif !important;
  }
  input:-moz-placeholder {
    font-family: "Nunito", sans-serif !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

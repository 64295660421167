import styled from "styled-components";

export const ProductWrapper = styled.section`
  background: #f2f5fc;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
  .productContainer {
    // .slick-cloned{
    //   display:none;
    // }
    max-width: 1280px;
    width: 100%;
    .slick-prev,
    .slick-next {
      background: #dbdde1;
      height: 200px;
      border-radius: 10px;
    }
    .slick-prev {
      left: -40px !important;
    }

    .productWrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 50px 0px;
    }
    h2 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      margin: 0;
      padding: 0;
      text-align: center;
      margin-bottom: 24px;
    }
    .buttonWrapper {
      margin-top: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    @media (max-width: 959px) {
      max-width: 100%;
      width: 100%;
      padding: 0px 60px;
    }
  }
`;

export const ProductItemWrapper = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 300px;
  height: 400px;
  overflow: hidden;
  img {
    height: 180px;
    object-fit: fill;
    width: 100%;
    padding-top: 10px;
  }
  .productDetail {
    padding: 20px 20px;
    h6 {
      color: #d93a35;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    h1 {
      color: #2b3b7c;
      font-size: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
      -webkit-box-orient: vertical;
      overflow: hidden;
      position: relative;
    }
  }
`;

export const ProductButton = styled.button`
  text-transform: uppercase;
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  padding: 10px 10px;
  background: var(--primary);
  border-radius: 6px;
`;

export const Wrapper = styled.div`
  position: relative;
  a {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

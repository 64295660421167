import React from "react";
import Modal from "../../components/Common/Modal";
import AddInventory from "./CreateInventory/AddInventory";
import { MainWrapper, StyledHeader } from "../../components/styles";
import { TContainer } from "../../components/Common/Table";
import { Grid } from "@mui/material";
import SearchInput from "../../components/Common/SearchInput";
import ToggleBtn from "../../components/Common/ToggleBtn";
import { inventoryTypes } from "../../utils/jobCards";
import useInventoryList from "./ListInventory/useInventoryList";
import InventoryTableList from "./ListInventory/InventoryTableList";
import IModal from "../../components/Common/Modal/IModal";
import EditInventory from "./CreateInventory/EditInventory";
import { getInventoryTypeName } from "./utils";
import ProductTableList from "./ListInventory/ProductTableList";
import CreateProduct from "./CreateInventory/CreateProduct";
import EditProduct from "./CreateInventory/EditProduct";

const Home = () => {
  const {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    handleOpen,
    handleClose,
    open,
    inventoryType,
    setInventoryType,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    handleChangeInventoryId,
    inventoryId,
    getList,
  } = useInventoryList();
  return (
    <MainWrapper>
      <TContainer>
        <Grid container justifyContent="space-between" mb={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader>Inventory</StyledHeader>
            <SearchInput onChange={handleOnChangeSearch} />
          </Grid>

          <Grid xs={12} sm={3} lg={1.5} item justifyContent="flex-end">
            <Modal
              title={getInventoryTypeName(inventoryType, "Add")}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              width={inventoryType === inventoryTypes.PRODUCTS ? 50 : 25}
            >
              {inventoryType === inventoryTypes.PRODUCTS ? (
                <CreateProduct type={inventoryType} handleClose={handleClose} />
              ) : (
                <AddInventory type={inventoryType} handleClose={handleClose} />
              )}
            </Modal>
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid xs={12} sm={3} lg={3} item>
            <ToggleBtn
              items={[
                { name: "Products", value: inventoryTypes?.PRODUCTS },
                { name: "Brand", value: inventoryTypes?.BRAND },
                { name: "Category", value: inventoryTypes?.CATEGORY },
              ]}
              value={inventoryType}
              setValue={setInventoryType}
            />
          </Grid>
        </Grid>
        {inventoryType === inventoryTypes.PRODUCTS ? (
          <ProductTableList
            data={list}
            loading={loading}
            count={totalCount}
            rowsPerpage={pagination?.perPage}
            onPageChange={handleChangePage}
            page={pagination?.page - 1}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangeInventoryId={handleChangeInventoryId}
          />
        ) : (
          <InventoryTableList
            data={list}
            loading={loading}
            tableHeader={[
              {
                name: "Name",
                key: "name",
                idx: 1,
              },
              {
                name: "Description",
                key: "description",
                idx: 2,
              },
              {
                name: "Status",
                key: "status",
                idx: 3,
              },
              {
                name: "Created date",
                key: "firstName",
                idx: 4,
              },
              {
                name: "Actions",
                key: "Actions",
                idx: 6,
              },
            ]}
            count={totalCount}
            rowsPerpage={pagination?.perPage}
            onPageChange={handleChangePage}
            page={pagination?.page - 1}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            type={inventoryType}
            handleChangeInventoryId={handleChangeInventoryId}
          />
        )}
      </TContainer>
      <IModal
        open={openEditModal}
        handleOpen={handleOpenEditModal}
        handleClose={handleCloseEditModal}
        width={inventoryType === inventoryTypes.PRODUCTS ? 50 : 25}
      >
        {inventoryType === inventoryTypes.PRODUCTS ? (
          <EditProduct
            type={inventoryType}
            handleClose={handleCloseEditModal}
            inventory={inventoryId}
            getList={getList}
          />
        ) : (
          <EditInventory
            type={inventoryType}
            handleClose={handleCloseEditModal}
            inventory={inventoryId}
            getList={getList}
          />
        )}
      </IModal>
    </MainWrapper>
  );
};

export default Home;

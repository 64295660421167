import { toast } from "react-toastify";
import { pickBy } from "lodash";

import axios from "axios";

export const axiosPublicInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    async ({ response }) => {
      return Promise.reject(response);
    }
  );
  return axiosInstance;
};

export const signin = async (credentails: {
  email: string;
  password: string;
}) => {
  try {
    const response = await axiosPublicInstance().post(
      `auth/local/login`,
      credentails
    );
    return Promise.resolve(response);
  } catch (result: any) {
    toast.error(result?.data?.message);
    return Promise.reject(result);
  }
};

export const contactUs = async (credentails: any) => {
  try {
    const response = await axiosPublicInstance().post(`/contact`, credentails);
    return Promise.resolve(response);
  } catch (result: any) {
    toast.error(result?.data?.message);
    return Promise.reject(result);
  }
};

export const fetchPublicProducts = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    brandId: query?.brandId,
    categoryId: query?.categoryId,
    isActive: query?.isActive,
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosPublicInstance().get(`products/public/info?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

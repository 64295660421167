import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
    justifyContent: "space-between",
  },

  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  titleContainer: { flexDirection: "row", marginTop: 24 },

  logo: { width: "90%", height: 130 },

  invoiceTitle: { fontSize: 16, textAlign: "center", color: "#000000", fontWeight: "bold" },
  reportTitle: { fontSize: 16, textAlign: "center" },

  addressTitle: { fontSize: 11, color: "#878A99" },

  invoice: { fontWeight: "bold", fontSize: 20 },

  invoiceNumber: { fontSize: 11, fontWeight: "bold" },

  address: { fontWeight: 400, fontSize: 10 },

  theader: {
    fontSize: 10,
    fontStyle: "bold",
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: 30,
    backgroundColor: "#DEDEDE",
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  tTopHeader: {
    fontSize: 9,
    fontStyle: "bold",
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    height: 38,
    backgroundColor: "#DEDEDE",
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  tHeadId: {
    fontSize: 10,
    fontStyle: "bold",
    paddingTop: 4,
    paddingLeft: 7,
    paddingRight: 7,
    width: 30,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: 30,
    backgroundColor: "#DEDEDE",
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

  tbody: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: 20,
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  tbodyId: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    paddingRight: 7,
    width: 30,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: 20,
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  total: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: "whitesmoke",
    borderBottomWidth: 1,
  },
  totalId: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    paddingRight: 7,
    width: 30,
    borderColor: "whitesmoke",
    borderBottomWidth: 1,
  },

  tbody2: {
    flex: 2,
    borderRightWidth: 1,
    textOverflow: "ellipsis",
  },
});

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import notFound from "././assets/svg/not-found.svg";

const NotFound = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack direction="column" spacing={2}>
        <img src={notFound} height="300px" width="300px" />
        <Link to="/crm-auth">
          <Button style={{ width: "300px" }} variant="outlined">
            GO BACK
          </Button>
        </Link>
      </Stack>
    </Box>
  );
};

export default NotFound;

import React, { useEffect, useState } from "react";
import { Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import GenerateDeliveryNotes from "./GenerateDeliveryNotes";
import { StyledTab } from "./styles";
import QuotedProducts from "./QuotedProducts";
import { useDispatch } from "react-redux";
import { usePurchaseOrderDetails } from "../../../store/purchaseOrder/purchaseOrder.selector";
import { getDeliveryNotesDetails } from "../../../services/quotation.services";
import { AppDispatch } from "../../../store/store";
import CustomizedAccordions from "./PreviousDeliveryNotes";
import { DeliveredNotes } from "../../../utils/purchaseOrder";

const DeliveryNotes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { purchaseOrder, deliveryNotes } = usePurchaseOrderDetails();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (purchaseOrder) {
      dispatch(getDeliveryNotesDetails(purchaseOrder.id));
    }
  }, [purchaseOrder, dispatch]);

  return (
    <>
      <TabContext value={value}>
        <Tabs
          onChange={handleChange}
          value={value}
        >
          <StyledTab label="Generate delivery note" value="1" />
          <StyledTab
            label={`See previous delivery notes(${
              deliveryNotes?.deliveryNotes?.filter(
                (notes: DeliveredNotes) => notes.deliveredProducts.length !== 0
              )?.length
            })`}
            value="2"
          />
          <StyledTab label="Quoted products" value="3" />
        </Tabs>
        <div>
          <TabPanel value={`1`}>
            <GenerateDeliveryNotes
              deliveredProducts={deliveryNotes?.deliveredProducts}
            />
          </TabPanel>
          <TabPanel value={`2`}>
            <CustomizedAccordions />
          </TabPanel>
          <TabPanel value={`3`}>
            <QuotedProducts
              purchaseOrder={purchaseOrder}
              deliveredProducts={deliveryNotes?.deliveredProducts}
            />
          </TabPanel>
        </div>
      </TabContext>
    </>
  );
};
export default DeliveryNotes;

import styled from "styled-components";

export const FooterStyled = styled.div`
  height: 98px;
  width: 100%;
  background: #fff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  .footerContainer {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: baseline;
    h1 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
    }
    h2 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 72px;
    }
  }
`;

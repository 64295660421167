import { styled } from "styled-components";

export const OurServicesStyled = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .ourServicesContainer {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      color: #2b3b7c;
      text-align: center;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 45px;
    }
    .serviceItemsContainer {
      max-width: 960px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0px 50px;
    }
  }
`;

export const OurServicesCard = styled.div`
  cursor: pointer;
  width: 285px;
  img {
    width: 277.446px;
    height: 333.665px;
  }
  h3 {
    color: #2b3b7c;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    margin: 0;
    padding: 0;
    width: 285px;
    margin-left: 15px;
  }
  p {
    font-family: Poppins;
    color: #5e5b5b;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 251px;
    opacity: 0.5;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-left: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Adjust the number of lines as needed */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

import { Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Pagination from "../../../components/Common/Table/Components/Pagination";
import {
  fetchBrandListPublic,
  fetchCategoryListPublic,
} from "../../../services/inventory.services";
import ProductSearchableDropdown from "../../Inventory/CreateInventory/ProductSearchableDropdown";
import Header from "../components/Header/Header";
import { ProductWrapper } from "../components/Products/ProductStyled";
import useProductList from "../components/Products/useProductList";
import { useState } from "react";
import { ProductItem } from "../components/Products/ProductItem";

const ProductsListing = () => {
  const {
    list,
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount,
    handleFilter,
  } = useProductList();
  const [labels, setLabels] = useState({
    brand: "Search brand",
    category: "Search Category",
  });

  const clearFilters = () => {
    setLabels(() => ({
      brand: "Search brand",
      category: "Search Category",
    }));
    handleFilter("brandId", "");
    handleFilter("categoryId", "");
  };
  return (
    <>
      <Header />
      <ProductWrapper>
        <div className="productContainer">
          <h2>Our Products</h2>
          <Grid spacing={3} container mb={3}>
            <Grid xs={11} sm={12} lg={5.5} item>
              <ProductSearchableDropdown
                placeholder="Search brand"
                fetchList={fetchBrandListPublic}
                key="brand"
                defaultValue={labels?.brand}
                value={labels.brand}
                onHandleChange={(data: any) => {
                  setLabels((label) => ({
                    ...label,
                    brand: data?.label,
                  }));
                  handleFilter("brandId", data?.value);
                }}
              />
            </Grid>
            <Grid xs={11} sm={12} lg={5.5} item>
              <ProductSearchableDropdown
                placeholder="Search Category"
                fetchList={fetchCategoryListPublic}
                key="category"
                defaultValue={labels?.category}
                value={labels.category}
                onHandleChange={(data: any) => {
                  setLabels((label) => ({
                    ...label,
                    category: data?.label,
                  }));
                  handleFilter("categoryId", data?.value);
                }}
              />
            </Grid>
            <Grid xs={1} sm={1} lg={1} item>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 4,
                }}
              >
                <ClearIcon onClick={() => clearFilters()} />
              </div>
            </Grid>
          </Grid>
          <div className="productWrapper">
            {list.map((item, index) => {
              return <ProductItem key={index} products={item} />;
            })}
          </div>
        </div>
      </ProductWrapper>
      <Pagination
        count={totalCount}
        rowsPerpage={pagination?.perPage}
        onPageChange={handleChangePage}
        page={pagination?.page - 1}
        rowsPerPageOptions={[8, 24, 48]}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ProductsListing;

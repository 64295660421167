import styled from "styled-components";

export const SecondaryFooterContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  .secondaryFooterWrapper {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 0px;
    .footerItems {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .location {
        width: 360px;
        height: 148px;
      }
      h3 {
        color: #2b3b7c;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px;
      }
      ul {
        display: flex;
        flex-direction: column;
        padding: 0;
      }
      .contactUs li {
        padding: 6px;
      }
      ul li a{
        color: #9f9f9f;
        text-decoration: none;
      }
      ul li {
        color: #9f9f9f;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        cursor: pointer;
      }
    }
  }
`;

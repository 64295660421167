import React, { ReactNode } from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { colors } from "../../../Theme/colors";

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | any;
  buttontypes?: "primary" | "secondary" | "cancel" | "success";
  loading?: boolean;
};

const buttonColors: any = {
  primary: {
    borderColor: colors.primary,
    backgroundColor: colors.primary,
    hoverColor: colors.primary,
    color: colors.primary,
  },
  secondary: {
    borderColor: colors.secondary,
    backgroundColor: colors.secondary,
    hoverColor: colors.secondary,
    color: colors.secondary,
  },
  cancel: {
    borderColor: colors.cancel,
    backgroundColor: colors.cancel,
    hoverColor: colors.cancelHover,
    color: colors.cancel,
  },
  success: {
    borderColor: colors.success,
    backgroundColor: colors.success,
    hoverColor: colors.success,
    color: colors.success,
  },
};

const SButton = ({
  children,
  disabled = false,
  onClick,
  buttontypes = "primary",
  loading = false,
}: Props) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      buttontypes={buttontypes}
      loading={loading}
    >
      {children}
    </StyledButton>
  );
};

export default SButton;

const StyledButton: any = styled(Button)`
  width: 100%;
  padding: 12px;
  color: #fff !important;
  font-weight: 700;
  font-size: 1rem !important;
  border-color: ${(props: any) =>
    buttonColors[props.buttontypes].borderColor} !important;
  border-radius: 5px;
  background-color: ${(props: any) =>
    buttonColors[props.buttontypes].backgroundColor} !important;
  border: 2px solid
    ${(props: any) => buttonColors[props.buttontypes].borderColor} !important;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-family: "Nunito", sans-serif !important;
  text-transform: none !important;
  &.Mui-disabled {
    background: #e6425e !important;
  }
  &:hover {
    color: #fff !important;
    opacity: 0.8;
    border: 2px solid
      ${(props: any) => buttonColors[props.buttontypes].borderColor} !important;
  }
  &:focus {
    outline: none;
  }
`;

import React from "react";
import { TContainer } from "../../../components/Common/Table";
import SearchInput from "../../../components/Common/SearchInput";
import Modal from "../../../components/Common/Modal";
import CreateCustomer from "../CreateCustomer";
import { MainWrapper, StyledHeader } from "../../../components/styles";
import { Grid } from "@mui/material";
import useCustomerList from "./useCustomerList";
import CustomerTableList from "./CustomerTable";
import IModal from "../../../components/Common/Modal/IModal";
import UpdateCustomer from "../UpdateCustomer";
import DeleteModal from "../../../components/Common/DeleteModal/DeleteModal";

const CustomerList = () => {
  const {
    loading,
    userList,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    getUserList,
    openEditModal,
    closeOpenEditModal,
    showEditModal,
    handleCustomerDetails,
    customerDetails,
    onSetCustomerId,
    closeDeleteModal,
    showDeleteModal,
    openDeleteModal,
    onHandleDelete,
  } = useCustomerList();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    getUserList();
  };
  return (
    <MainWrapper>
      <TContainer>
        <Grid container justifyContent="space-between" mb={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader>Customer Management</StyledHeader>
            <SearchInput onChange={handleOnChangeSearch} />
          </Grid>
          <Grid xs={12} sm={3} lg={2} item justifyContent="flex-end">
            <Modal
              title="Create Customer"
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              width={50}
            >
              <CreateCustomer handleClose={handleClose} />
            </Modal>
          </Grid>
        </Grid>
        <CustomerTableList
          data={userList}
          loading={loading}
          tableHeader={[
            {
              name: "Name",
              key: "name",
              idx: 1,
            },
            {
              name: "Email",
              key: "firstName",
              idx: 2,
            },
            {
              name: "Phone Number",
              key: "firstName",
              idx: 3,
            },
            {
              name: "Address",
              key: "firstName",
              idx: 4,
            },
            {
              name: "Role",
              key: "role",
              idx: 5,
            },
            {
              name: "Actions",
              key: "Actions",
              idx: 6,
            },
          ]}
          count={totalCount}
          rowsPerpage={pagination?.perPage}
          onPageChange={handleChangePage}
          page={pagination?.page - 1}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOpenEditModal={handleCustomerDetails}
          onSetCustomerId={onSetCustomerId}
        />
      </TContainer>
      <IModal
        open={showEditModal}
        handleOpen={openEditModal}
        handleClose={closeOpenEditModal}
        width={50}
      >
        <UpdateCustomer
          handleClose={closeOpenEditModal}
          customerDetails={customerDetails}
          getUserList={getUserList}
        />
      </IModal>
      <IModal
        open={showDeleteModal}
        handleOpen={openDeleteModal}
        handleClose={closeDeleteModal}
        width={30}
      >
        <DeleteModal
          handleClose={closeDeleteModal}
          handleSubmit={onHandleDelete}
        />
      </IModal>
    </MainWrapper>
  );
};

export default CustomerList;

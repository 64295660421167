import { FC, Fragment } from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import logo from "../../assets/images/saharaText.png";
import footer from "../../assets/images/footer.png";
import { styles } from "./styles";
import { convertAmountToWords, getFormattedDate } from "../../utils/utils";
import {
  AddressProps,
  InvoiceDetailsProps,
  TableBodyProps,
  TableTotalProps,
  AmountInTextProps,
  TableProps,
} from "./types";

const Title = () => (
  <View style={styles.titleContainer}>
    <View style={styles.column}>
      <Image style={styles.logo} src={logo} />
      <Text style={styles.invoiceTitle}>INVOICE</Text>
    </View>
  </View>
);

const InvoiceDetails: FC<InvoiceDetailsProps> = ({ invoiceNumber, name, date }) => {
  return (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoiceNumber}>Invoice number: {invoiceNumber} </Text>
          <Text style={styles.invoiceNumber}>Ref number: {invoiceNumber} </Text>
        </View>
        <View>
          <Text style={styles.invoiceNumber}>Name: {name}</Text>
          <Text style={styles.invoiceNumber}>Date: {getFormattedDate(date)} </Text>
        </View>
      </View>
    </View>
  );
};

const Address: FC<AddressProps> = ({ address }) => (
  <View style={[styles.titleContainer, { marginBottom: 20 }]}>
    <View style={styles.spaceBetween}>
      <View style={{ maxWidth: 200 }}>
        <Text style={styles.addressTitle}>Billing Address :</Text>
        <Text style={styles.address}>{address}</Text>
      </View>
      <View style={{ maxWidth: 200 }}>
        <Text style={styles.addressTitle}>Shipping Address :</Text>
        <Text style={styles.address}>{address}</Text>
      </View>
    </View>
  </View>
);

const TableTopHead = () => (
  <View style={{ width: "100%", flexDirection: "row" }}>
    <View style={[styles.tTopHeader]}>
      <Text>Order No.</Text>
      {/* <Text>23424234, 1232</Text> */}
      {/* <Text>12-May-2022, 12-May-2022</Text> */}
    </View>
    <View style={styles.tTopHeader}>
      <Text>Dispatch Document</Text>
    </View>
    <View style={styles.tTopHeader}>
      <Text>Delivery Note</Text>
      {/* <Text>234234 dt. 12-May-2022</Text> */}
    </View>
  </View>
);

const TableHead = () => (
  <View style={{ width: "100%", flexDirection: "row" }}>
    <View style={[styles.tHeadId]}>
      <Text>#</Text>
    </View>
    <View style={[styles.theader, styles.theader2]}>
      <Text>Description of Goods</Text>
    </View>
    <View style={styles.theader}>
      <Text>Quantity</Text>
    </View>
    <View style={styles.theader}>
      <Text>Rate</Text>
    </View>
    <View style={styles.theader}>
      <Text>Amount</Text>
    </View>
  </View>
);

const TableBody: FC<TableBodyProps> = ({ products }) => (
  <Fragment>
    {products.map((product, id) => (
      <Fragment key={product.productId}>
        <View break={id + 1 > 24} wrap={false} style={{ width: "100%", flexDirection: "row" }}>
          <View style={[styles.tbodyId]}>
            <Text>{id + 1}</Text>
          </View>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text>{product.productName}</Text>
          </View>

          <View style={styles.tbody}>
            <Text>{`${product.quantityRequired} Qty`}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{product.quotedPrice} </Text>
          </View>
          <View style={styles.tbody}>
            <Text>
              {(Number(product.quotedPrice) * Number(product.quantityRequired)).toFixed(2)}
            </Text>
          </View>
        </View>
      </Fragment>
    ))}
  </Fragment>
);

const TableTotal: FC<TableTotalProps> = ({ total }) => (
  <View style={{ width: "100%", flexDirection: "row" }}>
    <View style={styles.totalId}>
      <Text></Text>
    </View>
    <View style={styles.total}>
      <Text></Text>
    </View>
    <View style={styles.total}>
      <Text> </Text>
    </View>
    <View style={styles.tbody}>
      <Text>Total</Text>
    </View>
    <View style={styles.tbody}>
      <Text>{`KWD ${total}`}</Text>
    </View>
  </View>
);

const AmountInText: FC<AmountInTextProps> = ({ amount }) => (
  <View style={styles.titleContainer}>
    <View style={styles.spaceBetween}>
      <View>
        <Text style={styles.addressTitle}>Total Amount (in words):</Text>
        <Text style={styles.address}>{amount}</Text>
      </View>
      <Text style={styles.address}>E & O.E</Text>
    </View>
  </View>
);

const Table: FC<TableProps> = ({ products }) => {
  const total = products.reduce(
    (sum, item) => sum + Number(item.quotedPrice) * Number(item.quantityRequired),
    0
  );
  const totalInText = convertAmountToWords(total);

  return (
    <>
      <TableTopHead />
      <TableHead />
      <TableBody products={products} />
      <TableTotal total={total} />
      <AmountInText amount={totalInText} />
    </>
  );
};

const Signature = () => (
  <View style={[styles.titleContainer, { marginTop: 50 }]}>
    <View style={[styles.spaceBetween, { alignItems: "flex-start" }]}>
      <View style={{ maxWidth: 200 }}>
        <Text style={styles.address}>Customer's Seal and Signature</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        <Text style={[styles.address, { fontWeight: "bold", marginBottom: 30 }]}>
          for Sahara National Computer Co. W.L.L
        </Text>
        <View style={[styles.spaceBetween, { gap: 15 }]}>
          <Text style={styles.address}>Prepared by</Text>
          <Text style={styles.address}>Verified by</Text>
          <Text style={styles.address}>Authorized Signatory</Text>
        </View>
      </View>
    </View>
  </View>
);

const Footer = () => {
  return <Image src={footer} />;
};

export { Title, InvoiceDetails, Address, Table, Signature, Footer };

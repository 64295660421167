import * as React from "react";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { asyncLocalStorage } from "../services/utlis";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EventIcon from "@mui/icons-material/Event";
import StoreIcon from "@mui/icons-material/Store";
import { StyledSidebar } from "../components/styles";
import Logo from "../assets/images/saharaText.png";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

type MenuChild = {
  title: string;
  link: string;
  icon: React.JSX.Element;
};

export type Module =
  | "jobCards"
  | "quote"
  | "purchaseOrder"
  | "inventory"
  | "users"
  | "customers"
  | "enquiry"
  | "reminder"
  | "profile"
  | "logout";

type MenuItems = {
  icon: React.JSX.Element;
  hasSubmenu: boolean;
  title: string;
  link: string;
  child?: MenuChild[];
  roles: string[];
  module: Module;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  background: "var(--secondary)",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const menuItems: MenuItems[] = [
  {
    hasSubmenu: false,
    title: "Job Cards",
    link: "/crm-auth/job-cards",
    icon: <WorkHistoryIcon />,
    roles: ["admin", "staff", "finance", "technician"],
    module: "jobCards",
  },
  {
    hasSubmenu: false,
    title: "Quote",
    link: "/crm-auth/quote",
    icon: <RequestQuoteIcon />,
    roles: ["admin", "finance", "technician"],
    module: "quote",
  },
  {
    hasSubmenu: false,
    title: "Purchase Orders",
    link: "/crm-auth/purchase-order",
    icon: <StoreIcon />,
    roles: ["admin", "finance"],
    module: "purchaseOrder",
  },
  {
    hasSubmenu: false,
    title: "Inventory",
    link: "/crm-auth/products",
    icon: <Inventory2Icon />,
    roles: ["admin", "finance"],
    module: "inventory",
  },
  {
    hasSubmenu: false,
    title: "Users",
    link: "/crm-auth/users",
    icon: <PeopleIcon />,
    roles: ["admin"],
    module: "users",
  },
  {
    hasSubmenu: false,
    title: "Customers",
    link: "/crm-auth/customers",
    icon: <ManageAccountsIcon />,
    roles: ["admin", "finance"],
    module: "customers",
  },
  {
    hasSubmenu: false,
    title: "Enquiry",
    link: "/crm-auth/enquiry",
    icon: <ImportContactsIcon />,
    roles: ["admin", "finance"],
    module: "enquiry",
  },
  {
    hasSubmenu: false,
    title: "Remainder",
    link: "/crm-auth/remainder",
    icon: <EventIcon />,
    roles: ["admin", "finance"],
    module: "reminder",
  },
  {
    hasSubmenu: false,
    title: "Profile",
    link: "/crm-auth/profile",
    icon: <PersonIcon />,
    roles: ["admin", "staff", "finance", "technician"],
    module: "profile",
  },
  {
    hasSubmenu: false,
    title: "Logout",
    link: "/logout",
    icon: <LogoutIcon />,
    roles: ["admin", "staff", "finance", "technician"],
    module: "logout",
  },
];

export default function MiniDrawer({ children }: React.ReactNode | any) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const isSmallScreen = useMediaQuery("(max-width: 980px)");
  const { logout, setUserDetails, user } = useAuth();

  const onLogout = React.useCallback(() => {
    logout();
    setUserDetails({});
    asyncLocalStorage.removeItem();
    navigate("/crm-auth/login");
  }, [logout, navigate, setUserDetails]);

  const navigateToLint = React.useCallback(
    (link: string) => {
      if (link === "/logout") {
        onLogout();
        return;
      }
      navigate(link);
    },
    [navigate, onLogout]
  );

  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  React.useEffect(() => {
    if (!isSmallScreen) {
      setOpen(() => true);
    } else {
      setOpen(() => false);
    }
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        open={open}
        style={{
          background: "var(--secondary)",
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerOpen}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List
          style={{
            background: "var(--secondary)",
            height: "100%",
          }}
        >
          <StyledSidebar>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              disablePadding
              sx={{ display: "block" }}
            >
              <>
                {menuItems?.map(
                  (item: MenuItems) =>
                    item?.roles?.includes(user?.role) && (
                      <ListItemButton
                        key={item?.link}
                        onClick={() => navigateToLint(item?.link)}
                        className={
                          item?.link === `/${location?.pathname.split("/")[1]}` ? "active-tab" : ""
                        }
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item?.icon}
                        </ListItemIcon>
                        {open && <ListItemText primary={item.title} />}
                      </ListItemButton>
                    )
                )}
              </>
            </List>
          </StyledSidebar>
        </List>
        {open && <img src={Logo} alt="Logo" />}
      </Drawer>
      {children}
    </Box>
  );
}

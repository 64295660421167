import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styled from "styled-components";

type Iprops = {
  items: { value: string; name: string }[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const ToggleBtn = ({ items = [], value, setValue }: Iprops) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setValue(newAlignment);
  };

  return (
    <StyledButtonGrp
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {items?.map((item) => (
        <ToggleButton value={item?.value} key={item?.value}>
          {item?.name}
        </ToggleButton>
      ))}
    </StyledButtonGrp>
  );
};

export default ToggleBtn;

const StyledButtonGrp: any = styled(ToggleButtonGroup)`
  font-family: "Nunito", sans-serif !important;
  .Mui-selected {
    background: var(--secondary) !important;
    color: #fff !important;
    font-family: "Nunito", sans-serif !important;
  }
`;

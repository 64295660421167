import React, { useMemo, useState } from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { MainWrapper, StyledHeader } from "../../components/styles";
import { Wrapper } from "./components/styles";
import Button from "../../components/Common/Button";
import EditQuotation from "./forms/EditQuotation.form";
import { approveQuotation } from "../../services/quotation.services";
import QuoteAddress from "./components/QuoteAddress";
import { Customer } from "../Customers/types";
import QuoteProduct from "./components/QuoteProduct";
import QuoteInputs from "./components/QuoteInputs";
import Invoice from "../../components/Invoice";

const EditQuote = () => {
  const formik = EditQuotation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const customerDetails: Customer = useMemo(() => {
    const customerObj = {
      deliveryAddress: "",
      email: "",
      name: "",
    };
    return formik.values.quotedCustomer || customerObj;
  }, [formik.values.quotedCustomer]);

  const handleAddElement = () => {
    formik.setFieldValue("products", [
      ...formik.values.products,
      {
        productId: "",
        quotedPrice: "",
        quantityRequired: "",
      },
    ]);
  };

  const handleRemoveProduct = (index: number) => {
    const updatedPersons = [...formik.values.products];
    updatedPersons.splice(index, 1);
    formik.setFieldValue("products", updatedPersons);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("isEmailSent", event.target.checked);
  };

  const onApproveQuotation = async () => {
    await approveQuotation(formik.values.id).then(() => {
      toast.success("Quote Approved successfully");
      navigate("/crm-auth/purchase-order");
    });
  };

  const DownloadPDF = ({ title = "invoice" }: { title: string }) => (
    <PDFDownloadLink
      document={<Invoice values={formik.values} />}
      fileName={`${title}.pdf`}
    >
      <IconButton title="Download" color="primary">
        <DownloadIcon />
      </IconButton>
    </PDFDownloadLink>
  );

  const PreviewPDF = () => (
    <BlobProvider document={<Invoice values={formik.values} />}>
      {({ url }) => (
        <a href={`${url}`} target="_blank">
          <IconButton title="Preview" color="primary">
            <PreviewIcon />
          </IconButton>
        </a>
      )}
    </BlobProvider>
  );

  return (
    <MainWrapper>
      <Wrapper>
        <Grid container justifyContent="space-between" mb={2} gap={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader style={{ margin: "8px 0px !important" }}>
              Edit Quote
            </StyledHeader>
          </Grid>
          {!formik.values.isApproved && (
            <Grid xs={12} sm={3} lg={2} item justifyContent="flex-end">
              <Button
                buttontypes="success"
                onClick={() => onApproveQuotation()}
              >
                Approve Quote
              </Button>
            </Grid>
          )}
        </Grid>
        <QuoteInputs formik={formik} />
        <hr />
        <QuoteAddress
          formik={formik}
          onClose={onClose}
          customerDetails={customerDetails}
          open={open}
          setOpen={setOpen}
          showSelectBtn={false}
        />
        <hr />
        <QuoteProduct
          formik={formik}
          handleAddElement={handleAddElement}
          handleRemoveProduct={handleRemoveProduct}
        />
        <hr />
        <Grid
          spacing={2}
          container
          display="flex"
          justifyContent="space-between"
        >
          <Grid xs={12} sm={6} lg={6} item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={formik?.values.isEmailSent}
                />
              }
              label="Send Quote via e-mail."
            />
          </Grid>
          <Stack
            direction="row"
            display="flex"
            marginTop={2}
            gap={{
              md: 4,
            }}
            justifyContent="space-between"
            width={{
              xs: "100%",
              sm: "auto",
            }}
          >
            <DownloadPDF title="invoice"/>
            <PreviewPDF />
            <Button onClick={formik?.handleSubmit} disabled={formik.values.isApproved}>
              Update
            </Button>
          </Stack>
          {/* <Grid xs={12} sm={4} lg={3} item container>
            <Grid xs={12} sm={6} md={6} lg={4} item>
              <DownloadPDF title={formik?.values?.name} />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={4} item>
              <PreviewPDF />
            </Grid>
            <Grid xs={12} sm={4} md={6} lg={4} item>
              <Button
                onClick={formik?.handleSubmit}
                disabled={formik.values.isApproved}
              >
                Update
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
      </Wrapper>
    </MainWrapper>
  );
};

export default EditQuote;

import { useFormik } from "formik";
import { useCallback } from "react";
import * as Yup from "yup";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";
import { signin } from "../../services/public.services";
import { asyncLocalStorage } from "../../services/utlis";
import { getUserProfile } from "../../services/private.services";

const useSigninHook = () => {
  const { login, setUserDetails } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values) => {
      const { data } = await signin(values);
      asyncLocalStorage.setItem(data.accessToken)
      const userDetails = await getUserProfile();
      setUserDetails(userDetails);
      login(true);
      navigate("/crm-auth/dashboard");
    },
    [navigate, login, setUserDetails]
  );
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: handleSubmit,
  });
  return {
    formik,
  };
};

export default useSigninHook;

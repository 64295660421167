import React from "react";
import { BadgeContainer } from "./BadgeStyled";

const Badge = () => {
  const getYear = () => {
    let date = new Date();
    return date.getFullYear() - 2000;
  };
  return (
    <BadgeContainer>
      <ul className="badgeBox">
        <li className="free">{getYear()}</li>
        <li className="start">
          <span>Years</span>
        </li>
      </ul>
    </BadgeContainer>
  );
};

export default Badge;

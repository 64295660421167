import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

type IProps = {
  children: React.ReactNode;
  open: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

export default function TemporaryDrawer({
  children,
  open,
  toggleDrawer,
}: IProps) {
  return (
    <Drawer
      style={{ marginTop: 60 }}
      open={open}
      anchor="right"
      onClose={toggleDrawer(false)}
    >
      <Box sx={{ width: 600 }} role="presentation">
        {children}
      </Box>
    </Drawer>
  );
}

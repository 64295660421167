import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../auth";
import Signin from "./Signin";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";

const PublicRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const ProtectedRoutes: any = ({ children }: React.ReactNode | any) => {
    return isAuthenticated ? <Navigate to="/crm-auth/dashboard" replace /> : children;
  };

  return (
    <Routes>
      <Route
        path="/crm-auth/login"
        element={
          <ProtectedRoutes>
            <Signin />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/crm-auth/reset-password"
        element={
          <ProtectedRoutes>
            <ResetPassword />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/crm-auth/forgot-password"
        element={
          <ProtectedRoutes>
            <ForgotPassword />
          </ProtectedRoutes>
        }
      />
    </Routes>
  );
};

export default PublicRoutes;

import { useFormik } from "formik";
import { toast } from "react-toastify";
import { inventoryValidations, productValidations } from "../validations";
import { inventoryTypes } from "../../../utils/jobCards";
import { useMemo } from "react";
import {
  createBrand,
  createCategory,
  createProduct,
  updateBrandDetails,
  updateCategoryDetails,
  updateProductDetails,
} from "../../../services/inventory.services";
import { fileUploadService } from "../../../services/private.services";

const CreateInventory = (type: string, handleClose: () => void) => {
  return useFormik({
    initialValues: {
      name: "",
      description: "",
      isActive: false,
    },
    validationSchema: inventoryValidations,
    onSubmit: async (values) => {
      try {
        if (type === inventoryTypes.BRAND) {
          await createBrand(values);
          toast.success("New brand added");
          return handleClose();
        } else if (type === inventoryTypes.CATEGORY) {
          await createCategory(values);
          toast.success("New category added");
          return handleClose();
        }
      } catch (error) {
        // Handler error
      }
    },
  });
};

export default CreateInventory;

export const UpdateInventory = (
  type: string,
  handleClose: () => void,
  inventory: any,
  getList: () => void
) => {
  const initialValues = useMemo(() => {
    return {
      name: inventory?.name ? inventory?.name : "",
      description: inventory?.description || "",
      isActive: inventory?.isActive,
    };
  }, [inventory]);

  return useFormik({
    initialValues: initialValues,
    validationSchema: inventoryValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (type === inventoryTypes.BRAND) {
          await updateBrandDetails(inventory?.id, values);
          toast.success("Brand updated successfully.");
          getList();
          return handleClose();
        } else if (type === inventoryTypes.CATEGORY) {
          await updateCategoryDetails(inventory?.id, values);
          toast.success("Category updated successfully");
          getList();
          return handleClose();
        }
      } catch (error) {
        // Handler error
      }
    },
  });
};

//
export const CreateProductForm = (type: string, handleClose: () => void) => {
  return useFormik({
    initialValues: {
      name: "",
      description: "",
      isActive: false,
      modelNo: "",
      serialNo: "",
      categoryId: "",
      brandId: "",
      file: null,
      imageKey: "",
      thumbnailUrl: "",
    },
    validationSchema: productValidations,
    onSubmit: async (values) => {
      try {
        let valueObj = {
          ...values,
        };
        let result = null;
        if (values?.file) {
          result = await fileUploadService(values?.file);
        }
        if (
          result?.uploadDetails?.uploadedImage?.key &&
          result?.uploadDetails?.uploadedThumbnail?.Location
        ) {
          valueObj = {
            ...valueObj,
            imageKey: result?.uploadDetails?.uploadedImage?.key,
            thumbnailUrl: result?.uploadDetails?.uploadedThumbnail?.Location,
          };
        }
        await createProduct(valueObj);
        toast.success("New Product added");
        return handleClose();
      } catch (error) {
        // Handler error
      }
    },
  });
};

export const UpdateProduct = (
  type: string,
  handleClose: () => void,
  inventory: any,
  getList: () => void
) => {
  const initialValues = useMemo(() => {
    return {
      name: inventory?.name ? inventory?.name : "",
      description: inventory?.description || "",
      isActive: inventory?.isActive,
      modelNo: inventory?.modelNo || "",
      serialNo: inventory?.serialNo || "",
      categoryId: inventory?.productCategory?.id || "",
      brandId: inventory?.productMappedBrand?.id || "",
      defaultCategory:
        {
          label: inventory?.productCategory?.name,
          value: inventory?.productCategory?.id,
        } || {},
      defaultBrand:
        {
          label: inventory?.productMappedBrand?.name,
          value: inventory?.productMappedBrand?.id,
        } || {},
      imageUrl: inventory?.thumbnailUrl || "",
      file: null,
      imageKey: "",
      thumbnailUrl: "",
    };
  }, [inventory]);

  return useFormik({
    initialValues: initialValues,
    validationSchema: productValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let valueObj = {
          ...values,
        };
        let result = null;
        if (values?.file) {
          result = await fileUploadService(values?.file);
        }
        if (
          result?.uploadDetails?.uploadedImage?.key &&
          result?.uploadDetails?.uploadedThumbnail?.Location
        ) {
          valueObj = {
            ...valueObj,
            imageKey: result?.uploadDetails?.uploadedImage?.key,
            thumbnailUrl: result?.uploadDetails?.uploadedThumbnail?.Location,
          };
        }
        const finalObj = {
          name: valueObj.name,
          description: valueObj.description,
          isActive: valueObj.isActive,
          modelNo: valueObj.modelNo,
          serialNo: valueObj.serialNo,
          categoryId: valueObj.categoryId,
          brandId: valueObj.brandId,
          imageKey: valueObj.imageKey,
          thumbnailUrl: valueObj.thumbnailUrl,
        }
        await updateProductDetails(inventory?.id, finalObj);
        toast.success("Product updated successfully.");
        getList();
        return handleClose();
      } catch (error) {
        // Handler error
      }
    },
  });
};

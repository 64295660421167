import * as Yup from "yup";
import {
  name,
  description,
  brandId,
  categoryId,
  serialNo,
  modelNo,
} from "../../utils/validations";

export const inventoryValidations = Yup.object({
  name,
  description,
});

export const productValidations = Yup.object({
  name,
  description,
  brandId,
  categoryId,
  serialNo,
  modelNo,
});

import React, {  useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../auth";
import Quote from "../containers/Quote";
import Layout from "../Layout";
import Profile from "../containers/Profile";
import Inventory from "../containers/Inventory";
import UserList from "../containers/Users/UserList";
import JobCards from "../containers/JobCards";
import CreateQuote from "../containers/Quote/CreateQuote";
import Dashboard from "./Dashboard";
import Signin from "./Signin";
import CustomerList from "../containers/Customers/CustomerList";
import EditQuote from "../containers/Quote/EditQuote";
import PurchaseOrders from "../containers/PurchaseOrders";
import OrderDetail from "../containers/PurchaseOrders/OrderDetail";
import EnquiryDetail from "../containers/Enquiry";
import Remainder from "../containers/Remainder";
import NotFound from "../NotFound";
import ProtectedRoute from "../utils/ProtectedRoute";

const PrivateRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const AuthenticatedRoutes: React.FC = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    return isAuthenticated ? <Layout /> : <Signin />;
  };

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeout);
    };
  });

  return (
    <Routes>
      <Route path="crm-auth" element={<AuthenticatedRoutes />}>
        <Route path="*" element={<NotFound />} />
        <Route path="" element={<Dashboard />} />
        <Route
          path="profile"
          element={
            <ProtectedRoute module="profile">
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="products" element={<Inventory />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route
          path="users"
          element={
            <ProtectedRoute module="users">
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="customers"
          element={
            <ProtectedRoute module="customers">
              <CustomerList />
            </ProtectedRoute>
          }
        />
        <Route
          path="job-cards"
          element={
            <ProtectedRoute module="jobCards">
              <JobCards />
            </ProtectedRoute>
          }
        />
        <Route
          path="quote"
          element={
            <ProtectedRoute module="quote">
              <Quote />
            </ProtectedRoute>
          }
        />
        <Route
          path="quote/create"
          element={
            <ProtectedRoute module="quote">
              <CreateQuote />
            </ProtectedRoute>
          }
        />
        <Route
          path="quote/:quoteId"
          element={
            <ProtectedRoute module="quote">
              <EditQuote />
            </ProtectedRoute>
          }
        />
        <Route
          path="purchase-order"
          element={
            <ProtectedRoute module="purchaseOrder">
              <PurchaseOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="purchase-order/:quoteId"
          element={
            <ProtectedRoute module="purchaseOrder">
              <OrderDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="enquiry"
          element={
            <ProtectedRoute module="enquiry">
              <EnquiryDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="remainder"
          element={
            <ProtectedRoute module="reminder">
              <Remainder />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default PrivateRoute;

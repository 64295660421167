import * as Yup from "yup";
import {
  email,
  phoneNumber,
  name,
  address,
  description,
} from "../../../../utils/validations";

export const contactUsValidations = Yup.object({
  email,
  phoneNumber,
  name,
  address,
  description,
});

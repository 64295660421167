import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Symantec from "../../../../assets/images/Logos/symantec.jpg";
import DellLogo from "../../../../assets/images/Logos/dell-2.svg";
import Dlink from "../../../../assets/images/Logos/dlink.png";
import HP from "../../../../assets/images/Logos/hp-1 (1).svg";
import Adobe from "../../../../assets/images/Logos/Adobe_Corporate_Logo.png";
import MicroSoft from "../../../../assets/images/Logos/microsoft.svg";
import APC from "../../../../assets/images/Logos/apc.png";
import Intel from "../../../../assets/images/Logos/intel.svg";
import Toshiba from "../../../../assets/images/Logos/toshiba.png";
import Fujitu from "../../../../assets/images/Logos/fujitsu-siemens-thumbnail.png";
import Compaq from "../../../../assets/images/Logos/compaq-16.svg";
import Cisco from "../../../../assets/images/Logos/cisco-2.svg";
import AMD from "../../../../assets/images/Logos/amd-logo-1.svg";
import Acer from "../../../../assets/images/Logos/acer.png";
import { LogoSliderContainer } from "./LogoSliderStyled";

const LogoSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
        },
      },

    ],
  };
  return (
    <LogoSliderContainer id="ourCustomer">
      <div className="headingWrapper">
        <h2>Business Partners</h2>
      </div>
      <div className="sliderCls">
        <Slider {...settings}>
          <div>
            <img
              src={Dlink}
              alt="Logo 3"
              style={{
                width: "100%",
                maxHeight: "65px",
                marginLeft: "100px",
                marginRight: "40px",
                objectFit: "contain",
              }}
            />
          </div>

          <div>
            <img
              src={DellLogo}
              alt="Logo 2"
              style={{
                width: "100%",
                maxHeight: "80px",
                objectFit: "contain",
                marginLeft: "60px",
              }}
            />
          </div>
          <div>
            <img
              src={Adobe}
              alt="Logo 2"
              style={{ width: "100%", maxHeight: "50px", marginLeft: "60px" }}
            />
          </div>
          <div>
            <img
              src={HP}
              alt="Logo 1"
              style={{ width: "100%", maxHeight: "70px", marginLeft: "60px" }}
            />
          </div>
          <div>
            <img
              src={MicroSoft}
              alt="Logo 3"
              style={{ width: "100%", maxHeight: "50px", marginLeft: "30px" }}
            />
          </div>
          <div>
            <img
              src={Intel}
              alt="Logo 3"
              style={{ width: "100%", maxHeight: "70px", marginLeft: "30px" }}
            />
          </div>
          <div>
            <img
              src={Toshiba}
              alt="Logo 3"
              style={{ width: "100%", maxHeight: "50px", marginLeft: "30px" }}
            />
          </div>

          <div>
            <img
              src={Fujitu}
              alt="Logo 3"
              style={{
                width: "100%",
                maxHeight: "80px",
                objectFit: "cover",
                marginLeft: "60px",
              }}
            />
          </div>
          <div>
            <img
              src={Symantec}
              alt="Logo 1"
              style={{ width: "100%", maxHeight: "50px", marginLeft: "60px" }}
            />
          </div>
          <div>
            <img
              src={Compaq}
              alt="Logo 3"
              style={{ width: "100%", maxHeight: "50px", marginLeft: "60px" }}
            />
          </div>
          <div>
            <img
              src={Cisco}
              alt="Logo 3"
              style={{ width: "100%", maxHeight: "70px", marginLeft: "60px" }}
            />
          </div>
          <div>
            <img
              src={AMD}
              alt="Logo 3"
              style={{ width: "100%", maxHeight: "50px", marginLeft: "60px" }}
            />
          </div>
          <div>
            <img
              src={Acer}
              alt="Logo 3"
              style={{
                width: "100%",
                maxHeight: "80px",
                marginLeft: "60px",
                marginRight: "50px",
              }}
            />
          </div>

          <div>
            <img
              src={APC}
              alt="Logo 3"
              style={{
                width: "100%",
                maxHeight: "50px",
                marginLeft: "60px",
                objectFit: "contain",
                marginRight: "40px",
              }}
            />
          </div>
        </Slider>
      </div>
    </LogoSliderContainer>
  );
};

export default LogoSlider;

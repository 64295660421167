import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { ThreeDots } from "react-loader-spinner";
import dayjs from "dayjs";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowHeader,
} from "../../../components/Common/Table";
import Pagination from "../../../components/Common/Table/Components/Pagination";
import { Chip, Tooltip } from "@mui/material";
import ModeIcon from "@mui/icons-material/Mode";
import { transform } from "lodash";

type IProps = {
  count?: number;
  rowsPerpage?: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onRowsPerPageChange?: any;
  page: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  data: any[];
  handleChangeInventoryId: (id: string) => void;
};

const ProductTableList = ({
  count = 0,
  rowsPerpage = 10,
  page = 1,
  onPageChange,
  handleChangeRowsPerPage,
  loading,
  data = [],
  handleChangeInventoryId,
}: IProps) => {
  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRowHeader>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Model No.</StyledTableCell>
            <StyledTableCell>Serial No.</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Brand</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Created Date</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </StyledTableRowHeader>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data?.map((row: any, idx: any) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell key={row.name + idx}>
                  {row.name}
                </StyledTableCell>
                <StyledTableCell key={row.modelNo + idx}>
                  {row.modelNo}
                </StyledTableCell>
                <StyledTableCell key={row.serialNo + idx}>
                  {row.serialNo}
                </StyledTableCell>
                <StyledTableCell key={row.description + idx} className="w-25">
                  <div className="description-wrapper">
                    <Tooltip title={row?.description}>
                      {row.description}
                    </Tooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell key={row.productMappedBrand + idx}>
                  {row.productMappedBrand?.name}
                </StyledTableCell>
                <StyledTableCell key={row.productCategory + idx}>
                  {row.productCategory?.name}
                </StyledTableCell>
                <StyledTableCell key={row?.isActive + idx} >
                  {row?.isActive ? (
                    <Chip label="Active" color="primary" className="table_btn"/>
                  ) : (
                    <Chip label="Inactive" color="error" className="table_btn"/>
                  )}
                </StyledTableCell>
                <StyledTableCell key={row?.createdAt + idx}>
                  {dayjs(row?.createdAt).format("YYYY-MM-DD")}
                </StyledTableCell>
                <StyledTableCell key={row?.id + idx}>
                  <ModeIcon
                    onClick={() => handleChangeInventoryId(row)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#1B2850" height={50} width={50} />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data Found
        </div>
      )}
      <Pagination
        count={count}
        rowsPerpage={rowsPerpage}
        onPageChange={onPageChange}
        page={page}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ProductTableList;

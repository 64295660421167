import {  useMemo, useState } from "react";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MainWrapper, StyledHeader } from "../../components/styles";
import Button from "../../components/Common/Button";
import { Customer } from "../Customers/types";
import QuoteAddress from "../Quote/components/QuoteAddress";
import QuoteProduct from "../Quote/components/QuoteProduct";
import QuoteInputs from "../Quote/components/QuoteInputs";
import EditQuotation from "../Quote/forms/EditQuotation.form";
import { Wrapper } from "../Quote/components/styles";
import IModal from "../../components/Common/Modal/IModal";
import DeliveryNotes from "./components/DeliveryNotes";

const OrderDetail = () => {
  const formik = EditQuotation();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };
  const onModalOpen = () => {
    setOpenModal(true);
  };

  const customerDetails: Customer = useMemo(() => {
    const customerObj = {
      deliveryAddress: "",
      email: "",
      name: "",
    };
    return formik.values.quotedCustomer || customerObj;
  }, [formik.values.quotedCustomer]);

  const handleAddElement = () => {
    formik.setFieldValue("products", [
      ...formik.values.products,
      {
        productId: "",
        quotedPrice: "",
        quantityRequired: "",
      },
    ]);
  };

  const handleRemoveProduct = (index: number) => {
    const updatedPersons = [...formik.values.products];
    updatedPersons.splice(index, 1);
    formik.setFieldValue("products", updatedPersons);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("isEmailSent", event.target.checked);
  };

  return (
    <MainWrapper>
      <Wrapper>
        <Grid container justifyContent="space-between" mb={2} gap={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader style={{ margin: "8px 0px !important" }}>
              Quote Details
            </StyledHeader>
          </Grid>
          {formik.values.isApproved && (
            <Grid xs={12} sm={3} lg={2.5} item justifyContent="flex-end">
              <Button buttontypes="success" onClick={() => onModalOpen()}>
                Generate Delivery Notes
              </Button>
            </Grid>
          )}
        </Grid>
        <QuoteInputs formik={formik} isDisabled={true} />
        <hr />
        <QuoteAddress
          formik={formik}
          onClose={onClose}
          customerDetails={customerDetails}
          open={open}
          setOpen={setOpen}
          showSelectBtn={false}
        />
        <hr />
        <QuoteProduct
          formik={formik}
          handleAddElement={handleAddElement}
          handleRemoveProduct={handleRemoveProduct}
          isDisabled={true}
        />
        <hr />
        <Grid
          spacing={2}
          container
          display="flex"
          justifyContent="space-between"
        >
          <Grid xs={12} sm={6} lg={6} item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={formik?.values.isEmailSent}
                />
              }
              label="Send Quote via e-mail."
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={6}
            lg={2}
            item
            alignItems="flex-end"
            display="flex"
            justifyContent="flex-end"
          >
            <Button buttontypes="secondary">Preview</Button>
          </Grid>
        </Grid>
        <IModal open={openModal} handleClose={onModalClose} width={60}>
          <DeliveryNotes />
        </IModal>
      </Wrapper>
    </MainWrapper>
  );
};

export default OrderDetail;

import { createSlice } from "@reduxjs/toolkit";
import { getDeliveryNotesDetails } from "../../services/quotation.services";
import {
  DeliveryNotes,
  generateDeliveryNotesDetails,
} from "../../utils/purchaseOrder";
export interface PurchaseOrderState {
  purchaseOrder: any;
  isPurchaseOrderLoaded: boolean;
  deliveryNotes: DeliveryNotes | null | any;
  isDeliveryNotesLoaded: boolean;
}

const initialState: PurchaseOrderState = {
  purchaseOrder: null,
  isPurchaseOrderLoaded: false,
  deliveryNotes: null,
  isDeliveryNotesLoaded: false,
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    setPurchaseOrders: (state, action: any) => {
      state.isPurchaseOrderLoaded = true;
      state.purchaseOrder = action.payload;
      state.deliveryNotes = generateDeliveryNotesDetails(
        action.payload.deliveryNotes
      );
    },
    removePurchaseOrder: (state) => {
      state.isPurchaseOrderLoaded = false;
      state.purchaseOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryNotesDetails.pending, (state) => {
        state.isDeliveryNotesLoaded = true;
      })
      .addCase(getDeliveryNotesDetails.fulfilled, (state, action) => {
        state.isDeliveryNotesLoaded = false;
        state.deliveryNotes = generateDeliveryNotesDetails(action.payload);
      })
      .addCase(getDeliveryNotesDetails.rejected, (state, action) => {
        state.isDeliveryNotesLoaded = false;
      });
  },
});

export const { setPurchaseOrders, removePurchaseOrder } =
  purchaseOrderSlice.actions;
export default purchaseOrderSlice.reducer;

import * as Yup from "yup";

export const firstName = Yup.string()
  .max(255)
  .trim()
  .required("First name is required");
export const lastName = Yup.string()
  .max(255)
  .trim()
  .required("Last name is required");
export const middleName = Yup.string()
  .max(255)
  .trim()
  .required("Middle name is required");
export const password = Yup.string().min(8).required("Password is required");
export const phoneNumber = Yup.string()
  .max(255)
  .trim()
  .required("Phone Number is required");
export const gender = Yup.string()
  .max(255)
  .trim()
  .required("Gender is required");
export const houseNumber = Yup.string()
  .max(255)
  .trim()
  .required("House Number is required");
export const streetName = Yup.string()
  .max(255)
  .trim()
  .required("Street name is required");
export const country = Yup.string()
  .max(255)
  .trim()
  .required("Country is required");
export const state = Yup.string().max(255).trim().required("State is required");
export const postalCode = Yup.string()
  .max(255)
  .trim()
  .required("Postal code is required");
export const email = Yup.string()
  .trim()
  .email("Inavlid email")
  .max(255)
  .required("Email is required");
export const role = Yup.string().required("Role is required");
export const reportedProblem = Yup.string()
  .max(255)
  .trim()
  .required("Problem is required");
export const precondition = Yup.string()
  .max(255)
  .trim()
  .required("Pre Condition is required");
export const accessories = Yup.string()
  .max(255)
  .trim()
  .required("Accessories is required");
export const type = Yup.string()
  .max(255)
  .trim()
  .required("Job Type is required");
export const serviceType = Yup.string()
  .max(255)
  .trim()
  .required("Service type is required");
export const jobTitle = Yup.string()
  .max(255)
  .trim()
  .required("Title type is required");
export const name = Yup.string().max(255).trim().required("Name is required");
export const description = Yup.string()
  .max(255)
  .trim()
  .required("Description is required");
export const modelNo = Yup.string()
  .max(255)
  .trim()
  .required("Modal number is required");
export const serialNo = Yup.string()
  .max(255)
  .trim()
  .required("Serial number is required");
export const categoryId = Yup.string()
  .max(255)
  .trim()
  .required("Category is required");
export const brandId = Yup.string()
  .max(255)
  .trim()
  .required("Brand is required");
export const deliveryAddress = Yup.string()
  .max(255)
  .trim()
  .required("Brand is required");
export const address = Yup.string()
  .max(255)
  .trim()
  .required("Address is required");
export const dueDate = Yup.string()
  .max(255)
  .trim()
  .required("Due Date is required");
export const title = Yup.string()
  .max(255)
  .trim()
  .required("Title type is required");
export const startDate = Yup.string()
  .max(255)
  .trim()
  .required("Start Date is required");
export const endDate = Yup.string()
  .max(255)
  .trim()
  .required("End Date is required");

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { ThreeDots } from "react-loader-spinner";
import { getFormattedDate } from "../../utils/utils";

import Pagination from "../../components/Common/Table/Components/Pagination";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowHeader,
} from "../../components/Common/Table";
import { Tooltip } from "@mui/material";
type IProps = {
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleOnChangeSearch: React.ChangeEventHandler<HTMLInputElement>;
  loading: boolean;
  list: any;
  totalCount: number;
  pagination: {
    page: number;
    perPage: number;
    search: string;
  };
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ListContacts = ({
  loading,
  list,
  totalCount,
  pagination,
  handleChangeRowsPerPage,
  handleChangePage,
  handleOnChangeSearch,
}: IProps) => {
  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRowHeader>
            <StyledTableCell>
              <div className="title-wrapper">
                <span>Name</span>{" "}
              </div>
            </StyledTableCell>{" "}
            <StyledTableCell>
              <div className="title-wrapper">
                <span>Date</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>
              <div className="title-wrapper">
                <span>Phone</span>{" "}
              </div>
            </StyledTableCell>
            <StyledTableCell className="w-450">Description</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
          </StyledTableRowHeader>
        </TableHead>
        <TableBody>
          {!loading &&
            list &&
            list?.map((row: any, idx: any) => (
              <StyledTableRow
                key={row.id}
                style={{
                  cursor: "pointer",
                }}
              >
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>
                  {getFormattedDate(row?.createdAt)}
                </StyledTableCell>
                <StyledTableCell>{row?.email}</StyledTableCell>
                <StyledTableCell>{row?.phoneNumber}</StyledTableCell>
                <StyledTableCell className="w-450">
                <div className="description-wrapper">
                    <Tooltip title={row?.description}>
                      {row.description}
                    </Tooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{row?.address}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#1B2850" height={50} width={50} />
        </div>
      )}
      {!loading && list?.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data Found
        </div>
      )}
      <Pagination
        count={totalCount}
        rowsPerpage={pagination?.perPage}
        onPageChange={handleChangePage}
        page={pagination?.page - 1}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ListContacts;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { getQuotationDetails, updateQuotation } from "../../../services/quotation.services";
import { Customer } from "../../Customers/types";
import { toast } from "react-toastify";
import { setPurchaseOrders } from "../../../store/purchaseOrder/purchaseOrder.slice";

export interface Product {
  productId: string;
  quotedPrice: string;
  quantityRequired: string;
  productName: string;
}

export interface FormValues {
  products: Product[];
  name: string;
  customerId: string;
  userRefNo: string;
  description: string;
  userId: string;
  createdAt: string;
  isEmailSent: boolean;
  isApproved: boolean;
  quotationProductDetails?: Product[];
  id: string;
  quotedCustomer?: Customer;
}

const EditQuotation = () => {
  let { quoteId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quoteDetails, setQuoteDetails] = useState<FormValues | null>({
    products: [
      {
        productId: "",
        quotedPrice: "",
        quantityRequired: "",
        productName: "",
      },
    ],
    customerId: "",
    name: "",
    userRefNo: "",
    description: "",
    userId: "",
    isEmailSent: false,
    isApproved: false,
    createdAt: "",
    quotationProductDetails: [],
    id: "",
    quotedCustomer: {
      address: "",
      deliveryAddress: "",
      email: "",
      name: "",
      phoneNumber: "",
      id: "",
    },
  });

  const getQuoteDetails = useCallback(
    async (id: string) => {
      const result = await getQuotationDetails(id);
      dispatch(setPurchaseOrders(result));
      setQuoteDetails(result);
    },
    [dispatch]
  );

  const productDetails = useMemo(() => {
    let product: Product[] = [];
    if (quoteDetails?.quotationProductDetails) {
      product = quoteDetails?.quotationProductDetails.map((quoteDetail: any) => {
        let quoteObj = {
          productId: quoteDetail.productId,
          quotedPrice: quoteDetail.quotedPrice,
          quantityRequired: quoteDetail.quantityRequired,
          productName: quoteDetail.productDetails.name,
        };
        return quoteObj;
      });
    }
    return product;
  }, [quoteDetails]);

  useEffect(() => {
    if (quoteId) {
      getQuoteDetails(quoteId);
    }
  }, [quoteId, getQuoteDetails]);

  return useFormik<FormValues>({
    initialValues: {
      products: productDetails || quoteDetails?.quotationProductDetails || [],
      customerId: quoteDetails?.customerId || "",
      name: quoteDetails?.name || "",
      userRefNo: quoteDetails?.userRefNo || "",
      description: quoteDetails?.description || "",
      userId: quoteDetails?.userId || "",
      isEmailSent: quoteDetails?.isEmailSent || false,
      isApproved: quoteDetails?.isApproved || false,
      id: quoteDetails?.id || "",
      createdAt: quoteDetails?.createdAt || "",
      quotedCustomer: quoteDetails?.quotedCustomer,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      products: Yup.array()
        .of(
          Yup.object().shape({
            productId: Yup.string().required("Required"),
            quotedPrice: Yup.number().required("Required"),
            quantityRequired: Yup.number().required("Required"),
          })
        )
        .required("Product is Required"),
      name: Yup.string().max(255).trim().required("Name is required"),
      userRefNo: Yup.string().max(255).trim().required("Reference Number is required"),
    }),
    onSubmit: async (values) => {
      console.log(values, "the values");
      const dataObj = {
        products: values?.products,
        customerId: values?.customerId,
        name: values?.name,
        userRefNo: values?.userRefNo,
        description: values?.description,
        userId: values?.userId,
        isEmailSent: values?.isEmailSent,
        isApproved: values?.isApproved,
      };
      await updateQuotation(values.id, dataObj).then(() => {
        toast.success("Quote updated successfully");
        navigate("/crm-auth/quote");
      });
    },
  });
};

export default EditQuotation;

import { useState, useEffect, useCallback } from "react";
import { deleteJobCard, fetchJobList } from "../../../services/private.services";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { useAuth } from "../../../auth";

const useJobList = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [jobcardId, setJobcardId] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    search: "",
    field: "",
    sort: false,
    status: "",
    serviceType: "",
    type: "",
    userId: user?.role !== "admin" ? user?.id : "",
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      perPage: +event.target.value,
    }));
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: page + 1,
      }));
    },
    []
  );

  const handleFieldSort = useCallback((field: string) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      sort: !prevPagination.sort,
      field: field,
    }));
  }, []);

  const handleFilter = useCallback((name: string, value: string) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      [name]: value,
    }));
  }, []);

  // eslint-disable-next-line
  const handleOnChangeSearch = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        search: event.target.value,
      }));
    }, 500),
    []
  );

  const handleShowFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getList = useCallback(
    async (loader = true) => {
      if (loader) {
        setLoading(true);
      }
      try {
        const result = await fetchJobList(pagination);
        setList(result?.data);
        setTotalCount(result?.totalCount);
        if (loader) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [pagination]
  );

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const onSetJobCardId = (id: string) => {
    setJobcardId(id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onHandleDelete = useCallback(async () => {
    try {
      await deleteJobCard(jobcardId);
      getList();
      closeDeleteModal();
      toast.success("Job Card deleted", { autoClose: 1500 });
    } catch (error) {
      console.log(error);
    }
  }, [jobcardId, getList]);

  useEffect(() => {
    getList();
  }, [getList, pagination]);

  return {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    getList,
    handleOpen,
    handleClose,
    open,
    showFilter,
    handleShowFilter,
    handleFieldSort,
    handleFilter,
    closeDeleteModal,
    showDeleteModal,
    openDeleteModal,
    onHandleDelete,
    onSetJobCardId,
  };
};

export default useJobList;

import React from "react";
import { Grid } from "@mui/material";
import InputField from "../../components/Common/Input";
import Button from "../../components/Common/Button";
import Avatar from "../../components/Common/Avatar";
import { useAuth } from "../../auth";
import { getFullName } from "../../utils/utils";

const Profile = () => {
  const { user } = useAuth();
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          {/* <div className="page-title">
            <h4>Profile</h4>
            <h6>User Profile</h6>
          </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <Avatar />
                  <div className="profile-contentname">
                    <h2>{getFullName(user)}</h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
                <div className="ms-auto">
                  <Grid rowSpacing={1} display="flex">
                    <Grid
                      xs={12}
                      sm={6}
                      lg={6}
                      item
                      className="m-2"
                      width={100}
                    >
                      <Button>Save</Button>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      lg={12}
                      item
                      className="m-2"
                      width={100}
                    >
                      <Button buttontypes="secondary">Cancel</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <Grid spacing={2} container>
              <Grid xs={12} sm={6} lg={6} item>
                <label>First Name</label>
                <InputField placeholder="First Name" value={user?.firstName} />
              </Grid>
              <Grid xs={12} sm={6} lg={6} item>
                <label>Last Name</label>
                <InputField placeholder="Last Name" value={user?.lastName} />
              </Grid>
              <Grid xs={12} sm={6} lg={6} item>
                <label>Email</label>
                <InputField placeholder="email" value={user?.email} disabled/>
              </Grid>
              <Grid xs={12} sm={6} lg={6} item>
                <label>Phone</label>
                <InputField
                  placeholder="Phone number"
                  value={user?.phoneNumber}
                />
              </Grid>
              <Grid xs={12} sm={6} lg={6} item>
                <label>User Name</label>
                <InputField placeholder="Username " />
              </Grid>
              <Grid xs={12} sm={6} lg={6} item>
                <label>Password</label>
                <InputField type="password" placeholder="Password" />
              </Grid>
              <Grid xs={6} sm={6} lg={2} item>
                <Button>Submit</Button>
              </Grid>
              <Grid xs={6} sm={6} lg={2} item>
                <Button buttontypes="secondary">Cancel</Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

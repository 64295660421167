import { styled } from "styled-components";

export const WhatWeDoSectionStyled = styled.section`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 740px;
  .whatWeDoSectionContainer {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px 0px;
    .whatweDoWrapper {
      display: flex;
      gap: 80px;
    }
    h2 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      margin: 0;
      padding: 0;
    }
  }
  @media (max-width: 959px) {
    height: 100%;
    .whatWeDoSectionContainer{
      width: 100%;
      .whatweDoWrapper{
        flex-direction: column-reverse;
      }
    }
  }
`;

export const getName = (name: string) => {
  return name?.charAt(0)?.toUpperCase() + name?.slice(1);
};

export const getFormattedDate = (date: string) => {
  const newDate = new Date(date);
  const options: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return newDate.toLocaleDateString("en-US", options);
};

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  // Generate a darker shade by reducing the brightness
  const darkColor = "#" + "00000".substring(0, 6 - c.length) + c;
  const darkenedColor = adjustBrightness(darkColor, -20); // Adjust brightness by -20

  return darkenedColor;
};

const adjustBrightness = (color: string, amount: number) => {
  const colorValue = parseInt(color.slice(1), 16);

  let r = (colorValue >> 16) + amount;
  let g = ((colorValue >> 8) & 0x00ff) + amount;
  let b = (colorValue & 0x0000ff) + amount;

  r = Math.min(Math.max(0, r), 255);
  g = Math.min(Math.max(0, g), 255);
  b = Math.min(Math.max(0, b), 255);
  // eslint-disable-next-line
  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;
};

export const formatTimeDifference = (datetimeString: string) => {
  const currentDate = new Date();
  const inputDate = new Date(datetimeString);

  const timeDifference =
    (currentDate.getTime() - inputDate.getTime()) / (1000 * 60);
  if (timeDifference < 1) {
    return "Less than a minute ago";
  } else if (timeDifference < 60) {
    return `${Math.floor(timeDifference)} minutes ago`;
  } else if (timeDifference < 24 * 60) {
    return `${Math.floor(timeDifference / 60)} hours ago`;
  } else {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return inputDate.toLocaleDateString("en-US", options);
  }
};

export const getFullName = (user: any) => {
  const firstName = user?.firstName || user?.name;
  const lastName = user?.lastName || "";
  return `${firstName} ${lastName}` || "";
};

export const getAvatarName = (user: any) => {
  const firstName =
    (user?.firstName && user?.firstName[0]) || user?.name[0] || "";
  const lastName = (user?.lastName && user?.lastName[0]) || "";
  return `${firstName}${lastName}` || "";
};

export const getDeliveryNoteFormattedTime = (timestamp: string) => {
  const date = new Date(timestamp);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds(); // Display seconds as an integer

  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
};

export const convertAmountToWords = (amount: number) => {
  const ones = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function convertLessThanOneThousand(n: any) {
    let words = "";
    if (n % 100 < 10) {
      words = ones[n % 100];
      n = Math.floor(n / 100);
    } else if (n % 100 < 20) {
      words = teens[n % 10];
      n = Math.floor(n / 100);
    } else {
      words = ones[n % 10];
      n = Math.floor(n / 10);
      words = tens[n % 10] + " " + words;
      n = Math.floor(n / 10);
    }
    if (n === 0) return words;
    return ones[n] + " Hundred " + words;
  }

  if (amount === 0) return "KWD Zero only";

  let num = Math.floor(amount);
  const cents = Math.round((amount - num) * 100);

  let words = "";
  let scale = 0;

  do {
    const chunk = num % 1000;
    if (chunk !== 0) {
      const chunkWords = convertLessThanOneThousand(chunk);
      words =
        chunkWords +
        (scale > 0
          ? " " + ["Thousand", "Million", "Billion", "Trillion"][scale - 1]
          : "") +
        " " +
        words;
    }
    num = Math.floor(num / 1000);
    scale++;
  } while (num > 0);

  words += " Kuwait Dinar";
  if (cents > 0) {
    words += " and " + (cents < 10 ? "Zero" + cents : cents) + "/100";
  }
  words += " only";

  return words.charAt(0).toUpperCase() + words.slice(1);
};


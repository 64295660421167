import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { fetchContactList } from "../../services/private.services";

const useContactList = () => {
  const [loading, setLoading] = useState(true);
  const [enquiryList, setEnquiryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    search: "",
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      perPage: +event.target.value,
    }));
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: page + 1,
      }));
    },
    []
  );

  // eslint-disable-next-line
  const handleOnChangeSearch = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        search: event.target.value,
      }));
    }, 500),
    []
  );

  const getContactList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await fetchContactList(pagination);
      setEnquiryList(result?.data);
      setTotalCount(result?.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [pagination]);

  useEffect(() => {
    getContactList();
  }, [getContactList, pagination]);

  return {
    loading,
    enquiryList,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    getContactList,
  };
};

export default useContactList;

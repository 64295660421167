import React, { useMemo } from "react";
import Header from "../components/Header/Header";
import HeroSection from "./HeroSection/HeroSection";
import { BodySection } from "./HeroSection/HeroSectionStyled";
import { useLocation } from "react-router-dom";
import { ourServicesConstant } from "../components/OurServices/constants";

const Services = () => {
  const location = useLocation();
  const serviceData = useMemo(() => {
    const data = ourServicesConstant.find(
      (item) => item.id.toString() === location?.pathname.split("/")[2]
    );
    return data;
  }, [location]);
  return (
    <>
      <Header />
      <HeroSection title={serviceData?.title} />
      <BodySection>
        <div className="bodySectionContainer">
          <div className="firstPara">
            <p>{serviceData?.firstParagraph}</p>
            <img src={serviceData?.image} alt="service data" />
          </div>
          <p>{serviceData?.descripiton}</p>
        </div>
      </BodySection>
      {/* <Footer /> */}
    </>
  );
};

export default Services;

import { useMemo } from "react";
import { styled } from "styled-components";
import { upperCase } from "lodash";

export type StatusColor = {
  background: string;
  bgColor: string;
};

export type Statuses = {
  [key: string]: {
    background: string;
    bgColor: string;
  };
};

export const jobCardStatus: Statuses = {
  OPEN: {
    background: "#69ADA0",
    bgColor: "#E7F2F0",
  },
  CLOSED: {
    background: "#FF7A7A",
    bgColor: "#F4E2E2",
  },
  INPROGRESS: {
    background: "#F6D423",
    bgColor: "#FEF7D2",
  },
  ONHOLD: {
    background: "#FFB359",
    bgColor: "#FFE7CB",
  },
  TO_VERIFY: {
    background: "#A295FF",
    bgColor: "#E0E0FF",
  },
};
const JobCardStatuses = ({ status = "open" }: { status: string }) => {
  const statusType: StatusColor = useMemo(() => {
    const statusId = status === "to_verify" ? "TO_VERIFY" : upperCase(status);
    const colors = jobCardStatus[`${statusId}`];
    return colors;
  }, [status]);

  return (
    <StyledStatuses background={statusType?.background}>
      {upperCase(status)}
    </StyledStatuses>
  );
};

export default JobCardStatuses;

const StyledStatuses = styled.div<{
  background: string;
}>`
  background: ${(props) => props.background};
  color: white;
  border-radius: 10px;
  padding: 4px 10px;
  text-align: center;
  text-transform: capitalize;
`;

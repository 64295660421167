import { Grid } from "@mui/material";
import { StyledTable } from "../../Quote/components/styles";
import DeliveryNotesForm from "../forms/GenerateDeliveryNotes.form";
import InputField from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import { DeliveredProduct } from "../../../utils/purchaseOrder";

const GenerateDeliveryNotes = ({
  deliveredProducts,
}: {
  deliveredProducts: DeliveredProduct[];
}) => {
  const formik = DeliveryNotesForm();
  return (
    <>
      <Grid spacing={6} container>
        <Grid xs={12} sm={12} lg={12} item>
          <Grid
            xs={12}
            sm={12}
            lg={12}
            item
            className="mt-2"
            display="flex"
            justifyContent="flex-end"
            container
          >
            <Grid xs={12} sm={12} lg={12} item>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Required Qty</th>
                    <th>Delivered Qty</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {formik?.values?.products?.map(
                    (product: any, index: number) => (
                      <tr key={index}>
                        <td>
                          <div>{product?.productName}</div>
                        </td>
                        <td className="quantity-meta">
                          <div>
                            <div>{product?.quantityRequired}</div>
                          </div>
                        </td>
                        <td className="quantity-meta">
                          <div>
                            <div>
                              {" "}
                              {deliveredProducts
                                ? deliveredProducts?.find(
                                    (data: DeliveredProduct) =>
                                      data.productId === product.productId
                                  )?.quantityDelivered || 0
                                : 0}{" "}
                            </div>
                          </div>
                        </td>
                        <td className="quantity-meta">
                          <div className="p-2">
                            <InputField
                              type="number"
                              name={`products[${index}].quantityDelivered`}
                              handleChange={formik?.handleChange}
                              value={product.quantityDelivered}
                              handleBlur={formik?.handleBlur}
                              disabled={
                                product?.quantityRequired ===
                                deliveredProducts?.find(
                                  (data: DeliveredProduct) =>
                                    data.productId === product.productId
                                )?.quantityDelivered
                              }
                            />{" "}
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </StyledTable>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          spacing={2}
          container
          display="flex"
          justifyContent="flex-end"
          mt={1}
        >
          <Grid xs={12} sm={4} lg={2} item>
            <Button onClick={formik?.handleSubmit}>Generate</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GenerateDeliveryNotes;

import { isArray, pickBy } from "lodash";
import { toast } from "react-toastify";
import axiosInstance from "./axios.interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchQuotationList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    isApproved: query?.isApproved,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(
    formatQuery,
    (e) => e || typeof e === "boolean"
  );
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`quotations?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const createQuotation = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`quotations`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const getQuotationDetails = async (quoteId: string) => {
  try {
    const res = await axiosInstance().get(`quotations/${quoteId}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const approveQuotation = async (quoteId: string) => {
  try {
    const body = {
      isApproved: true,
    };
    const res = await axiosInstance().patch(
      `quotations/approve/${quoteId}`,
      body
    );
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const updateQuotation = async (quoteId: string, credentials: any) => {
  try {
    const res = await axiosInstance().patch(
      `quotations/${quoteId}`,
      credentials
    );
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const generateDeliveryNotes = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(
      `delivery-notes-and-invoice`,
      credentials
    );
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

interface AsyncThunkApiConfig {
  rejectValue: string;
}

export const getDeliveryNotesDetails = createAsyncThunk<
  string,
  AsyncThunkApiConfig
>("purchaseOrderSlice/getDeliveryNotes", async (quoteId) => {
  try {
    const response = await axiosInstance().get(
      `delivery-notes-and-invoice?quotationId=${quoteId}`
    );
    return response.data;
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
});

export const deleteQuotations = async (quoteId: string) => {
  try {
    const res = await axiosInstance().delete(`quotations/${quoteId}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};
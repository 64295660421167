import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { inventoryTypes } from "../../../utils/jobCards";
import { fetchBrandList, fetchCategoryList, fetchProductList } from "../../../services/inventory.services";

const useInventoryList = () => {
  const [inventoryType, setInventoryType] = useState(inventoryTypes?.PRODUCTS);
  const [open, setOpen] = useState(false);
  const [openEditModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    search: "",
  });
  const [inventoryId, setInventoryId] = useState("");

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      perPage: +event.target.value,
    }));
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: page + 1,
      }));
    },
    []
  );

  // eslint-disable-next-line
  const handleOnChangeSearch = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        search: event.target.value,
      }));
    }, 500),
    []
  );

  const handleChangeInventoryId = useCallback((id: string) => {
    setInventoryId(id);
    setEditModal(true);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    getList();
  };
  const handleOpenEditModal = () => setEditModal(true);
  const handleCloseEditModal = () => {
    setEditModal(false);
    setInventoryId("");
  };

  const getList = useCallback(async () => {
    setLoading(true);
    try {
      let result;
      switch (inventoryType) {
        case inventoryTypes.BRAND:
          result = await fetchBrandList(pagination);
          break;
        case inventoryTypes.CATEGORY:
          result = await fetchCategoryList(pagination);
          break;
        case inventoryTypes.PRODUCTS:
          result = await fetchProductList(pagination);
          break;
        default:
          result = await fetchBrandList(pagination);
      }
      setList(result?.data);
      setTotalCount(result?.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [pagination, inventoryType]);

  useEffect(() => {
    getList();
  }, [getList, pagination]);

  useEffect(() => {
    setPagination({
      page: 1,
      perPage: 10,
      search: "",
    });
  }, [inventoryType]);

  return {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    getList,
    handleOpen,
    handleClose,
    open,
    inventoryType,
    setInventoryType,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    handleChangeInventoryId,
    inventoryId,
  };
};

export default useInventoryList;

import { isArray, pickBy } from "lodash";
import axiosInstance from "./axios.interceptors";
import { toast } from "react-toastify";
import { axiosPublicInstance } from "./public.services";

export const createBrand = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`brands`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const createCategory = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`Category`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchCategoryList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    isActive: query?.isActive,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`Category?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchBrandList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    isActive: query?.isActive,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`brands?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchProductList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`Products?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const updateBrandDetails = async (id: string, credentials: any) => {
  try {
    const res = await axiosInstance().patch(`brands/${id}`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const updateCategoryDetails = async (id: string, credentials: any) => {
  try {
    const res = await axiosInstance().patch(`Category/${id}`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const createProduct = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`Products`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const updateProductDetails = async (id: string, credentials: any) => {
  try {
    const res = await axiosInstance().patch(`Products/${id}`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchProductListPublic = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosPublicInstance().get(`Products/public/info?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchBrandListPublic = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    isActive: query?.isActive,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosPublicInstance().get(`brands/public?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchCategoryListPublic = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    isActive: query?.isActive,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosPublicInstance().get(`Category/public?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

import SystemMaintenance from "../../../../assets/images/SystemMaintenance.png";
import NetworkCabling from "../../../../assets/images/NetworkCabling.png";
import NetworkDesign from "../../../../assets/images/NetworkDesign.png";
import PrinterConsumabales from "../../../../assets/images/PrinterConsumabales.png";
import PrinterService from "../../../../assets/images/PrinterService.png";
import BrandedServers from "../../../../assets/images/BrondedServers.png";

export const ourServicesConstant = [
  {
    id: 1,
    title: "System Maintenance",
    image: SystemMaintenance,
    firstParagraph:
      "On call service: SNCC offers world class maintenance services for servers, workstations, notebooks and printers. We at SNCC have team of well updated service personnel that can provide you the best solutions within a short span. We also optimize your system for the best performance so that you get the feel of working in a brand new computer. We are also engaged in the virus / spyware / malware removal service and data recovery service with complete care and attention. We are well known company for the fastest turnaround time in Kuwait. Annual Maintenance Contract: SNCC also offers superb computer Annual Maintenance Contracts services for maintenance of servers, desktops, notebooks, printers and networks. We have different types of maintenance contracts, which varies depends on the response time, which the customers can choose. Also we could provide standby items, depends on the type of contract, you choose. Resident Engineering: This is essentially outsourcing. We place an engineer(s) on your site, at your disposal to do whatever tasks you require to support your systems or network. This service is available for as little as one man day per week up to full time arrangements. If you wish to enjoy a trouble free work environment let your computers under the vigilant care of SNCC. Next time when you think of computer repairs and computer services in Kuwait, remember SNCC is at your service.",
    descripiton: "",
  },
  {
    id: 2,
    title: "Printer Consumables",
    image: PrinterConsumabales,
    firstParagraph:
      "In the field of genuine ink / cartridge suppliers, we are in the leading position. We have partnership agreement with HP, which enable us to supply any quantity of HP toners of any model HP printers at a very competitive price. We are supplying only genuine ink cartridges and laser toners. Customers who are looking for quality consumables, we are the first option. Also we are supplying large range of Genuine Toners and Ink Cartridges of the following manufacturers and brands. Apple, Brother, Canon, Epson, Fujitsu, Hewlett Packard, Kyocera, Lexmark, NEC, Oki, Panasonic, Ricoh, Sagem, Samsung, Sharp, Toshiba, Xerox and Zebra.",
    descripiton: "",
  },
  {
    id: 3,
    title: "Printer Services",
    image: PrinterService,
    firstParagraph:
      "Our printer maintenance division is equipped with state of the art technical equipments and company trained printer professionals dedicated to each brand. We have a separate fully fledged workshop only for HP Laser printers with HP, Epson certified and trained service engineers and technicians. Our printer engineers and technicians joined us after many years of experience in hardware and printer service management roles at companies such as HP, Epson and Lexmark. Our aim is simply to provide an excellent laser printer repair service to your organization, minimizing down-time and ensuring the long term reliability of your office equipment. Printer repair technicians are customer-focused, well presented and regularly undertake training courses in HP, Lexmark, IBM, Canon, Epson and other OEM printer products. We seek ongoing relationships with our customers to actively ensure that your laser printer investment is well protected. We have found this to be a service valued by existing clients. Our parts stock is extensive and very reasonably priced. The usual three or six-month manufacturer’s warranty applies on all parts we used. Our own work is fully guaranteed. We are undertaking the maintenance of almost all brands of Printers including Konica-Minolta, Kyocera, Oki, Sharp, Samsung, Panasonic, Toshiba, Xerox and more! On-site service is available for our commercial clients.",
    descripiton:
      "Printers are designed with parts that wear out over time, similar to brake pads on an automobile. These parts can be cost effectively replaced to keep your printer tuned & working like new. SNCC provides maintenance services for laser monochrome & colour printers including replacement of drums, fuser kits, transfer rollers and other wearable parts. Caring for your inkjet or laser printer can help to ensure a long useful life. Paper dust and other debris can build up inside of the printer over time to time, resulting in poor print quality, paper jams and other printing errors",
  },
  {
    id: 4,
    title: "Branded Servers, Desktops & Notebooks",
    image: BrandedServers,
    firstParagraph: "The branded IT products we are supplying including servers, desktops and laptops, which come as a pre-assembled product saving you much time energy. Today most of them come in various standard configurations that you may pick from and even with preloaded operating systems. You just need to plug-in and start working. The greatest advantage is that the company that provide you the computer will take up the responsibility of any defects or problems that you come across during the warranty period. At Sahara, we are dealing different branded servers, desktop computers and laptops making it convenient for your choose between a variety of brands available in the market under a single roof. We are also able to provide the genuine Microsoft operating system software and licenses for servers and computers separately.",
    descripiton: "",
  },
  {
    id: 5,
    title: "Network design & Implementation",
    image: NetworkDesign,
    descripiton:
      "We thoroughly research, benchmark, compare and use all products and services under \n consideration before including them in our portfolio of offerings. We are proud to offer a discreet line \n of the highest quality products from industry-leading manufacturers. We specialize in the design, \n installation and maintenance of converged IP networks supporting data switching and routing, \n voice over IP (VoIP) and IP-based video distribution over secure wired and WLAN infrastructures. \n  \n Post sales support is perhaps the most significant differentiator that SNCC has to offer our customers. \n Anyone can provide product and most can do a reasonably adequate job of a basic installation. \n The real test of an organization’s competency and commitment is revealed in how they support \n the product and the customer after the sale. This is where trust is established, relationships are \n built, and where Sahara really shines. \n We offer service contracts on all network hardware and software solutions in our portfolio, \n regardless of whether or not you actually purchased the products from us.",
    firstParagraph:
      "Your Satisfaction is our Inspiration Network Design & Implementation At Sahara, we have some of the best talented engineers and technicians available and employ a group of experienced staff with solid backgrounds in account and project management, networkdesign, and field engineering. All of our services are provided by network engineers with specific knowledge and significant experience relevant to any project.Whether you are setting up a new IT infrastructure, replacing an ageing network or expanding your existing capacity, we have the design and implementation skills required to deliver the solution. We have years of experience in the design, implementation and management of IT Solutions across varied business sectors. How your business data needs to be accessed, storage, key business applications and security are areas we would address in order to provide the correct solution. Our site audit would allow us to understand the way in which your data needs to be accessed by office and remote workers, the storage required to run your applications and the options for locating your data such as On-Premise, Data Center Hosting and Cloud Computing. Sahara adheres to a “best value” philosophy in designing and building standards-based networks. We strive for solutions that provide the highest level of performance, features, functionality, scalability, and manageability at the lowest total cost of ownership and smallest carbon footprint.",
  },
  {
    id: 6,
    title: "Network Cabling Solutions",
    image: NetworkCabling,
    descripiton:
      " • Category 5e, 6, 6A, 7 and 7A Data Cabling • Fibre Optical OM1, OM2, OM3, OM4, OS1, and OS2 Cabling • Wireless Planning and Installation (Wi-Fi), Voice Cabling",
    firstParagraph:
      "Providing robust and reliable solutions for data and communication cabling is a crucial part of any IT infrastructure, no matter how large or small the business. With ever increasing levels of bandwidth and a greater reliance on network devices a high quality data cabling system is essential. We specialize in the installation of structured cabling to meet your IT and communication network requirements. Not only must the cabling system be able to cope with today’s demanding internet, Intranet, video conferencing, email and multimedia applications but it should have the scope for planned applications of tomorrow. Our teams have the experience and expertise to install, test and maintain the following business critical infrastructures:- ",
  },
];

import React from "react";
import { Grid } from "@mui/material";
import { TContainer } from "../../components/Common/Table";
import SearchInput from "../../components/Common/SearchInput";
import { MainWrapper, StyledHeader } from "../../components/styles";
import useContactList from "./useContactList";
import ListContacts from "./EquireTable";

const JobCards = () => {
  const {
    loading,
    enquiryList,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
  } = useContactList();
  return (
    <MainWrapper>
      <TContainer>
        <Grid container justifyContent="space-between" mb={2} gap={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader style={{ marginRight: 8 }}>Enquiry</StyledHeader>
            <SearchInput onChange={handleOnChangeSearch} />
          </Grid>
        </Grid>
        <ListContacts
          loading={loading}
          list={enquiryList}
          totalCount={totalCount}
          pagination={pagination}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleOnChangeSearch={handleOnChangeSearch}
        />
      </TContainer>
    </MainWrapper>
  );
};

export default JobCards;

import * as Yup from "yup";
import { type, jobTitle, dueDate } from "../../utils/validations";

export const createJobCardValidations = Yup.object({
  type,
  jobTitle,
  dueDate,
  serviceType: Yup.string().test(
    "is-required",
    "Service Type is required",
    function (value) {
      const type = this.resolve(Yup.ref("type"));
      if (type !== "task" && !value) {
        return false;
      }
      return true;
    }
  ),
  reportedProblem: Yup.string().test(
    "is-required",
    "Problem is required",
    function (value) {
      const type = this.resolve(Yup.ref("type"));
      if (type !== "task" && !value) {
        return false;
      }
      return true;
    }
  ),
  precondition: Yup.string().test(
    "is-required",
    "Pre Condition is required",
    function (value) {
      const type = this.resolve(Yup.ref("type"));
      if (type !== "task" && !value) {
        return false;
      }
      return true;
    }
  ),
  accessories: Yup.string().test(
    "is-required",
    "Accessories is required",
    function (value) {
      const type = this.resolve(Yup.ref("type"));
      if (type !== "task" && !value) {
        return false;
      }
      return true;
    }
  ),
});

import { useFormik } from "formik";
import { createUserValidations } from "../validations";
import { updateUser } from "../../../services/private.services";
import { toast } from "react-toastify";
import { useMemo } from "react";

export interface UserValues {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  houseNumber: string;
  streetName: string;
  country: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  middleName: string;
  role: string;
  id?: string;
}
const UpdateUserForm = (
  handleClose: () => void,
  userDetails: UserValues,
  getUserList: () => void
) => {
  const userId = userDetails?.id ?? "";
  const initialValues = useMemo(() => {
    return {
      firstName: userDetails?.firstName ?? "",
      lastName: userDetails?.lastName ?? "",
      email: userDetails?.email ?? "",
      gender: userDetails?.gender ?? "",
      houseNumber: userDetails?.houseNumber ?? "",
      streetName: userDetails?.streetName ?? "",
      country: userDetails?.country ?? "",
      state: userDetails?.state ?? "",
      postalCode: userDetails?.postalCode ?? "",
      phoneNumber: userDetails?.phoneNumber ?? "",
      middleName: userDetails?.middleName ?? "",
      role: userDetails?.role ?? "",
    };
  }, [userDetails]);

  return useFormik<UserValues>({
    initialValues: initialValues,
    validationSchema: createUserValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const dataObj = {
        ...values,
      };
      await updateUser(dataObj, userId);
      toast.success("Updated user details");
      getUserList();
      handleClose();
    },
  });
};
export default UpdateUserForm;

export const headerItems = [
  {
    name: "Home",
    id: 1,
    path: "/",
  },
  {
    name: "About Us",
    id: 4,
    path: "/policy",
  },
  {
    name: "Work",
    id: 2,
    path: "/#work",
  },
  {
    name: "Service",
    id: 3,
    path: "/#services",
  },

  {
    name: "Contact",
    id: 7,
    path: "/#contactUs",
  },
];

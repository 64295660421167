import { inventoryTypes } from "../../utils/jobCards";

export const getInventoryTypeName = (inventoryType: string, action: string) => {
  switch (inventoryType) {
    case inventoryTypes.BRAND:
      return `${action} Brand`;
    case inventoryTypes.CATEGORY:
      return `${action} Category`;
    case inventoryTypes.PRODUCTS:
      return `${action} Products`;
    default:
      return "";
  }
};

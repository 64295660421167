import { useFormik } from "formik";
import { createUserValidations } from "../validations";
import { creatUser } from "../../../services/private.services";
import { toast } from "react-toastify";

const CreatUserForm = (handleClose: () => void) => {
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      gender: "",
      houseNumber: "",
      streetName: "",
      country: "",
      state: "",
      postalCode: "",
      phoneNumber: "",
      middleName: "",
      role: "",
    },
    validationSchema: createUserValidations,
    onSubmit: async (values) => {
      const dataObj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        gender: values.gender,
        address: {
          houseNumber: values.houseNumber,
          streetName: values.streetName,
          country: values.country,
          state: values.state,
          postalCode: values.postalCode,
        },
        phoneNumber: values.phoneNumber,
        middleName: values.middleName,
        role: values.role,
      };
      const result = await creatUser(dataObj);
      console.log(result)
      toast.success("User created Successfully");
      handleClose();
    },
  });
};

export default CreatUserForm;

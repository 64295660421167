import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./auth";
import PrivateRoute from "./components/PrivateRoutes";
import PublicRoutes from "./components/PublicRoutes";
import HomePage from "./containers/Home";
import ProductPage from "./containers/Home/Products";
import ServicePage from "./containers/Home/Services";
import PolicyPage from "./containers/Home/Policy";

const AppRouter: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/services/:id" element={<ServicePage />} />
          <Route path="/policy" element={<PolicyPage />} />
        </Routes>
        <PublicRoutes />
        <PrivateRoute />
      </Router>
    </AuthProvider>
  );
};
export default AppRouter;

import { combineReducers } from "@reduxjs/toolkit";
import purchaseOrderReducer from "./purchaseOrder/purchaseOrder.slice";
import productOrderReducer from "./product/product.Slice";

const rootReducer = combineReducers({
  purchaseOrder: purchaseOrderReducer,
  product: productOrderReducer,
});

export default rootReducer;

import React from "react";
import { StyledError } from "../../../components/styles";
import InputField from "../../../components/Common/Input";
import { Grid } from "@mui/material";

const QuoteInputs = ({
  formik,
  isDisabled = false,
}: {
  formik: any;
  isDisabled?: boolean;
}) => {
  return (
    <>
      <Grid spacing={3} container>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Name</label>
          <InputField
            name="name"
            formik={formik}
            placeholder="Name"
            disabled={isDisabled}
          />
          <StyledError>
            {formik.touched?.name && formik.errors["name"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Description</label>
          <InputField
            name="description"
            formik={formik}
            placeholder="Description"
            disabled={isDisabled}
          />
          <StyledError>
            {formik.touched?.description && formik.errors["description"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <label>Reference No.</label>
          <InputField
            name="userRefNo"
            formik={formik}
            placeholder="Reference No"
            disabled={isDisabled}
          />
          <StyledError>
            {formik.touched?.userRefNo && formik.errors["userRefNo"]}
          </StyledError>
        </Grid>
      </Grid>
    </>
  );
};

export default QuoteInputs;

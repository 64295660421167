import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ThreeDots } from "react-loader-spinner";
import { Grid } from "@mui/material";
import Logo from "../../assets/images/saharaText.png";
import HomeBg from "../../assets/images/HomeBg.png";
import Button from "../Common/Button";
import InputField from "../Common/Input";
import useSigninHook from "./useSigninHook";
import { StyledError } from "../styles";

const Signin = () => {
  const navigate = useNavigate();
  const { formik } = useSigninHook();
  return (
    <StyledSignin className="login-wrapper">
      <div className="glass-element">
        <div className="body">
          <h3>Hi,</h3>
          <h1>Welcome</h1>
          <p>
          A solution and a unified platform for efficient communication, data analysis, and customer engagement, empowering your team to deliver exceptional services and build lasting client relationships.
          </p>
          <h4>Regards,</h4>
          <h5>Sahara National Computers</h5>
        </div>
        <div className="cardBody">
          <img src={Logo} alt="Sahara computers" />
          <Grid mb={2} className="input-cls" px={2}>
            <InputField
              placeholder="Enter your email address"
              formik={formik}
              name="email"
              login={true}
            />
            <StyledError>
              {formik.touched?.email && formik.errors["email"]}
            </StyledError>
          </Grid>
          <Grid px={2}>
            <InputField
              type="password"
              placeholder="Enter your password"
              formik={formik}
              name="password"
              login={true}
            />
            <StyledError>
              {formik.touched?.password && formik.errors["password"]}
            </StyledError>
          </Grid>
          <div
            className="form-login px-3"
            onClick={() => navigate("/admin/forgot-password")}
          >
            <h4 className="form-forgot">Forgot Password?</h4>
          </div>
          <Grid px={2}>
            {!formik.isSubmitting ? (
              <Button
                onClick={formik?.handleSubmit}
                buttontypes="primary"
                loading={true}
              >
                Login
              </Button>
            ) : (
              <StyledLoader>
                <ThreeDots color="white" height={50} width={50} />
              </StyledLoader>
            )}
          </Grid>
        </div>
      </div>
    </StyledSignin>
  );
};

export default Signin;

const StyledSignin = styled.div`
  background: url(${HomeBg}) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .glass-element {
    width: 1176px;
    height: 682px;
    border-radius: 9px;
    background: rgba(255, 254, 255, 0.44);
    backdrop-filter: blur(12px);
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    position: relative;
    @media (max-width: 777px) {
      height: 482px;
      width: 380px;
      padding: 30px 30px;
    }
  }
  .cardBody {
    position: absolute;
    width: 360px;
    height: 452px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    box-shadow: 0px 1px 18px 0px rgba(180, 180, 180, 0.08);
    backdrop-filter: blur(14.5px);
    top: 115px;
    right: 229px;
    padding: 81px 40px 91px;
    @media (max-width: 1065px) {
      right: 100px;
    }
    @media (max-width: 943px) {
      right: 10px;
    }
    @media (max-width: 777px) {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .input-cls {
    margin-top: 22px;
  }
  .form-forgot {
    text-align: right;
    color: #2b3b7c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  }
  .body {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 80px;
    h1 {
      color: rgba(46, 43, 43, 0.65);
      font-family: Poppins;
      font-size: 80px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0px;
      padding: 0px;
    }
    h3 {
      color: rgba(46, 43, 43, 0.65);
      font-family: Poppins;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
      padding: 0px;
    }
    p {
      color: rgba(17, 17, 17, 0.65);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 366px;
      text-align: left;
    }
    h4 {
      color: rgba(17, 17, 17, 0.65);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h5 {
      color: rgba(17, 17, 17, 0.65);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    @media (max-width: 943px) {
      margin-left: 10px;
    }
    @media (max-width: 777px) {
      display: none;
    }
  }
  @media (max-width: 777px) {
    justify-content: center;
  }
`;

const StyledLoader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgb(236, 32, 38) !important;
  color: white;
  height: 44px;
`;

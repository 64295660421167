import { SecondaryFooterContainer } from "./SecondaryFooterStyled";
import Location from "../../../../assets/images/location.png";
import Facebook from "../../../../assets/images/fb.svg";
import Insta from "../../../../assets/images/insta.svg";
import Mail from "../../../../assets/images/mail.svg";

const SecondaryFooter = () => {
  return (
    <SecondaryFooterContainer>
      <div className="secondaryFooterWrapper">
        <div className="footerItems">
          <h3>Location</h3>
          <a
            href="https://maps.app.goo.gl/ZZfMaGZy6raCoYm76"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Location}
              alt="Location"
              className="location"
            />{" "}
          </a>
        </div>
        <div className="footerItems">
          {/* <h3>Company</h3> */}
          {/* <ul>
            <li>- Work</li>
            <li>- Service</li>
            <li>- Team</li>
            <li>- Contact</li>
          </ul> */}
        </div>
        <div className="footerItems">
          <h3>Contact Us</h3>
          <ul className="contactUs">
            <li>
              <img src={Facebook} alt="Facebook" />{" "}
              <a
                href="https://www.facebook.com/people/SaharaComputers/100065312596899/"
                target="_blank"
                rel="noopener noreferrer"
              >
                saharanational
              </a>
            </li>
            <li>
              <img src={Insta} alt="Instagram" />{" "}
              <a
                href="https://www.instagram.com/saharacomputerskw/"
                target="_blank"
                rel="noopener noreferrer"
              >
                saharanational
              </a>
            </li>
            <li>
              <img src={Mail} alt="email" />{" "}
              <a href="mailto:support@sahara.com">sales@saharacomputers.com</a>
            </li>
          </ul>
        </div>
      </div>
    </SecondaryFooterContainer>
  );
};

export default SecondaryFooter;

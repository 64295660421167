import { ReactNode } from "react";
import { styled } from "styled-components";

export const StyledError = styled.div`
  font-size: 14px;
  color: #e21717;
  text-align:left;
`;

export const StyledHeader = styled.h4`
  text-transform: uppercase;
  font-weight: 800;
  margin: 0 8px 0 0 !important;
  color: var(--primary-header);
`;

export const StyledLabel = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-subheader);
`;

export const StyledSidebar = styled.div`
  .sidebar-inner {
    border-top: 1px solid red;
    width: 100%;
  }
  .MuiList-root {
    background: var(--secondary) !important;
  }
  .MuiSvgIcon-root {
    color: #78899b;
  }
  .MuiTypography-root {
    font-family: "Nunito", sans-serif !important;
  }
  .MuiListItemButton-root {
    min-width: 220px;
    height: 50px;
    color: #78899b;
    font-family: "Nunito", sans-serif !important;
  }
  .active-tab {
    .MuiSvgIcon-root {
      color: #000;
    }
    background-color: #fff !important;
    color: #000;
  }
  .MuiListItemButton-root:hover {
    .MuiSvgIcon-root {
      color: #000;
    }
    border-radius: 0 !important;
    background-color: #fff !important;
    color: #000;
  }
  .inner-child {
    border-radius: 5px;
    // background: #fafbfe;
    .MuiListItemText-root {
      margin-left: -12px;
    }
    .MuiListItemButton-root {
      height: 45px;
      font-family: "Nunito", sans-serif !important;
    }
    .MuiListItemButton-root:hover {
      .MuiSvgIcon-root {
        color: #000;
      }
      color: #000;
      background-color: #fafbfe !important;
    }
  }
`;

export const MainWrapper = ({ children }: ReactNode | any) => {
  return (
    <div
      className="card"
      style={{
        width: "100%",
        margin: 8,
      }}
    >
      <div
        className="card-body"
        style={{
          height: "100%",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 16px ",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const StatusWrapper = styled.div`
  padding: 4px 4px;
  background: ${(props) => props?.color};
  border-radius: 8px;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
`;

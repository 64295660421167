import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import HomeRepairServiceTwoToneIcon from '@mui/icons-material/HomeRepairServiceTwoTone';
import ReceiptIcon from '@mui/icons-material/Receipt';

export const whatWeDoItems = [
  {
    id: 1,
    title: "HP Power Service Partner",
    description:
      "Sahara specializes in providing a wide array of IT solutions, including hardware, software, and network services. From top-tier hardware products to software implementations, we have you covered.",
    image: HandshakeTwoToneIcon,
  },
  {
    id: 2,
    title: "Authorized Sales for Heavy Duty Copiers and Printers",
    description:
      "Offering high-end toners for exceptional print quality, As an authorized service center, we are fully equipped and certified to provide exceptional support for all your HP commercial printer needs. Our experienced team is here to ensure your printers run smoothly, offering maintenance, repairs, and expert guidance.",
    image: HomeRepairServiceTwoToneIcon,
  },
  {
    id: 2,
    title: "General sales and services",
    description:
      "Sahara offers specialized services in printer maintenance and network cabling solutions. We keep your printing solutions running seamlessly and provide robust network infrastructure designs and implementations.",
    image: ReceiptIcon,
  },
];

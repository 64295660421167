import React from "react";
import { styled } from "styled-components";

const WhatWeDoItems = ({
  title,
  ImageIcon,
  description,
}: {
  title: string;
  ImageIcon: any;
  description: string;
}) => {
  return (
    <StyledItems>
      <div className="TitleWrapper">
        {/* <img src={image} alt="icon" /> */}
        <ImageIcon
          style={{
            color: "#D93A35",
          }}
        />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </StyledItems>
  );
};

export default WhatWeDoItems;

export const StyledItems = styled.div`
  display: flex;
  flex-direction: column;
  .TitleWrapper {
    display: flex;
    min-width: 120px;
    width: 100%;
    align-items: center;
    h3 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      margin: 0;
      padding: 0;
      margin-left: 20px;
      @media (max-width: 959px) {
        line-height: auto;
      }
    }
  }
  p {
    max-width: 385px;
    width: 100%;
    color: #5e5b5b;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Poppins;
    margin: 0;
    padding: 0;
    margin-left: 47px;
    @media (max-width: 959px) {
      width: 100%;
    }
  }
`;

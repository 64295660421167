import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProductWrapper, ProductButton } from "./ProductStyled";
import useProductList from "./useProductList";
import { ProductItem } from "./ProductItem";

const Products = () => {
  const navigate = useNavigate();
  const { list } = useProductList();
  const settings = {
    dots: false,
    // infinite: true,
    slidesToShow: 4,
    // autoplay: true,
    // speed: 2500,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const onBtnClick = () => {
    navigate("/products");
  };

  return (
    <ProductWrapper>
      <div className="productContainer">
        <h2>Our Products</h2>
        <div>
          <Slider {...settings}>
            {list?.map((item, index) => {
              return <ProductItem products={item} key={index} />;
            })}
          </Slider>
        </div>
        <div className="buttonWrapper">
          <ProductButton onClick={() => onBtnClick()}>
            More Products
          </ProductButton>
        </div>
      </div>
    </ProductWrapper>
  );
};

export default Products;

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { ThreeDots } from "react-loader-spinner";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowHeader,
} from "../../../components/Common/Table";
import Pagination from "../../../components/Common/Table/Components/Pagination";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

type IProps = {
  count?: number;
  rowsPerpage?: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onRowsPerPageChange?: any;
  page: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  data: any[];
  tableHeader: any[];
  handleOpenEditModal: (data: any) => void;
  onSetCustomerId: (data: string) => void;
};

const CustomerTableList = ({
  count = 0,
  rowsPerpage = 10,
  page = 1,
  onPageChange,
  handleChangeRowsPerPage,
  tableHeader = [],
  loading,
  data = [],
  handleOpenEditModal,
  onSetCustomerId,
}: IProps) => {
  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRowHeader>
            {tableHeader?.map((item, idx) => {
              return <StyledTableCell key={idx}>{item?.name}</StyledTableCell>;
            })}
          </StyledTableRowHeader>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data?.map((row: any, idx: any) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell key={row.name + idx}>
                  {row.name}
                </StyledTableCell>
                <StyledTableCell key={row.email + idx}>
                  {row.email}
                </StyledTableCell>
                <StyledTableCell key={row?.phoneNumber + idx}>
                  {row?.phoneNumber}
                </StyledTableCell>
                <StyledTableCell key={row?.address + idx}>
                  {row?.address}
                </StyledTableCell>
                <StyledTableCell key={row?.deliveryAddress + idx}>
                  {row?.deliveryAddress}
                </StyledTableCell>
                <StyledTableCell key={row?.id + idx}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <ModeIcon
                      onClick={() => handleOpenEditModal(row)}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <div
                      onClick={() => onSetCustomerId(row?.id)}
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteForeverIcon />
                    </div>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#1B2850" height={50} width={50} />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data Found
        </div>
      )}
      <Pagination
        count={count}
        rowsPerpage={rowsPerpage}
        onPageChange={onPageChange}
        page={page}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default CustomerTableList;

export const asyncLocalStorage = {
  setItem: (value: string, key = process.env.REACT_APP_TOKEN) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(`${key}`, value as string);
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  },
  getItem: (key = process.env.REACT_APP_TOKEN) => {
    return new Promise((resolve, reject) => {
      try {
        const token = localStorage.getItem(`${key}`);
        resolve(token);
      } catch (error) {
        reject(false);
      }
    });
  },
  removeItem: (key = process.env.REACT_APP_TOKEN): any => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(`${key}`);
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  },
};

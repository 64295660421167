import React from "react";
import Button from "../Common/Button";
import styled from "styled-components";
import InputField from "../Common/Input";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <StyledForgotPassword className="login-wrapper">
      <div className="login-content">
        <div className="login-userset">
          <div className="login-logo">LOGO</div>
          <div className="login-userheading">
            <h3>Forgot password?</h3>
            <h4>
              Don’t worry! it happens. Please enter the address <br />{" "}
              associated with your account.
            </h4>
          </div>
          <label>Email</label>
          <InputField placeholder="Enter your email address" required />
          <div className="form-login" onClick={() => navigate("/crm-auth")}>
            <h4 className="form-forgot">
              {" "}
              Go Back
            </h4>
          </div>
          <Grid style={{ marginTop: 8 }}>
            <Button buttontypes = "primary">Submit</Button>
          </Grid>
        </div>
      </div>
      <div className="login-img">
        <img
          src="https://preadmin.dreamguystech.com/html/pos/template/assets/img/login.jpg"
          alt="img"
        />
      </div>
    </StyledForgotPassword>
  );
};

export default ForgotPassword;

const StyledForgotPassword = styled.div`
  .form-forgot {
    font-size: 1rem;
    color: var(--primary);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

import React, { useState } from "react";
import { Grid } from "@mui/material";
import {
  MainWrapper,
  StyledHeader,
} from "../../components/styles";
import { Wrapper } from "./components/styles";
import Button from "../../components/Common/Button";
import CreateQuotationFrom from "./forms/CreateQuotation.form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import QuoteAddress from "./components/QuoteAddress";
import { Customer } from "../Customers/types";
import QuoteProduct from "./components/QuoteProduct";
import QuoteInputs from "./components/QuoteInputs";

const CreateQuote = () => {
  const [open, setOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    deliveryAddress: "",
    email: "",
  });
  const onClose = () => {
    setOpen(false);
  };
  const formik = CreateQuotationFrom();
  const handleAddElement = () => {
    formik.setFieldValue("products", [
      ...formik.values.products,
      {
        productId: "",
        quotedPrice: "",
        quantityRequired: "",
      },
    ]);
  };

  const handleRemoveProduct = (index: number) => {
    const updatedPersons = [...formik.values.products];
    updatedPersons.splice(index, 1);
    formik.setFieldValue("products", updatedPersons);
  };

  const onSelectCustomer = (user: Customer) => {
    setCustomerDetails(user);
    formik.setFieldValue("customerId", user?.id);
    onClose();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("isEmailSent", event.target.checked);
  };
  return (
    <MainWrapper>
      <Wrapper>
        <Grid className="mb-2">
          <StyledHeader style={{ margin: "8px 0px !important" }}>
            Create Quote
          </StyledHeader>
        </Grid>
        <QuoteInputs formik={formik} />
        <hr />
        <QuoteAddress
          formik={formik}
          onClose={onClose}
          customerDetails={customerDetails}
          onSelectCustomer={onSelectCustomer}
          open={open}
          setOpen={setOpen}
        />
        <hr />
        <QuoteProduct
          formik={formik}
          handleAddElement={handleAddElement}
          handleRemoveProduct={handleRemoveProduct}
        />
        <hr />
        <Grid
          spacing={2}
          container
          display="flex"
          justifyContent="space-between"
        >
          <Grid xs={12} sm={6} lg={6} item>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} />}
              label="Send Quote via e-mail."
            />
          </Grid>
          <Grid xs={12} sm={4} lg={2} item>
            <Button onClick={formik?.handleSubmit}>Create</Button>
          </Grid>
        </Grid>
      </Wrapper>
    </MainWrapper>
  );
};

export default CreateQuote;

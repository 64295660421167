import React from "react";
import Magnifier from "../../../assets/svg/search-white.svg";
import styled from "styled-components";

type Iprops = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
};

const SearchInput = ({ onChange, value }: Iprops) => {
  return (
    <Wrapper>
      <img src={Magnifier} alt="Search" />
      <InputWrapper
        type="text"
        placeholder="Search..."
        onChange={onChange}
        value={value}
      />
    </Wrapper>
  );
};

export default SearchInput;

const Wrapper = styled.div`
  height: 40px;
  width: 250px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;

const InputWrapper = styled.input`
  outline: none;
  border: none;
  width: 225px;
  padding: 0 0 0 10px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
`;

import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProductItemWrapper, Wrapper } from "./ProductStyled";
import { setProduct } from "../../../../store/product/product.Slice";

interface Props {
  products: {
    description: string;
    id: string;
    modelNo: string;
    name: string;
    serialNo: string;
    productCategory: {
      name: string;
    };
    productMappedBrand: {
      name: string;
    };
    thumbnailUrl: string | null;
  };
}

export const ProductItem = ({ products }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onProductClick = (productName: any = "") => {
    dispatch(setProduct(productName));
    navigate("/#contactUs");
  };
  return (
    <Wrapper>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a href="#contactUs" onClick={() => onProductClick(products?.name)}></a>
      <ProductItemWrapper>
        <div
          style={{
            padding: "0px 20px",
          }}
        >
          <img src={products?.thumbnailUrl || ""} alt="Products" />
        </div>
        <div className="productDetail">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h6>{products?.productCategory?.name}</h6>
            <h6>{products?.productMappedBrand?.name}</h6>
          </div>
          <h1>{products?.name}</h1>
          <Tooltip title={products?.description}>
            <p>{products?.description}</p>
          </Tooltip>
        </div>
      </ProductItemWrapper>
    </Wrapper>
  );
};

import React from "react";
import { Grid } from "@mui/material";
import InputField from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import Dropdown from "../../../components/Common/Dropdown";

import { gender, roles } from "../../../utils/jobCards";
import {
  StyledError,
  StyledHeader,
  StyledLabel,
} from "../../../components/styles";
import UpdateUserForm from "./updateUser.form";

type IProps = {
  handleClose: () => void;
  getUserList: () => void;
  userDetails: any;
};

const UpdateUser = ({ handleClose, userDetails, getUserList }: IProps) => {
  const formik = UpdateUserForm(handleClose, userDetails, getUserList);
  return (
    <>
      <Grid container>
        <Grid xs={12} sm={6} lg={6} item justifyContent="flex-start">
          <StyledHeader style={{ marginBottom: 4 }}>Update User</StyledHeader>
        </Grid>
      </Grid>
      <Grid spacing={3} container>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>First Name</StyledLabel>
          <InputField formik={formik} name="firstName" />
          <StyledError>
            {formik.touched?.firstName && formik.errors["firstName"]}
          </StyledError>
        </Grid>
        {/* <Grid xs={12} sm={6} lg={4} item>
            <StyledLabel>Middle Name</StyledLabel>
            <InputField formik={formik} name="middleName" />
            <StyledError>
              {formik.touched?.middleName && formik.errors["middleName"]}
            </StyledError>
          </Grid> */}
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Last Name</StyledLabel>
          <InputField formik={formik} name="lastName" />
          <StyledError>
            {formik.touched?.lastName && formik.errors["lastName"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Email</StyledLabel>
          <InputField formik={formik} name="email" />
          <StyledError>
            {formik.touched?.email && formik.errors["email"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Phone</StyledLabel>
          <InputField formik={formik} name="phoneNumber" />
          <StyledError>
            {formik.touched?.phoneNumber && formik.errors["phoneNumber"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Gender</StyledLabel>
          <Dropdown
            options={gender}
            className="mt-2"
            formik={formik}
            name="gender"
            value={formik?.values?.gender}
          />
          <StyledError>
            {formik.touched?.gender && formik.errors["gender"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>User Role</StyledLabel>
          <Dropdown
            options={roles}
            className="mt-2"
            formik={formik}
            name="role"
            disabled={formik?.values?.role === "admin"}
          />
          <StyledError>
            {formik.touched?.role && formik.errors["role"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>House Number</StyledLabel>
          <InputField formik={formik} name="houseNumber" />
          <StyledError>
            {formik.touched?.houseNumber && formik.errors["houseNumber"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Street Name</StyledLabel>
          <InputField formik={formik} name="streetName" />
          <StyledError>
            {formik.touched?.streetName && formik.errors["streetName"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>State</StyledLabel>
          <InputField formik={formik} name="state" />
          <StyledError>
            {formik.touched?.state && formik.errors["state"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Postal Code</StyledLabel>
          <InputField formik={formik} name="postalCode" />
          <StyledError>
            {formik.touched?.postalCode && formik.errors["postalCode"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item>
          <StyledLabel>Country</StyledLabel>
          <InputField formik={formik} name="country" />
          <StyledError>
            {formik.touched?.country && formik.errors["country"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item className="p-0 m-0"></Grid>
        <Grid xs={12} sm={6} lg={4} item className="p-0 m-0"></Grid>
        <Grid xs={12} sm={6} lg={4} item className="p-0 m-0"></Grid>
        <Grid xs={12} sm={6} lg={4} item className="p-0 m-0"></Grid>
        <Grid xs={6} sm={6} lg={2} item>
          <Button onClick={formik?.handleSubmit}>Submit</Button>
        </Grid>
        <Grid xs={6} sm={6} lg={2} item>
          <Button buttontypes="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default UpdateUser;

import React from "react";
import { styled } from "styled-components";
import Logo from "../../assets/images/saharaText.png";
import Home from "../../assets/images/dashboard.svg";

const Dashboard = () => {
  return (
    <div className="page-wrapper">
      <div
        className="content"
        style={{
          padding: "0 !important",
          height: "100%",
        }}
      >
        <StyledDashboard>
          <img src={Logo} alt="Logo" className="logo-image" />
          <h3>Welcome to Sahara National Computers Co.</h3>
          <img src={Home} alt="Home" className="home-image" />
        </StyledDashboard>
      </div>
    </div>
  );
};

export default Dashboard;

export const StyledDashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 350px;
  padding-right: 350px;
  h3 {
    font-size: 22px;
    color: #082857;
    margin-top: 20px;
    text-align: center;
  }
  .home-image {
    margin-top: 30px;
  }
  @media (max-width: 1280px) {
    padding-left: 150px;
    padding-right: 150px;
  }
  @media (max-width: 991px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

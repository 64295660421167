import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { debounce, uniqBy } from "lodash";
import { styled } from "styled-components";

type IProps = {
  placeholder?: string;
  fetchList: any;
  onHandleChange: any;
  defaultValue?: any;
  value?: any;
  disabled?: boolean;
};

const ProductSearchableDropdown = ({
  placeholder = "Search",
  fetchList,
  onHandleChange,
  defaultValue,
  value,
  disabled = false,
}: IProps) => {
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const fetchOptions = useCallback(async () => {
    if (options?.length < totalCount || options?.length === 0) {
      setLoading(true);
      try {
        const response = await fetchList({
          page,
          isActive: true,
          perPage: 100,
        });
        setTotalCount(response?.totalCount);
        const newOptions = response.data?.map((option: any) => ({
          label: option?.name,
          value: option?.id,
        }));
        setOptions((prevOptions: any) =>
          uniqBy([...prevOptions, ...newOptions], "value")
        );
        setPage(page + 1);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [page, fetchList, options, totalCount]);

  const handleInputChange = debounce((value) => {
    setSearch(value);
  }, 1000);

  useEffect(() => {
    fetchOptions();
  }, [search, fetchOptions]);
  return value ? (
    <StyledSelect
      onInputChange={handleInputChange}
      options={options}
      isLoading={loading}
      defaultValue={defaultValue}
      onMenuScrollToBottom={fetchOptions}
      onChange={onHandleChange}
      placeholder={placeholder}
      isSearchable
      isDisabled={disabled}
      value={{
        value: value,
        label: defaultValue,
      }}
    />
  ) : (
    <StyledSelect
      onInputChange={handleInputChange}
      options={options}
      isLoading={loading}
      defaultValue={defaultValue}
      onMenuScrollToBottom={fetchOptions}
      onChange={onHandleChange}
      placeholder={placeholder}
      isSearchable
      isDisabled={disabled}
    />
  );
};

export default ProductSearchableDropdown;

export const StyledSelect = styled(Select)`
  height: 40px;
`;

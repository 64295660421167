import { toast } from "react-toastify";
import axiosInstance, { axiosFileUpload } from "./axios.interceptors";
import { isArray, pickBy } from "lodash";

export const tableServices = async ({ url, page, rowsPerpage }: any) => {
  try {
    const res = await axiosInstance().get(`${url}?page=${page}&size=${rowsPerpage}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const creatUser = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`user`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchUserList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`user?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

//update user
export const updateUser = async (credentials: any, userId: string) => {
  try {
    const res = await axiosInstance().patch(`user/${userId}`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const createJobCard = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`job-cards`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchJobList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    field: query?.field || "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
    status: query?.status,
    serviceType: query?.serviceType,
    type: query?.type,
    userId: query?.userId,
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`job-cards?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchJobCardDetials = async (id: string) => {
  try {
    const res = await axiosInstance().get(`job-cards/${id}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const createJobCardComments = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`job-card-comments`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const updateJobCardDetails = async (id: string, credentials: any) => {
  return await axiosInstance()
    .patch(`job-cards/${id}`, credentials)
    .then((response) => {
      if (response) {
        return Promise.resolve(response?.data);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getUserProfile = async () => {
  try {
    const res = await axiosInstance().get(`user/profile`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    // toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const creatCustomer = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`customer`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchCustomerList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`customer?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchJobCardCommentsList = async (query: any, jobcardId: string) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`job-card-comments/${jobcardId}?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fetchContactList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    field: "createdAt",
    sort: query?.sort ? "ASC" : "DESC",
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`contact?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const fileUploadService = async (file: any) => {
  const Obj = {
    file,
  };
  try {
    const res = await axiosFileUpload().post(`upload`, Obj);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const createRemainder = async (credentials: any) => {
  try {
    const res = await axiosInstance().post(`reminder`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const getRemainderList = async (query: any) => {
  const formatQuery = {
    page: query?.page,
    perPage: query?.perPage,
    search: query?.search,
    startDate: query?.startDate,
    endDate: query?.endDate,
  };
  const removedNullKeys = pickBy(formatQuery);
  const makeUrl = Object.entries(removedNullKeys)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((_roles) => `${key}=${_roles}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
  try {
    const res = await axiosInstance().get(`reminder?${makeUrl}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const updateCustomer = async (credentials: any, customerId: string) => {
  try {
    const res = await axiosInstance().patch(`customer/${customerId}`, credentials);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const deleteCustomer = async (customerId: string) => {
  try {
    const res = await axiosInstance().delete(`customer/${customerId}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};
//delete user
export const deleteUser = async (userId: string) => {
  try {
    const res = await axiosInstance().delete(`user/${userId}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const deleteRemainder = async (reminderId: string) => {
  try {
    const res = await axiosInstance().delete(`reminder/${reminderId}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

export const deleteJobCard = async (jobcardId: string) => {
  try {
    const res = await axiosInstance().delete(`job-cards/${jobcardId}`);
    return Promise.resolve({
      ...res?.data,
    });
  } catch (error: any) {
    if (isArray(error?.data?.message)) {
      error?.data?.message?.map((message: string) => {
        return toast.error(message);
      });
      return Promise.reject(error);
    }
    toast.error(error?.data?.message || error?.data?.message[0]);
    return Promise.reject(error);
  }
};

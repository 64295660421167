import React from "react";
import Button from "../Common/Button";
import styled from "styled-components";
import InputField from "../Common/Input";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <StyledResetPassword className="login-wrapper">
      <div className="login-content">
        <div className="login-userset">
          <div className="login-logo">LOGO</div>
          <div className="login-userheading">
            <h3>Reset Password</h3>
            <h4>Reset your password, login to your account</h4>
          </div>
          <label>Password</label>
          <InputField placeholder="Enter your password" type="password" />
          <label>Confirm Password</label>
          <InputField type="password" placeholder="Confirm your password" />
          <div
            className="form-login"
            onClick={() => navigate("/crm-auth", { replace: true })}
          >
            <h4 className="form-forgot">Go Back</h4>
          </div>
          <Button
            onClick={() => navigate("/crm-auth", { replace: true })}
            buttontypes="primary"
          >
            Reset Password
          </Button>
        </div>
      </div>
      <div className="login-img">
        <img
          src="https://preadmin.dreamguystech.com/html/pos/template/assets/img/login.jpg"
          alt="img"
        />
      </div>
    </StyledResetPassword>
  );
};

export default ResetPassword;

const StyledResetPassword = styled.div`
  .form-forgot {
    font-size: 1rem;
    color: var(--primary);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

import React, { useCallback, useEffect, useState } from "react";
import { fetchJobCardDetials } from "../../../services/private.services";

const useDraweHook = (getList: (loader?: boolean) => void) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [jobId, setJobId] = useState("");
  const [jobDetails, setJobDetails] = useState({});
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenDrawer(open);
    };

  const closeDrawer = () => {
    setOpenDrawer(false);
    getList(false);
  };
  const setJobAndOpenDrawer = useCallback((id: string) => {
    setOpenDrawer(true);
    setJobId(() => id);
  }, []);

  const getJobDetails = useCallback(async () => {
    const result = await fetchJobCardDetials(jobId);
    setJobDetails(result);
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      getJobDetails();
    }
  }, [jobId, getJobDetails]);

  useEffect(() => {
    if (!openDrawer) {
      setJobId("");
      setJobDetails({});
    }
  }, [openDrawer]);

  return {
    toggleDrawer,
    openDrawer,
    setJobAndOpenDrawer,
    jobDetails,
    closeDrawer,
    getJobDetails,
  };
};

export default useDraweHook;

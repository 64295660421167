import React from "react";
import { TablePagination } from "@mui/material";
import styled from "styled-components";

type IProps = {
  count?: number;
  rowsPerpage?: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onRowsPerPageChange?: any;
  page: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowsPerPageOptions?: number[];
};

const Pagination = ({
  count = 0,
  rowsPerpage = 10,
  page = 1,
  onPageChange,
  handleChangeRowsPerPage,
  rowsPerPageOptions
}: IProps) => {
  return (
    <TPagination
      rowsPerPageOptions={
        rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 100]
      }
      count={count}
      rowsPerPage={rowsPerpage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;

const TPagination = styled(TablePagination)`
  border-bottom: none !important;
  width: 10%;
  display: flex;
  justify-content: center;
  p {
    margin: 0;
  }
`;

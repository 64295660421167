import React from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { colors } from "../../../Theme/colors";

type Iprops = {
  currentImage?: string;
};

const Avatar = ({
  currentImage = "https://preadmin.dreamguystech.com/html/pos/template/assets/img/customer/customer5.jpg",
}: Iprops) => {
  return (
    <div className="profile-contentimg">
      <img src={currentImage} alt="img" id="blah" />
      <div className="profileupload">
        <input type="file" id="imgInp" />
        <span
          style={{
            background: colors.primary,
          }}
        >
          <DriveFileRenameOutlineIcon
            style={{
              color: "#fff",
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default Avatar;

import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DownArrow from "../../../assets/svg/DownArrow.svg";
import { getDeliveryNoteFormattedTime } from "../../../utils/utils";
import { usePurchaseOrderDetails } from "../../../store/purchaseOrder/purchaseOrder.selector";
import { StyledTable } from "../../Quote/components/styles";
import { DeliveredNotes } from "../../../utils/purchaseOrder";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginTop: "10px",
  borderRadius: "4px 5px 5px 5px",
  border: "1px solid #DDE2E7",
  background: "#FDFBFB",
  p: {
    color: "var(--primary-header)",
    fontFamily: "Nunito, sans-serif !important",
    fontWeight: 600,
  },
  "&:before": {
    display: "none",
  },
  ".MuiAccordionSummary-root": {
    borderRadius: "4px",
    background: "#fff",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <img
        src={DownArrow}
        style={{
          transform: "rotate(270deg)",
        }}
        alt="down arrow"
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  background: "#F9F9F9",
  marginTop: "4px",
}));

export default function CustomizedAccordions() {
  const { deliveryNotes } = usePurchaseOrderDetails();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div
      style={{
        maxHeight: "600px",
        overflowY: "scroll",
        color: "var(--primary-header)",
        fontFamily: "Nunito, sans-serif !important",
        fontWeight: 600,
      }}
    >
      {deliveryNotes?.deliveryNotes
        ?.filter(
          (notes: DeliveredNotes) => notes.deliveredProducts.length !== 0
        )
        .map((notes: DeliveredNotes) => {
          return (
            <Accordion
              expanded={expanded === notes.id}
              onChange={handleChange(notes.id)}
              key={notes.id}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>
                  {getDeliveryNoteFormattedTime(notes.date)}
                </Typography>
                <FileDownloadOutlinedIcon />
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    padding: "8px 0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Generated By : {notes.createdBy.name}
                </div>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Delivered Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notes?.deliveredProducts?.map((product) => {
                      return (
                        <tr key={product.productId}>
                          <td>
                            <div>{product?.productName}</div>
                          </td>
                          <td className="quantity-meta">
                            <div>
                              <div>{product.quantityDelivered}</div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </StyledTable>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}

import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { contactUs } from "../../../../services/public.services";
import { contactUsValidations } from "./validations";
import { useProduct } from "../../../../store/product/product.selector";
import { removeProduct } from "../../../../store/product/product.Slice";

const CreatUserForm = () => {
  const dispatch = useDispatch();
  const product = useProduct();
  return useFormik({
    initialValues: {
      email: "",
      name: "",
      phoneNumber: "",
      address: "",
      description: product?.hasSelectedProduct
        ? `Enquired about the product ${product?.selectedProduct}.`
        : "",
    },
    validationSchema: contactUsValidations,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      let dataObj = {
        ...values,
      };
      await contactUs(dataObj);
      dispatch(removeProduct());
      toast.success("Success");
      resetForm();
    },
  });
};

export default CreatUserForm;

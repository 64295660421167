import { Avatar, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAvatarName, stringToColor } from "../../../utils/utils";
import { AssigneeModalWrapper } from "../../JobCards/components/styles";
import useCustomerList from "../../Customers/CustomerList/useCustomerList";
import { Customer } from "../../Customers/types";

const AssignCustomerModal = ({
  handleClose,
  onSelect,
}: {
  handleClose: () => void;
  onSelect?: (user: Customer) => void;
}) => {
  const { userList, handleOnChangeSearch } = useCustomerList();
  // TODO: add loader intially
  return (
    <AssigneeModalWrapper className="input-wrapper">
      <CancelIcon className="close-icon" onClick={handleClose} />
      <h3>Select Customer</h3>
      <Grid mt={2} width={"100%"}>
        <input
          type="text"
          className="search-input"
          placeholder="Search..."
          onChange={handleOnChangeSearch}
        />
        <button className="search-button">Search</button>
      </Grid>
      <div className="assignee-height">
        {onSelect &&
          userList?.map((user: any) => {
            return (
              <Grid
                mt={2}
                display="flex"
                gap={2}
                alignItems="center"
                p={1}
                borderRadius={1}
                borderBottom="1px solid #eaeaea"
                className="assignee-list"
                key={user?.id}
                onClick={() => onSelect(user)}
              >
                <Avatar sx={{ bgcolor: stringToColor(user?.name) }}>
                  {getAvatarName(user)}
                </Avatar>
                <div className="assginee-detail">
                  <span>{user?.name}</span>
                  <span className="name">{user?.email}</span>
                </div>
              </Grid>
            );
          })}
        {userList?.length === 0 && (
          <Grid
            mt={2}
            display="flex"
            gap={2}
            alignItems="center"
            p={1}
            borderRadius={1}
            borderBottom="1px solid #eaeaea"
            className="assignee-list"
          >
            No Customer found
          </Grid>
        )}
      </div>
    </AssigneeModalWrapper>
  );
};

export default AssignCustomerModal;

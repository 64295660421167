import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TContainer } from "../../components/Common/Table";
import SearchInput from "../../components/Common/SearchInput";
import { MainWrapper, StyledHeader } from "../../components/styles";
import useListQuotation from "./hooks/useListQuotation";
import Button from "../../components/Common/Button";
import ListQuotes from "./components/ListQuotes";
import IModal from "../../components/Common/Modal/IModal";
import DeleteModal from "../../components/Common/DeleteModal/DeleteModal";

const Quote = () => {
  const navigate = useNavigate();
  const {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    closeDeleteModal,
    showDeleteModal,
    openDeleteModal,
    onHandleDelete,
    onSetQuoteId,
  } = useListQuotation();
  return (
    <MainWrapper>
      <TContainer>
        <Grid container justifyContent="space-between" mb={2} gap={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader style={{ marginRight: 8 }}>Quotes</StyledHeader>
            <SearchInput onChange={handleOnChangeSearch} />
          </Grid>
          <Grid xs={12} sm={3} lg={2} item justifyContent="flex-end">
            <Button onClick={() => navigate("/crm-auth/quote/create")}>
              {" "}
              Create Quote
            </Button>
          </Grid>
        </Grid>
        <ListQuotes
          loading={loading}
          list={list}
          totalCount={totalCount}
          pagination={pagination}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          onSetQuoteId={onSetQuoteId}
        />
      </TContainer>
      <IModal
        open={showDeleteModal}
        handleOpen={openDeleteModal}
        handleClose={closeDeleteModal}
        width={30}
      >
        <DeleteModal
          handleClose={closeDeleteModal}
          handleSubmit={onHandleDelete}
        />
      </IModal>
    </MainWrapper>
  );
};

export default Quote;

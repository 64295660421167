import { useFormik } from "formik";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { usePurchaseOrderDetails } from "../../../store/purchaseOrder/purchaseOrder.selector";
import { toast } from "react-toastify";
import {
  generateDeliveryNotes,
  getDeliveryNotesDetails,
} from "../../../services/quotation.services";
import { AppDispatch } from "../../../store/store";
export interface Product {
  productId: string;
  quantityDelivered: string;
}
interface FormValues {
  products: Product[];
}

const DeliveryNotesForm = () => {
  const { purchaseOrder } = usePurchaseOrderDetails();
  const dispatch = useDispatch<AppDispatch>();
  const products = useMemo(() => {
    if (purchaseOrder?.quotationProductDetails) {
      return purchaseOrder?.quotationProductDetails?.map((product: any) => ({
        productId: product?.productDetails?.id,
        productName: product?.productDetails?.name,
        quantityRequired: product?.quantityRequired,
        quantityDelivered: 0,
      }));
    }
    return [];
  }, [purchaseOrder?.quotationProductDetails]);
  return useFormik<FormValues>({
    initialValues: {
      products: products,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      products: Yup.array().of(
        Yup.object().shape({
          productId: Yup.string(),
          productName: Yup.string(),
          // quantityDelivered: Yup.number().when(
          //   ["productId"],
          //   (productId, schema) => {
          //     return schema.test({
          //       name: "deliveringGreaterThanRequired",
          //       test: function (quantityDelivered) {
          //         console.log(quantityDelivered, "the ", productId);
          //         return this.createError({
          //           path: "quantityDelivered",
          //           message:
          //             "Delivering should not be greater than Quantity Required",
          //         });
          //       },
          //     });
          //   }
          // ),
          quantityRequired: Yup.number(),
          quantityDelivered: Yup.number(),
        })
      ),
    }),
    onSubmit: async (values, formikHelpers) => {
      const products = values?.products
        .filter((product: Product) => product.quantityDelivered)
        .map((product: Product) => ({
          productId: product.productId,
          quantityDelivered: product.quantityDelivered,
        }));

      const deliveryNoteObj = {
        quotationId: purchaseOrder.id,
        deliveryNoteGenerated: true,
        products,
      };
      if (products.length === 0) return;
      await generateDeliveryNotes(deliveryNoteObj).then(() => {
        toast.success("Delivery note generated successfully");
      });
      await dispatch(getDeliveryNotesDetails(purchaseOrder.id));
      formikHelpers.resetForm();
    },
  });
};

export default DeliveryNotesForm;

import styled from "@emotion/styled";

export const LogoSliderContainer = styled.div`
  .headingWrapper {
    width: 100%;
    display:flex;
    justify-content: center;
    h2 {
      color: #2b3b7c;
      text-align: center;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 45px;
      margin-top: 60px;
      margin-bottom: 0px;
      width: 368px;
    }
  }
  .sliderCls {
    padding: 65px;
    align-items: center;
    height: 187px;
  }
  &.slick-slide-item {
    padding: 0 10px;
  }
`;

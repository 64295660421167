import axios from "axios";
import { asyncLocalStorage } from "./utlis";

const axiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
      Authorization: localStorage.getItem("accessToken")
        ? `Bearer ${localStorage.getItem("accessToken")}`
        : "",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    async ({ response }) => {
      console.log(window.location.pathname);
      if (
        (response?.status === 401 || response?.status === 500) &&
        window.location.pathname !== "/"
      ) {
        asyncLocalStorage.removeItem();
        return (window.location.href = "/crm-auth");
      }
      return Promise.reject(response);
    }
  );
  return axiosInstance;
};

export default axiosInstance;

export const axiosFileUpload = (history = null) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      "content-type": "multipart/form-data",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    ({ response }) => {
      return Promise.reject(response);
    }
  );
  return axiosInstance;
};

import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../auth";
import { menuItems, Module } from "../Layout/Sidebar";

type ProtectedRouteProps = {
  children: ReactNode;
  module: Module;
};
const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, module }) => {
  const { user } = useAuth();
  const userRole = user.role;
  const menu = menuItems.find((menu) => menu.module === module);
  const canAccess = !!menu?.roles.find((role) => role === userRole);
  return <>{canAccess ? children : <Navigate to="/crm-auth/" replace />}</>;
};

export default ProtectedRoute;

import OurServicesItems from "./OurServicesItems";
import { OurServicesStyled } from "./OurServicesStyled";
import { ourServicesConstant } from "./constants";
import { ServiceType } from "./types";

const OurServices = () => {
  return (
    <OurServicesStyled id="services">
      <div className="ourServicesContainer">
        <h1>Our Services</h1>
        <div className="serviceItemsContainer">
          {ourServicesConstant?.map((items: ServiceType) => {
            return <OurServicesItems services={items} key={items.id} />;
          })}
        </div>
      </div>
    </OurServicesStyled>
  );
};

export default OurServices;

import * as Yup from "yup";
import {
  title,
  description,
  startDate,
  endDate,
} from "../../utils/validations";

export const remainderValidations = Yup.object({
  title,
  description,
  startDate,
  endDate,
});

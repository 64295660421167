import React, { useCallback, useState } from "react";
import { Grid, Switch } from "@mui/material";
import InputField from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import { UpdateProduct } from "./CreateInventory.form";
import { StyledError } from "../../../components/styles";
import { getInventoryTypeName } from "../utils";
import ProductSearchableDropdown from "./ProductSearchableDropdown";
import {
  fetchBrandList,
  fetchCategoryList,
} from "../../../services/inventory.services";
import { AvatarWrapper } from "./CreateProduct";

type IProps = {
  handleClose: () => void;
  type: string;
  inventory: any;
  getList: () => void;
};

const EditProduct = ({ handleClose, type, inventory, getList }: IProps) => {
  const formik = UpdateProduct(type, handleClose, inventory, getList);

  const [productImage, setProductImage] = useState(null);

  const onImageChange = useCallback(
    (e: any) => {
      setProductImage(e.target.files);
      formik?.setFieldValue("file", e.target.files[0]);
    },
    [formik]
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>{getInventoryTypeName(type, "Edit")}</h4>
        <AvatarWrapper>
          <img
            src={
              productImage
                ? URL.createObjectURL(productImage[0])
                : formik?.values?.imageUrl
                ? formik?.values?.imageUrl
                : "https://www.pngitem.com/pimgs/m/579-5798581_image-placeholder-circle-hd-png-download.png"
            }
            alt="user avatar"
            width={100}
            height={100}
          />
          <input type="file" name="" id="" onChange={onImageChange} />
        </AvatarWrapper>
      </div>

      <Grid spacing={3} container>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Name</label>
          <InputField formik={formik} name="name" />
          <StyledError>
            {/* {formik && formik.touched?.name && formik.errors["name"]} */}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Description</label>
          <InputField formik={formik} name="description" />
          <StyledError>
            {/* {formik.touched?.description && formik.errors["description"]} */}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Brand</label>
          <ProductSearchableDropdown
            placeholder="Search brand"
            fetchList={fetchBrandList}
            key="brand"
            onHandleChange={(data: any) => {
              formik?.setFieldValue("brandId", data?.value);
            }}
            defaultValue={formik?.values?.defaultBrand}
          />
          <StyledError>
            {/* {formik.touched?.brandId && formik.errors["brandId"]} */}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Category</label>
          <ProductSearchableDropdown
            placeholder="Search Category"
            fetchList={fetchCategoryList}
            key="category"
            onHandleChange={(data: any) => {
              formik?.setFieldValue("categoryId", data?.value);
            }}
            defaultValue={formik?.values?.defaultCategory}
          />
          <StyledError>
            {/* {formik.touched?.categoryId && formik.errors["categoryId"]} */}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Model No</label>
          <InputField formik={formik} name="modelNo" />
          <StyledError>
            {/* {formik.touched?.modelNo && formik.errors["modelNo"]} */}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Serial Number</label>
          <InputField formik={formik} name="serialNo" />
          <StyledError>
            {/* {formik.touched?.serialNo && formik.errors["serialNo"]} */}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={12} lg={6} item>
          <label>Active</label>
          <Switch
            value={formik?.values?.isActive}
            name="isActive"
            onChange={formik?.handleChange}
          />
        </Grid>

        <Grid xs={12} sm={12} lg={12} item container spacing={3}>
          <Grid xs={12} sm={12} lg={6} item>
            <Button buttontypes="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>{" "}
          <Grid xs={12} sm={12} lg={6} item>
            <Button
              onClick={formik?.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProduct;

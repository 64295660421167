import React from "react";
import { WhatWeDoSectionStyled } from "./WhatWeDoSectionStyled";
import WhatWeDoImage from "../../../../assets/images/whatWeDoImage.png";
import WhatWeDoItems from "./WhatWeDoItems";
import { whatWeDoItems } from "./constants";

const WhatWeDoSection = () => {
  return (
    <WhatWeDoSectionStyled>
      <div className="whatWeDoSectionContainer">
        <h2>What we are</h2>
        <div className="whatweDoWrapper">
          <div>
            <img src={WhatWeDoImage} alt="WhatWeDoImage" />
          </div>
          <div>
            {whatWeDoItems?.map((items) => {
              return (
                <WhatWeDoItems
                  title={items.title}
                  description={items.description}
                  ImageIcon={items.image}
                  key={items.id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </WhatWeDoSectionStyled>
  );
};

export default WhatWeDoSection;

import * as Yup from "yup";
import {
  firstName,
  email,
  password,
  phoneNumber,
  role,
  gender,
} from "../../utils/validations";

export const createUserValidations = Yup.object({
  firstName,
  email,
  password,
  phoneNumber,
  role,
  gender,
});

import React from "react";
import { Grid } from "@mui/material";
import { TContainer } from "../../components/Common/Table";
import SearchInput from "../../components/Common/SearchInput";
import { MainWrapper, StyledHeader } from "../../components/styles";
import useListQuotation from "../Quote/hooks/useListQuotation";
import ListQuotes from "../Quote/components/ListQuotes";

const PurchaseOrders = () => {
  const {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
  } = useListQuotation(true);
  return (
    <MainWrapper>
      <TContainer>
        <Grid container justifyContent="space-between" mb={2} gap={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader style={{ marginRight: 8 }}>
              Purchase Orders
            </StyledHeader>
            <SearchInput onChange={handleOnChangeSearch} />
          </Grid>
        </Grid>
        <ListQuotes
          loading={loading}
          list={list}
          totalCount={totalCount}
          pagination={pagination}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </TContainer>
    </MainWrapper>
  );
};

export default PurchaseOrders;

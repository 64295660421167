import { FC } from "react";
import { Page, Document, View } from "@react-pdf/renderer";
import { styles } from "./styles";
import { FormValues } from "../../containers/Quote/forms/EditQuotation.form";
import { Address, InvoiceDetails, Table, Title, Signature, Footer } from "./Elements";

type InvoiceProps = {
  values: FormValues;
};
const Invoice: FC<InvoiceProps> = ({ values }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Title />
          <InvoiceDetails
            invoiceNumber={values.userRefNo}
            name={values?.quotedCustomer?.name ?? ""}
            date={values?.createdAt}
          />
          <Address address={values?.quotedCustomer?.address ?? ""} />
          <Table products={values.products} />
          <Signature />
        </View>
        <View>
          <Footer />
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;

import React from "react";
import { HeroSectionStyled } from "./HeroSectionStyled";
import HeroImage from "../../../../assets/images/HeroImage.svg";
import Badge from "../Badge/Badge";

const HeroSection = () => {
  return (
    <HeroSectionStyled>
      <div className="heroSectionContainer">
        <div>
          <h1 className="heroTitle">Your satisfaction is our inspiration.</h1>
          <p className="heroParagraph">
            Welcome to our one-stop technology hub, where expertise meets
            excellence. Your Authorized Service Center and Tech Sales Hub –
            Elevate Your Tech Experience with Expert Support and Quality
            Products.
          </p>
        </div>
        <div className="imageContainer">
          <img src={HeroImage} alt="HeroImage" />
        </div>
        <Badge />
      </div>
    </HeroSectionStyled>
  );
};

export default HeroSection;

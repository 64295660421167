import React from "react";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { MainWrapper } from "../../components/styles";
import Modal from "../../components/Common/Modal";
import useRemainderHook from "./useRemainderHook";
import CreateRemainder from "./components/CreateRemainder";
import RemainderDetail from "./components/RemainderDetail";

const localizer = momentLocalizer(moment);

const Remainder: React.FC = () => {
  const {
    openModal,
    handleOpen,
    handleClose,
    handleDateChanges,
    dates,
    event,
    getList,
    openDetailModal,
    handleDetailModalOpen,
    handleDetailModalClose,
    setEvents,
    eventDetail,
    changeEventOnMonthChange,
    deleteRemainderWithId,
  } = useRemainderHook();

  const handleSelect = ({ start, end }: { start: Date; end: Date }) => {
    handleDateChanges({
      start,
      end,
    });
    handleOpen();
  };

  const handleEventClick = (event: Event) => {
    setEvents(event);
    handleDetailModalOpen();
  };

  const handleNavigate = (date: Date) => {
    changeEventOnMonthChange(date);
  };

  return (
    <MainWrapper>
      <div style={{ height: 700, width: "100%" }}>
        <Calendar
          localizer={localizer}
          events={event}
          startAccessor="start"
          endAccessor="end"
          onSelectSlot={handleSelect}
          selectable
          onSelectEvent={handleEventClick}
          views={["month"]}
          onNavigate={handleNavigate}
        />
      </div>
      <Modal
        open={openModal}
        width={50}
        handleOpen={handleOpen}
        handleClose={handleClose}
      >
        <CreateRemainder
          handleClose={handleClose}
          dates={dates}
          getList={getList}
        />
      </Modal>
      <Modal
        open={openDetailModal}
        width={40}
        handleOpen={handleDetailModalOpen}
        handleClose={handleDetailModalClose}
      >
        <RemainderDetail
          event={eventDetail}
          deleteRemainderWithId={deleteRemainderWithId}
        />
      </Modal>
    </MainWrapper>
  );
};

export default Remainder;

import { Grid } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ProductSearchableDropdown from "../../Inventory/CreateInventory/ProductSearchableDropdown";
import { fetchProductList } from "../../../services/inventory.services";
import { StyledError } from "../../../components/styles";
import InputField from "../../../components/Common/Input";
import { PriceWrapper, StyledDeleteIconWrapper, StyledTable } from "./styles";
import Button from "../../../components/Common/Button";
import { useMemo } from "react";
import { Product } from "../forms/CreateQuotation.form";

const QuoteProduct = ({
  formik,
  handleAddElement,
  handleRemoveProduct,
  isDisabled = false,
}: {
  formik: any;
  handleAddElement: () => void;
  handleRemoveProduct: (data: number) => void;
  isDisabled?: boolean;
}) => {
  const productValues = useMemo(() => {
    return formik?.values?.products;
  }, [formik?.values?.products]);

  const getTotalPrice = (value1: string | number, value2: string | number) => {
    return Number((Number(value1) * Number(value2)).toFixed()) || 0;
  };
  return (
    <>
      {" "}
      <Grid spacing={6} container>
        <Grid xs={12} sm={12} lg={12} item>
          <div className="billing-details">
            <h3>Add Items</h3>
            {!isDisabled && (
              <Button onClick={handleAddElement} buttontypes="secondary">
                Add Item
              </Button>
            )}
          </div>
          <Grid
            xs={12}
            sm={12}
            lg={12}
            item
            className="mt-2"
            display="flex"
            justifyContent="flex-end"
            container
          >
            <Grid xs={12} sm={12} lg={12} item></Grid>
            <Grid xs={12} sm={12} lg={12} item>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productValues?.map((product: Product, index: number) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <ProductSearchableDropdown
                            placeholder="Search Product"
                            fetchList={fetchProductList}
                            key={product.quantityRequired + index}
                            onHandleChange={(data: any) => {
                              formik.setFieldValue(
                                `products[${index}].productId`,
                                data?.value
                              );
                              formik.setFieldValue(
                                `products[${index}].productName`,
                                data?.label
                              );
                            }}
                            value={product?.productId}
                            defaultValue={product?.productName}
                            disabled={isDisabled}
                          />
                          <StyledError>
                            {formik.touched.products?.[index]?.productId &&
                              (formik.errors.products as any)?.[index]
                                ?.productId}
                          </StyledError>
                        </div>
                      </td>
                      <td className="product-meta">
                        <div>
                          <div>
                            <InputField
                              type="number"
                              name={`products[${index}].quantityRequired`}
                              handleChange={formik?.handleChange}
                              value={product.quantityRequired}
                              handleBlur={formik?.handleBlur}
                              disabled={isDisabled}
                            />
                          </div>
                          <StyledError>
                            {formik.touched.products?.[index]
                              ?.quantityRequired &&
                              (formik.errors.products as any)?.[index]
                                ?.quantityRequired}
                          </StyledError>
                        </div>
                      </td>
                      <td className="product-meta">
                        <div>
                          <InputField
                            type="number"
                            name={`products[${index}].quotedPrice`}
                            handleChange={formik?.handleChange}
                            value={product.quotedPrice}
                            handleBlur={formik?.handleBlur}
                            disabled={isDisabled}
                          />
                        </div>
                        <StyledError>
                          {formik.touched.products?.[index]?.quotedPrice &&
                            (formik.errors.products as any)?.[index]
                              ?.quotedPrice}
                        </StyledError>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                          }}
                        >
                          {getTotalPrice(
                            product?.quotedPrice,
                            product?.quantityRequired
                          )}
                        </div>
                      </td>
                      <td className="pt-2">
                        <StyledDeleteIconWrapper
                          onClick={() =>
                            !isDisabled && handleRemoveProduct(index)
                          }
                        >
                          <DeleteForeverIcon
                            style={{
                              color: "#fff",
                            }}
                          />
                        </StyledDeleteIconWrapper>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
              <Grid
                xs={12}
                sm={12}
                lg={12}
                item
                className="pr-2"
                display="flex"
                justifyContent="flex-end"
                container
              >
                <Grid xs={12} sm={6} lg={4} item>
                  <PriceWrapper className="mt-4">
                    <span>SubTotal:</span>
                    <span>
                      KWD{" "}
                      {productValues?.reduce(
                        (sum: number, product: Product) => {
                          return (
                            sum +
                            Number(
                              (
                                Number(product.quotedPrice) *
                                Number(product.quantityRequired)
                              ).toFixed(2)
                            )
                          );
                        },
                        0
                      )}
                    </span>
                  </PriceWrapper>
                  <PriceWrapper>
                    <span>Tax:</span>
                    <span>0</span>
                  </PriceWrapper>
                  <hr />
                  <PriceWrapper>
                    <span>Total:</span>
                    <span>
                      KWD{" "}
                      {productValues?.reduce(
                        (sum: number, product: Product) => {
                          return (
                            sum +
                            Number(
                              (
                                Number(product.quotedPrice) *
                                Number(product.quantityRequired)
                              ).toFixed(2)
                            )
                          );
                        },
                        0
                      )}
                    </span>
                  </PriceWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default QuoteProduct;

import styled from "styled-components";

export const PolicyContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  .aboutUs {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h3 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
    }
    p {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      line-height: 32px;
    }
  }
  .aboutValues {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    h3 {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
    }
    p {
      color: #2b3b7c;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      line-height: 32px;
    }
  }
`;

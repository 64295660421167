import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import {
  deleteQuotations,
  fetchQuotationList,
} from "../../../services/quotation.services";
import { toast } from "react-toastify";

const useListQuotation = (isApproved = false) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    search: "",
    isApproved: isApproved,
  });
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      perPage: +event.target.value,
    }));
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: page + 1,
      }));
    },
    []
  );

  // eslint-disable-next-line
  const handleOnChangeSearch = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        search: event.target.value,
      }));
    }, 500),
    []
  );

  const getList = useCallback(
    async (loader = true) => {
      if (loader) {
        setLoading(true);
      }
      try {
        const result = await fetchQuotationList(pagination);
        setList(result?.data);
        setTotalCount(result?.totalCount);
        if (loader) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [pagination]
  );

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const onSetQuoteId = (id: string) => {
    setQuoteId(id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onHandleDelete = useCallback(async () => {
    try {
      await deleteQuotations(quoteId);
      getList();
      closeDeleteModal();
      toast.success("Quote deleted", { autoClose: 1500 });
    } catch (error) {
      console.log(error);
    }
  }, [quoteId, getList]);

  useEffect(() => {
    getList();
  }, [getList, pagination]);

  return {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    getList,
    closeDeleteModal,
    showDeleteModal,
    openDeleteModal,
    onHandleDelete,
    onSetQuoteId,
  };
};

export default useListQuotation;

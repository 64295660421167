import { Grid } from "@mui/material";
import InputField from "../../../components/Common/Input";
import AssignCustomerModal from "./AssignCustomerModal";
import { StyledError } from "../../../components/styles";
import { Customer } from "../../Customers/types";
import IModal from "../../../components/Common/Modal/IModal";
import Button from "../../../components/Common/Button";

const QuoteAddress = ({
  formik,
  onClose,
  onSelectCustomer,
  open,
  customerDetails,
  setOpen,
  showSelectBtn = true,
}: {
  formik: any;
  onClose: () => void;
  onSelectCustomer?: (user: Customer) => void;
  open: boolean;
  customerDetails: Customer;
  setOpen: (flag: boolean) => void;
  showSelectBtn?: boolean;
}) => {
  return (
    <>
      <Grid spacing={6} container>
        <Grid xs={12} sm={6} lg={6} item>
          <div className="billing-details">
            <h3>Billing To</h3>
            {showSelectBtn && (
              <div>
                <Button onClick={() => setOpen(true)} buttontypes="secondary">
                  Select Customer
                </Button>
                <StyledError>{formik.errors["customerId"]}</StyledError>
              </div>
            )}
          </div>
          <Grid xs={12} sm={12} lg={12} item className="mt-2">
            <InputField
              placeholder="Customer Name"
              name="name"
              value={customerDetails?.name}
              disabled
            />
          </Grid>
          <Grid xs={12} sm={12} lg={12} item className="mt-2">
            <InputField
              name="email"
              placeholder="Email"
              value={customerDetails?.email}
              disabled
            />
          </Grid>
          <Grid xs={12} sm={12} lg={12} item className="mt-2">
            <InputField
              name="deliveryAddress"
              placeholder="Delivery Address"
              value={customerDetails?.deliveryAddress}
              disabled
              multiline
            />
          </Grid>
          {showSelectBtn && (
            <IModal open={open} handleClose={onClose} width={30}>
              <AssignCustomerModal
                handleClose={onClose}
                onSelect={onSelectCustomer}
              />
            </IModal>
          )}
        </Grid>
        <Grid xs={12} sm={6} lg={6} item>
          <div className="billing-details flex-end">
            <h3>Billing From</h3>
          </div>
          <Grid xs={12} sm={12} lg={12} item className="mt-2">
            <InputField name="name" value="SAHARA NATIONAL CO" disabled />
          </Grid>
          <Grid xs={12} sm={12} lg={12} item className="mt-2">
            <InputField
              name="description"
              value="sales@saharacomputers.com"
              disabled
            />
          </Grid>
          <Grid xs={12} sm={12} lg={12} item className="mt-2">
            <InputField
              name="description"
              value={`Bin Khaldoun Street Hawally,\nMishref,\nKuwait03197`}
              disabled
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default QuoteAddress;

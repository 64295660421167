import React from "react";
import { HowWeWorkStyled } from "./HowWeWorkStyled";
import HowWeWorkImg from "../../../../assets/images/HowWeWork.png";

const HowWeWork = () => {
  return (
    <HowWeWorkStyled id="work">
      <div className="howWeWorkContainer">
        <div className="howWeWorkWrapper">
          <div className="howWeWorkBox">
            <h1>How We Work</h1>
            <p>Let's discuss what projects you have in mind</p>
            <a href="#contactUs">Contact Us</a>
          </div>
          <img src={HowWeWorkImg} alt="How we work" className="howWeWorkImage"/>
        </div>
      </div>
    </HowWeWorkStyled>
  );
};

export default HowWeWork;

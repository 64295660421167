import { useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IModal from "../../../components/Common/Modal/IModal";
import { getFormattedDate } from "../../../utils/utils";
import DeleteModal from "../../../components/Common/DeleteModal/DeleteModal";

const RemainderDetail = ({
  event,
  deleteRemainderWithId,
}: {
  event: any;
  deleteRemainderWithId: any;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const onHandleDelete = () => {
    deleteRemainderWithId(event?.id);
  };
  return (
    <>
      <h4
        style={{
          textAlign: "center",
          margin: "10px 0px",
        }}
      >
        Event Detail
      </h4>
      <div
        onClick={() => openDeleteModal()}
        style={{
          color: "red",
          cursor: "pointer",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <DeleteForeverIcon />
      </div>
      <p>
        <span>Title : </span> <b>{event?.title}</b>
      </p>
      <p>
        <span>Description : </span>
        <b> {event?.description}</b>
      </p>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          Start Date : <b>{getFormattedDate(event?.startDate)}</b>{" "}
        </span>
        <span>
          End Date :<b>{getFormattedDate(event?.endDate)}</b>{" "}
        </span>
      </p>
      <IModal
        open={showDeleteModal}
        handleOpen={openDeleteModal}
        handleClose={closeDeleteModal}
        width={30}
      >
        <DeleteModal
          handleClose={closeDeleteModal}
          handleSubmit={onHandleDelete}
        />
      </IModal>
    </>
  );
};

export default RemainderDetail;

import React from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Modal from "../../components/Common/Modal";
import CreateJobCard from "./CreateJobCard";
import ListJobCards from "./ListJobCards";
import useJobList from "./ListJobCards/useJobList";
import { TContainer } from "../../components/Common/Table";
import SearchInput from "../../components/Common/SearchInput";
import { MainWrapper, StyledHeader } from "../../components/styles";
import Dropdown from "../../components/Common/Dropdown";
import { jobStatus, jobTypes, serviceTypes } from "../../utils/jobCards";
import IModal from "../../components/Common/Modal/IModal";
import DeleteModal from "../../components/Common/DeleteModal/DeleteModal";

const JobCards = () => {
  const {
    loading,
    list,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    handleOpen,
    handleClose,
    open,
    getList,
    showFilter,
    handleShowFilter,
    handleFieldSort,
    handleFilter,
    closeDeleteModal,
    showDeleteModal,
    openDeleteModal,
    onHandleDelete,
    onSetJobCardId,
  } = useJobList();
  return (
    <MainWrapper>
      <TContainer>
        <Grid container justifyContent="space-between" mb={2} gap={2}>
          <Grid
            xs={12}
            sm={6}
            lg={6}
            item
            justifyContent={{
              lg: "flex-start",
              xs: "space-between",
            }}
            display="flex"
            alignItems={"center"}
          >
            <StyledHeader style={{ marginRight: 8 }}>Job Cards</StyledHeader>
            <SearchInput onChange={handleOnChangeSearch} />
            <div
              style={{
                cursor: "pointer",
                height: "38px",
                background: "var(--secondary)",
                width: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "8px",
                borderRadius: "4px",
                color: "white",
              }}
              onClick={handleShowFilter}
            >
              {showFilter ? <FilterAltOffIcon /> : <FilterAltIcon />}
            </div>
          </Grid>
          <Grid xs={12} sm={3} lg={2} item justifyContent="flex-end">
            <Modal
              title="Create Job Card"
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
            >
              <CreateJobCard handleClose={handleClose} getList={getList} />
            </Modal>
          </Grid>
        </Grid>
        {showFilter && (
          <Grid xs={12} sm={12} lg={12} container spacing={3} mb={2}>
            <Grid xs={12} sm={6} lg={4} item>
              <Dropdown
                options={serviceTypes}
                name="serviceType"
                selectText="Filter by Service Type"
                value={pagination.serviceType}
                onChange={(event: SelectChangeEvent) =>
                  handleFilter(event.target.name, event.target.value)
                }
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <Dropdown
                options={jobTypes}
                name="type"
                selectText="Filter by Job Type"
                value={pagination.type}
                onChange={(event: SelectChangeEvent) =>
                  handleFilter(event.target.name, event.target.value)
                }
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4} item>
              <Dropdown
                options={jobStatus}
                name="status"
                selectText="Filter by Status"
                value={pagination.status}
                onChange={(event: SelectChangeEvent) =>
                  handleFilter(event.target.name, event.target.value)
                }
              />
            </Grid>
          </Grid>
        )}
        <ListJobCards
          loading={loading}
          list={list}
          totalCount={totalCount}
          pagination={pagination}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleOnChangeSearch={handleOnChangeSearch}
          getList={getList}
          handleFieldSort={handleFieldSort}
          onSetJobCardId={onSetJobCardId}
        />
      </TContainer>
      <IModal
        open={showDeleteModal}
        handleOpen={openDeleteModal}
        handleClose={closeDeleteModal}
        width={30}
      >
        <DeleteModal
          handleClose={closeDeleteModal}
          handleSubmit={onHandleDelete}
        />
      </IModal>
    </MainWrapper>
  );
};

export default JobCards;

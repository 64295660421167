import { styled } from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  .billing-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 200px;
    }
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  overflowX:scroll;
  thead {
    border-top: 1px solid #c7c8c9;
    border-bottom: 1px solid #c7c8c9;
    width: 100%;
    tr {
      text-transform: uppercase;
      font-weight: 900;
      color: var(--primary-header);
      font-family: "Nunito", sans-serif !important;
      th {
        padding: 8px !important;
        text-align: center;
      }
      th: first-child {
        text-align: left;
        width: 75%:
      }
    }
  }
  tbody {
    vertical-align:none !important;
    width: 100%;
    td {
      padding: 8px !important; 
    }
    tr {
      td:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      td: first-child{
        width: 60%
      }
    }
  }
  .product-meta {
    width: 100px !important;
    height: 40px !important;
  }
  .quantity-meta {
    min-width: 150px !important;
    width: 100%;
    height: 50px !important;
    text-align: center;
  }
  .price-center{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
`;

export const StyledDeleteIconWrapper = styled.div`
  background: var(--primary);
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span:first-child {
    font-size: 16px;
    font-weight: 700;
  }
`;

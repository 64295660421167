import React from "react";
import { Grid } from "@mui/material";
import InputField from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import {
  StyledError,
  StyledHeader,
  StyledLabel,
} from "../../../components/styles";
import UpdateCustomerForm from "./updateCustomer.form";

type IProps = {
  handleClose: () => void;
  getUserList: () => void;
  customerDetails: any;
};

const UpdateCustomer = ({
  handleClose,
  customerDetails,
  getUserList,
}: IProps) => {
  const formik = UpdateCustomerForm(handleClose, customerDetails, getUserList);
  return (
    <>
      <Grid container>
        <Grid xs={12} sm={6} lg={6} item justifyContent="flex-start">
          <StyledHeader style={{ marginBottom: 4 }}>
            Update Customer
          </StyledHeader>
        </Grid>
      </Grid>
      <Grid spacing={3} container>
        <Grid xs={12} sm={12} lg={12} item>
          <StyledLabel>Name</StyledLabel>
          <InputField formik={formik} name="name" />
          <StyledError>
            {formik.touched?.name && formik.errors["name"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={6} item>
          <StyledLabel>Email</StyledLabel>
          <InputField formik={formik} name="email" />
          <StyledError>
            {formik.touched?.email && formik.errors["email"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={6} item>
          <StyledLabel>Phone Number</StyledLabel>
          <InputField formik={formik} name="phoneNumber" />
          <StyledError>
            {formik.touched?.phoneNumber && formik.errors["phoneNumber"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={6} item>
          <StyledLabel>Address</StyledLabel>
          <InputField formik={formik} name="address" multiline />
          <StyledError>
            {formik.touched?.address && formik.errors["address"]}
          </StyledError>
        </Grid>
        <Grid xs={12} sm={6} lg={6} item>
          <StyledLabel>Delivery Address</StyledLabel>
          <InputField formik={formik} name="deliveryAddress" multiline />
          <StyledError>
            {formik.touched?.deliveryAddress &&
              formik.errors["deliveryAddress"]}
          </StyledError>
        </Grid>
        <Grid xs={12} container item spacing={2} justifyContent="flex-end">
          <Grid xs={12} sm={12} lg={6} item>
            <Button onClick={formik?.handleSubmit}>Submit</Button>
          </Grid>
          <Grid xs={12} sm={12} lg={6} item>
            <Button buttontypes="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateCustomer;

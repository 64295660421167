import { Grid } from "@mui/material";
import { StyledTable } from "../../Quote/components/styles";
import { DeliveredProduct } from "../../../utils/purchaseOrder";

const QuotedProducts = ({
  purchaseOrder,
  deliveredProducts,
}: {
  purchaseOrder: any;
  deliveredProducts: DeliveredProduct[];
}) => {
  return (
    <>
      <Grid spacing={6} container>
        <Grid xs={12} sm={12} lg={12} item>
          <Grid
            xs={12}
            sm={12}
            lg={12}
            item
            className="mt-2"
            display="flex"
            justifyContent="flex-end"
            container
          >
            <Grid xs={12} sm={12} lg={12} item></Grid>
            <Grid xs={12} sm={12} lg={12} item>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Required Qty</th>
                    <th>Delivered Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrder?.quotationProductDetails?.map(
                    (product: any, index: number) => (
                      <tr key={index}>
                        <td>
                          <div>{product?.productDetails?.name}</div>
                        </td>
                        <td className="quantity-meta">
                          <div>
                            <div>{product?.quantityRequired}</div>
                          </div>
                        </td>
                        <td className="quantity-meta">
                          <div>
                            {deliveredProducts
                              ? deliveredProducts?.find(
                                  (data: DeliveredProduct) =>
                                    data.productId === product.productId
                                )?.quantityDelivered || 0
                              : 0}{" "}
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </StyledTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default QuotedProducts;

import { useFormik } from "formik";
import { customerValidations } from "../validations";
import { updateCustomer } from "../../../services/private.services";
import { toast } from "react-toastify";
import { useMemo } from "react";

export interface CustomerValues {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  deliveryAddress: string;
  id?: string;
}

const UpdateCustomerForm = (
  handleClose: () => void,
  customerDetails: CustomerValues,
  getUserList: () => void
) => {
  const customerId = customerDetails?.id ?? "";
  const initialValues = useMemo(() => {
    return {
      name: customerDetails?.name ?? "",
      email: customerDetails?.email ?? "",
      phoneNumber: customerDetails?.phoneNumber ?? "",
      address: customerDetails?.address ?? "",
      deliveryAddress: customerDetails?.deliveryAddress ?? "",
    };
  }, [customerDetails]);

  return useFormik<CustomerValues>({
    initialValues: initialValues,
    validationSchema: customerValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const dataObj = {
        ...values,
      };
      await updateCustomer(dataObj, customerId);
      toast.success("Updated customer details");
      getUserList();
      handleClose();
    },
  });
};

export default UpdateCustomerForm;

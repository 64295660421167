export const jobTypes = [
  {
    id: "doa",
    name: "DOA (Dead on arrival)",
  },
  {
    id: "warranty",
    name: "Warranty",
  },
  {
    id: "out_of_warranty",
    name: "Out of Warranty",
  },
  {
    id: "consulting",
    name: "Consulting",
  },
  {
    id: "task",
    name: "Task",
  },
];

export const serviceTypes = [
  {
    id: "carry_in",
    name: "Carry in",
  },
  {
    id: "on_site",
    name: "On site",
  },
  {
    id: "na",
    name: "Others",
  },
];

export const gender = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
];

export const roles = [
  {
    id: "finance",
    name: "Finance",
  },
  {
    id: "technician",
    name: "Technician",
  },
  {
    id: "staff",
    name: "Staff",
  },
  {
    id: "admin",
    name: "Admin",
  },
];

export const inventoryTypes = {
  BRAND: "brand",
  CATEGORY: "catergory",
  PRODUCTS: "products",
};

export const jobStatus = [
  {
    id: "open",
    name: "Open",
  },
  {
    id: "closed",
    name: "Closed",
  },
  {
    id: "inprogress",
    name: "In Progress",
  },
  {
    id: "onhold",
    name: "Onhold",
  },
  {
    id: "to_verify",
    name: "To Verify",
  },
];
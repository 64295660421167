import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { getUserProfile } from "./services/private.services";

// AuthContext.tsx
interface AuthContextType {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  user: any;
  setUserDetails: (user: any) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}: ReactNode | any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const setUserDetails = (user: any) => {
    setUser(user);
  };

  const setUserProfileDetails = useCallback(async () => {
    const result = await getUserProfile();
    setUserDetails(result);
    setIsAuthenticated(true);
  }, []);

  useEffect(() => {
    if (!isAuthenticated && localStorage.getItem("accessToken")) {
      setUserProfileDetails();
    }
  }, [isAuthenticated, setUserProfileDetails]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, setUserDetails, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

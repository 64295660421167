import { useState, useEffect, useCallback } from "react";
import { fetchUserList,deleteUser } from "../../../services/private.services";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const useUserList = () => {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showEditModal, setEditModal] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    search: "",
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      perPage: +event.target.value,
    }));
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: page + 1,
      }));
    },
    []
  );

  // eslint-disable-next-line
  const handleOnChangeSearch = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        search: event.target.value,
      }));
    }, 500),
    []
  );

  const getUserList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await fetchUserList(pagination);
      setUserList(result?.data);
      setTotalCount(result?.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [pagination]);
  const openEditModal = () => {
    setEditModal(true);
  };
  const handleUserDetails = (data: any) => {
    setUserDetails(data);
    setEditModal(true);
  };
  const closeOpenEditModal = () => {
    setEditModal(false);
  };
  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const onSetUserId = (customerId: string) => {
    setUserId(customerId);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onHandleDelete = useCallback(async () => {
    try {
      await deleteUser(userId);
      getUserList();
      closeDeleteModal();
      toast.success("Deleted User");
    } catch (error) {
      console.log(error);
    }
  }, [userId, getUserList]);

  useEffect(() => {
    getUserList();
  }, [getUserList, pagination]);



  return {
    loading,
    userList,
    totalCount,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOnChangeSearch,
    getUserList,
    openEditModal,
    closeOpenEditModal,
    showEditModal,
    handleUserDetails,
    userDetails,
    onSetUserId,
    closeDeleteModal,
    showDeleteModal,
    openDeleteModal,
    onHandleDelete,
    
  };
};

export default useUserList;
